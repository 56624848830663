import { Suspense, useEffect, useRef, useState } from 'react';
import * as S from './styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { Dropdown } from 'primereact/dropdown';
import { visitas } from './data';
import { FaFileExcel } from 'react-icons/fa';
import { useVendedorAcompanhamento, useVendedorCheckinCheckout } from 'client/hooks/mapa';
import { useVendedoresByUser } from 'client/hooks/vendedores';
import { RadioButton } from 'primereact/radiobutton';

const CheckInOut = React.lazy(() => import('./models/checkinout'));
const ConsolidadoTable = React.lazy(() => import('./models/consolidado/consolidadoTable'));
const DetailedTable = React.lazy(() => import('./models/detailed/detailedTable'));

import VmButton from 'components/VmButton/VmButton';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';

import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useForm } from 'react-hook-form';
import { FilterByUsersCompany } from 'components/FilterByUsersCompany';
import { FilterByCompany } from 'components/FilterByCompany';
import { FilterBySupervisorCompany } from 'components/FilterBySupervisorCompany';
import { FilterByManagerCompany } from 'components/FilterByManagerCompany';
import { userSelector } from 'modules/auth/reducer/auth';
import { useAppSelector } from 'store';
import React from 'react';

export default function AcompanhamentoDiario() {
  const user = useAppSelector(userSelector);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      codCompany: user?.codEmpresa,
      codUserByCompany: user?.codUsuario,
      codSupervisor: -1,
      codManager: -1,
    },
  });

  const values = watch();

  const { setTitlePage } = useTitlePageStore();

  const [viewMode, setViewMode] = useState<'daily' | 'detailed' | 'checkinout'>('daily');
  const [selectedVendedor, setSelectedVendedor] = useState<any>({
    codEmpresa: values.codCompany,
    codVendedor: -1,
    nome: 'Todos',
  });

  const currentDate = new Date();

  const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const formattedStartOfMonth = startOfMonth.toISOString().split('T')[0];
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];

  const [selectedDtIni, setSelectedDtIni] = useState<any>(formattedStartOfMonth);
  const [selectedDtFim, setSelectedDtFim] = useState<any>(formattedCurrentDate);

  const { data: vendedores, refetch: refetchVendedores } = useVendedoresByUser(
    values.codCompany,
    String(values.codUserByCompany),
  );
  const { data: visitasVendedores, refetch } = useVendedorAcompanhamento(
    values.codCompany,
    String(values.codManager ?? -1) ?? '-1',
    String(values.codSupervisor ?? -1) ?? '-1',
    selectedVendedor && selectedVendedor.codVendedor ? selectedVendedor.codVendedor : '-1',
    selectedDtIni,
    selectedDtFim,
    String(values.codUserByCompany),
  );
  const { data: checkinOutVendedores, refetch: refetchCheckinOut } = useVendedorCheckinCheckout(
    values.codCompany,
    selectedVendedor && selectedVendedor.codVendedor ? selectedVendedor.codVendedor : '-1',
    selectedDtIni,
    selectedDtFim,
    String(values.codUserByCompany),
  );

  const defaultOldFilters = {
    oldVendedor: {
      codEmpresa: values.codCompany,
      codVendedor: -1,
      nome: 'Todos',
    },
    oldDtIni: formattedStartOfMonth,
    oldDtFim: formattedCurrentDate,
    oldMode: 'daily',
  };

  const [oldFilters, setOldFilters] = useState(defaultOldFilters);

  useEffect(() => {
    if (oldFilters && oldFilters.oldMode == 'checkinout') {
      setSelectedVendedor(oldFilters.oldVendedor);
      setSelectedDtIni(oldFilters.oldDtIni);
      setSelectedDtFim(oldFilters.oldDtFim);
    }
  }, [viewMode]);

  useEffect(() => {
    refetchVendedores();
  }, [values.codCompany, values.codUserByCompany]);

  useEffect(() => {
    if (viewMode == 'checkinout') {
      refetchCheckinOut();
    } else {
      refetch();
    }
  }, [
    values.codCompany,
    values.codManager,
    values.codSupervisor,
    values.codUserByCompany,
    selectedDtIni,
    selectedDtFim,
    selectedVendedor,
    viewMode,
  ]);

  const [tableData, setTableData] = useState<any>({
    vendedores: [],
  });
  const [uniqueSemanas, setUniqueSemanas] = useState<any>([]);
  const [uniqueDays, setUniqueDays] = useState<any>([]);
  const [mesList, setMesList] = useState<any>([]);

  useEffect(() => {
    if (!visitasVendedores) {
      return;
    }
    setTitlePage('Acompanhamento Diário');
    const rcaDataList = visitas(visitasVendedores);
    setTableData({
      vendedores: rcaDataList,
    });

    let indexWithHigherNumberOfDays = 0;
    let higherLength = 0;
    for (let i = 0; i < rcaDataList.length; i++) {
      if (rcaDataList[i] && rcaDataList[i].dias.length > higherLength) {
        higherLength = rcaDataList[i].dias.length;
        indexWithHigherNumberOfDays = i;
      }
    }
    rcaDataList[indexWithHigherNumberOfDays] &&
      setUniqueSemanas(
        rcaDataList.length == 0
          ? []
          : Array.from(new Set(rcaDataList[indexWithHigherNumberOfDays].dias.map((dia) => dia.semana))),
      );
    setUniqueDays(
      rcaDataList.length == 0
        ? []
        : Array.from(new Set(rcaDataList[indexWithHigherNumberOfDays].dias.map((dia) => dia.dia))),
    );
    setMesList(rcaDataList.length == 0 ? [] : rcaDataList[indexWithHigherNumberOfDays].dias);
  }, [visitasVendedores]);

  const handleKmTotalValue = () => {
    const formatter = new Intl.NumberFormat('pt-BR');
    if (checkinOutVendedores) {
      let totalKm = 0;
      for (let i = 0; i < checkinOutVendedores.length; i++) {
        const info = checkinOutVendedores[i];
        totalKm = totalKm + +info.km;
      }
      return formatter.format(totalKm) ?? '0';
    } else {
      return '0';
    }
  };

  function exportCliToExcel() {
    const fileName: string = `checkin-checkout-${selectedDtIni}-
    ${selectedDtFim}`;
    const handledCheckinoutVendedores: any = checkinOutVendedores;
    for (let i = 0; i < handledCheckinoutVendedores.length; i++) {
      handledCheckinoutVendedores[i].km = +handledCheckinoutVendedores[i].km;
    }

    handledCheckinoutVendedores.push({ km: handleKmTotalValue() });

    const ws = XLSX.utils.json_to_sheet(viewMode == 'checkinout' ? handledCheckinoutVendedores : visitasVendedores);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  }

  const dailyTableRef = useRef<any>(null);
  const detailedTableRef = useRef<any>(null);
  const checkInOutRef = useRef<any>(null);

  useEffect(() => {
    setValue('codUserByCompany', user?.codUsuario);
    setValue('codCompany', user?.codEmpresa);
  }, []);

  const [showDayColumns, setShowDayColumns] = useState(false);

  const exportTableToExcel = async () => {
    const borderStyle: Partial<ExcelJS.Borders> = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } },
    };
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
  
    if (showDayColumns) {
      const firstHeaderRow = [
        'CÓD SUPERVISOR',
        'SUPERVISOR',
        'CÓD VENDEDOR',
        'VENDEDOR',
        ...uniqueDays.flatMap((day) => [`DIA ${day}`, '', '', '', '', '', '']),
        'TOTAIS',
        '',
        '',
        '',
        '',
        '',
        '',
      ];
      const firstRow = worksheet.addRow(firstHeaderRow);
  
      worksheet.getColumn(1).width = 20; // CÓD SUPERVISOR
      worksheet.getColumn(2).width = 25; // SUPERVISOR
      worksheet.getColumn(3).width = 20; // CÓD VENDEDOR
      worksheet.getColumn(4).width = 25; // VENDEDOR
  
      let startColumn = 5;
      uniqueDays.forEach(() => {
        worksheet.mergeCells(1, startColumn, 1, startColumn + 6);
        startColumn += 7;
      });
  
      worksheet.mergeCells(1, startColumn, 1, startColumn + 6);
  
      firstRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });
  
      const secondHeaderRow = [
        '',
        '',
        '',
        '',
        ...uniqueDays.flatMap(() => ['ROTA', '', '', 'POSITIVAÇÃO', '', '', 'GPS']),
        'ROTA',
        '',
        '',
        'POSITIVAÇÃO',
        '',
        '',
        'GPS',
      ];
      const secondRow = worksheet.addRow(secondHeaderRow);
      startColumn = 5;
      uniqueDays.forEach(() => {
        worksheet.mergeCells(2, startColumn, 2, startColumn + 2);
        worksheet.mergeCells(2, startColumn + 3, 2, startColumn + 5);
        startColumn += 7;
      });
  
      worksheet.mergeCells(2, startColumn, 2, startColumn + 2);
      worksheet.mergeCells(2, startColumn + 3, 2, startColumn + 5);
  
      secondRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });
  
      const thirdHeaderRow = [
        '',
        '',
        '',
        '',
        ...uniqueDays.flatMap(() => ['PREV', 'REAL', 'NÃO.R', '%', 'REAL', '%', 'KM']),
        'PREV',
        'REAL',
        'NÃO.R',
        '%',
        'REAL',
        '%',
        'KM',
      ];
      const thirdRow = worksheet.addRow(thirdHeaderRow);
      thirdRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });
  
      tableData.vendedores.forEach((vend: any) => {
        const row = [
          vend.codSupervisor,
          vend.supervisor,
          vend.codVendedor,
          vend.vendedor,
          ...vend.dias.flatMap((dia: any) => [
            dia.qde_cli_dia, // Keep as number
            dia.qde_visitado_dia,
            dia.qde_nao_visitado,
            dia.perc_visitado,
            dia.positivacoes,
            dia.perc_positivado,
            dia.km_exec ? dia.km_exec : 0,
          ]),
          vend.prev_visita_total,
          vend.real_visita_total,
          vend.pendencia_visita_total,
          vend.perc_visita_total,
          vend.positivacao_total,
          vend.perc_positivacao_total,
          vend.km_total ? vend.km_total : 0,
        ];
        const addedRow = worksheet.addRow(row);
  
        addedRow.eachCell((cell, colNumber) => {
          if (colNumber > 4) {
            cell.numFmt = '#,##0.00';
          }
          cell.border = borderStyle;
        });
      });
    } else {
      let startColumn = 5;
  
      const headerRow = ['CÓD SUPERVISOR', 'SUPERVISOR', 'CÓD VENDEDOR', 'VENDEDOR', 'TOTAIS', '', '', '', '', '', ''];
      const firstRow = worksheet.addRow(headerRow);
      firstRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      });
      [1].forEach(() => {
        worksheet.mergeCells(1, startColumn, 1, startColumn + 6);
      });
  
      const secondHeaderRow = ['', '', '', '', 'ROTA', '', '', 'POSITIVAÇÃO', '', '', 'GPS'];
      const secondRow = worksheet.addRow(secondHeaderRow);
      startColumn = 5;
  
      worksheet.mergeCells(2, startColumn, 2, startColumn + 2);
      worksheet.mergeCells(2, startColumn + 3, 2, startColumn + 5);
  
      secondRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });
  
      const thirdHeaderRow = ['', '', '', '', 'PREV', 'REAL', 'NÃO.R', '%', 'REAL', '%', 'KM'];
      const thirdRow = worksheet.addRow(thirdHeaderRow);
      thirdRow.eachCell((cell) => {
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'D3D3D3' } };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.border = borderStyle;
      });
  
      tableData.vendedores.forEach((vend: any) => {
        const row = [
          vend.codSupervisor,
          vend.supervisor,
          vend.codVendedor,
          vend.vendedor,
          vend.prev_visita_total,
          vend.real_visita_total,
          vend.pendencia_visita_total,
          vend.perc_visita_total,
          vend.positivacao_total,
          vend.perc_positivacao_total,
          vend.km_total ? vend.km_total : 0,
        ];
        const addedRow = worksheet.addRow(row);
          addedRow.eachCell((cell, colNumber) => {
          if (colNumber > 4) {
            cell.numFmt = '#,##0.00';
          }
          cell.border = borderStyle;
        });
      });
    }
  
    worksheet.mergeCells('A1:A3');
    worksheet.mergeCells('B1:B3');
    worksheet.mergeCells('C1:C3');
    worksheet.mergeCells('D1:D3');
  
    worksheet.columns.forEach((column) => {
      const maxLength = Math.max(
        10,
        ...column.values.filter((val) => val !== null && val !== undefined).map((val) => val.toString().length),
      );
      column.width = maxLength;
    });
  
    const buffer = await workbook.xlsx.writeBuffer();
  
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    saveAs(blob, `visitas-detalhadas-${selectedDtIni} a ${selectedDtFim}.xlsx`);
  };
  

  return (
    <S.ReportMainBox>
      <S.ReportFormBox>
        {user && user.codEmpresa === 2072021 && (
          <S.ReportInputBox>
            <label>Empresa</label>
            <FilterByCompany control={control} setValue={setValue} />
          </S.ReportInputBox>
        )}

        {user && user.codEmpresa === 2072021 && (
          <S.ReportInputBox>
            <label>Usuário</label>
            <FilterByUsersCompany control={control} setValue={setValue} codCompany={values?.codCompany} />
          </S.ReportInputBox>
        )}

        <S.ReportInputBox>
          <label>Gerente</label>
          <FilterByManagerCompany
            control={control}
            setValue={setValue}
            codCompany={values?.codCompany}
            codUser={values?.codUserByCompany}
          />
        </S.ReportInputBox>

        <S.ReportInputBox>
          <label>Supervisor</label>
          <FilterBySupervisorCompany
            control={control}
            setValue={setValue}
            codCompany={values?.codCompany}
            codUser={values?.codUserByCompany}
          />
        </S.ReportInputBox>

        <S.ReportInputBox>
          <label>Vendedor</label>
          <Dropdown
            options={
              vendedores && vendedores.length > 0
                ? [
                    {
                      codEmpresa: vendedores && vendedores.length > 0 ? vendedores[0].codEmpresa : values.codCompany,
                      codVendedor: -1,
                      nome: 'Todos',
                    },
                    ...(values.codSupervisor
                      ? vendedores.filter(
                          (vend: any) => +vend.codSupervisor == +values.codSupervisor || values.codSupervisor == -1,
                        )
                      : vendedores ?? []),
                  ]
                : []
            }
            optionLabel="nome"
            placeholder="Vendedor"
            itemTemplate={(rca) => {
              return rca.nome + ' [' + rca.codVendedor + ']';
            }}
            value={
              vendedores && selectedVendedor
                ? vendedores.filter((v: any) => +v.codVendedor == +selectedVendedor.codVendedor)[0]
                : selectedVendedor ?? null
            }
            onChange={(e) => {
              setSelectedVendedor(e.value);
              setOldFilters((prev: any) => {
                return {
                  ...prev,
                  oldVendedor: e.value,
                };
              });
            }}
            filter
            filterBy="nome,codVendedor"
            showClear
          />
        </S.ReportInputBox>
        <S.ReportDateInputBox>
          <label>Data Inicial</label>

          <input
            type={'date'}
            id={'dateStart'}
            value={selectedDtIni}
            defaultValue={selectedDtIni}
            onChange={(e) => {
              setSelectedDtIni(e.target.value);
              setOldFilters((prev: any) => {
                return {
                  ...prev,
                  oldDtIni: e.target.value,
                };
              });
            }}
          />
        </S.ReportDateInputBox>

        <S.ReportDateInputBox>
          <label>Data Final</label>
          <input
            type={'date'}
            id={'dateEnd'}
            value={selectedDtFim}
            defaultValue={selectedDtFim}
            onChange={(e) => {
              setSelectedDtFim(e.target.value);
              setOldFilters((prev: any) => {
                return {
                  ...prev,
                  oldDtFim: e.target.value,
                };
              });
            }}
          />
        </S.ReportDateInputBox>
        <S.ReportViewModeBox>
          <S.ReportViewOption>
            <RadioButton
              inputId="daily"
              name="Diário"
              value="daily"
              onChange={(e) => {
                setViewMode(e.value);
                setOldFilters((prev: any) => {
                  return {
                    ...prev,
                    oldMode: viewMode,
                  };
                });
              }}
              checked={viewMode === 'daily'}
            />
            <label htmlFor="daily">Consolidado</label>
          </S.ReportViewOption>
          <S.ReportViewOption>
            <RadioButton
              inputId="detailed"
              name="Detalhado"
              value="detailed"
              onChange={(e) => {
                setViewMode(e.value);
                setOldFilters((prev: any) => {
                  return {
                    ...prev,
                    oldMode: viewMode,
                  };
                });
              }}
              checked={viewMode === 'detailed'}
            />
            <label htmlFor="detailed">Detalhado</label>
          </S.ReportViewOption>
          <S.ReportViewOption>
            <RadioButton
              inputId="checkinout"
              name="Check In Out"
              value="checkinout"
              onChange={(e) => {
                setViewMode(e.value);
                setOldFilters((prev: any) => {
                  return {
                    ...prev,
                    oldMode: viewMode,
                  };
                });
              }}
              checked={viewMode === 'checkinout'}
            />
            <label htmlFor="checkinout">Check In Out</label>
          </S.ReportViewOption>
        </S.ReportViewModeBox>

        <S.ReportHeaderButtonsBox>
          {viewMode == 'daily' ? (
            <DownloadTableExcel
              currentTableRef={dailyTableRef.current}
              filename={`visitas-consolidadas-${selectedDtIni}-
              ${selectedDtFim}`}
            >
              <VmButton
                options={{
                  icon: <FaFileExcel />,
                  iconPosition: 'left',
                  label: 'Exportar',
                  style: 'btn-success',
                }}
                style={{
                  marginLeft: '10px',
                }}
              />
            </DownloadTableExcel>
          ) : viewMode == 'detailed' ? (
            // <DownloadTableExcel
            //   currentTableRef={detailedTableRef.current}
            //   filename={`visitas-detalhadas-${selectedDtIni} a ${selectedDtFim}`}
            // >
            <VmButton
              options={{
                icon: <FaFileExcel />,
                iconPosition: 'left',
                label: 'Exportar',
                style: 'btn-success',
              }}
              onClick={() => {
                exportTableToExcel();
              }}
              style={{
                marginLeft: '10px',
              }}
            />
          ) : (
            /* </DownloadTableExcel> */
            <VmButton
              options={{
                icon: <FaFileExcel />,
                iconPosition: 'left',
                label: 'Exportar',
                style: 'btn-success',
              }}
              style={{
                marginLeft: '10px',
              }}
              onClick={() => {
                exportCliToExcel();
              }}
            />
          )}
        </S.ReportHeaderButtonsBox>
      </S.ReportFormBox>

      <Suspense fallback={<>Loading</>}>
        <ConsolidadoTable
          mesList={mesList}
          tableData={tableData}
          uniqueDays={uniqueDays}
          uniqueSemanas={uniqueSemanas}
          setSelectedDtIni={setSelectedDtIni}
          setSelectedDtFim={setSelectedDtFim}
          setSelectedVendedor={setSelectedVendedor}
          setViewMode={setViewMode}
          dailyTableRef={dailyTableRef}
          viewMode={viewMode}
        />
      </Suspense>

      <Suspense fallback={<>Loading</>}>
        <DetailedTable
          mesList={mesList}
          tableData={tableData}
          uniqueDays={uniqueDays}
          uniqueSemanas={uniqueSemanas}
          setSelectedDtIni={setSelectedDtIni}
          setSelectedDtFim={setSelectedDtFim}
          setSelectedVendedor={setSelectedVendedor}
          setViewMode={setViewMode}
          detailedTableRef={detailedTableRef}
          viewMode={viewMode}
          showDayColumns={showDayColumns}
          setShowDayColumns={setShowDayColumns}
        />
      </Suspense>

      <Suspense fallback={<>Loading</>}>
        {viewMode == 'checkinout' && (
          <CheckInOut viewMode={viewMode} checkinOutVendedores={checkinOutVendedores} checkInOutRef={checkInOutRef} />
        )}
      </Suspense>
    </S.ReportMainBox>
  );
}
