import * as S from './noDataFound.styles';
import noDataFoundImg from 'assets/images/noDataFound.png';

export default function NoDataFound() {
  return (
    <S.EmptyMainBox>
      <S.EmptyImage alt="no-data-found-vmais" src={noDataFoundImg} />
      <S.EmptyText>Nenhum dado encontrado :/</S.EmptyText>
    </S.EmptyMainBox>
  );
}
