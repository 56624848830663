import { useState } from 'react';

import * as S from './styles';

type selectedDay = {
  manha: string;
  tarde: string;
};

type daySelectionArray = {
  domingo: selectedDay;
  segunda: selectedDay;
  terca: selectedDay;
  quarta: selectedDay;
  quinta: selectedDay;
  sexta: selectedDay;
  sabado: selectedDay;
};

const UseMapRouterDaySelector = () => {
  const defaultDaySelectionArray = {
    domingo: {
      manha: 'N',
      tarde: 'N',
    },
    segunda: {
      manha: 'S',
      tarde: 'S',
    },
    terca: {
      manha: 'S',
      tarde: 'S',
    },
    quarta: {
      manha: 'S',
      tarde: 'S',
    },
    quinta: {
      manha: 'S',
      tarde: 'S',
    },
    sexta: {
      manha: 'S',
      tarde: 'S',
    },
    sabado: {
      manha: 'N',
      tarde: 'N',
    },
  };
  const [daySelection, setDaySelection] = useState<daySelectionArray>(defaultDaySelectionArray);

  const Selectdays = () => {
    return (
      <S.SelectDayMainBox>
        <S.DaysBox>
          <S.DaysBoxTable>
            <S.DaysBoxTableHead>
              <S.DaysBoxTableRow>
                <S.DaysBoxTableHeaderColumn></S.DaysBoxTableHeaderColumn>
                <S.DaysBoxTableHeaderColumn>Domingo</S.DaysBoxTableHeaderColumn>
                <S.DaysBoxTableHeaderColumn>Segunda</S.DaysBoxTableHeaderColumn>
                <S.DaysBoxTableHeaderColumn>Terça</S.DaysBoxTableHeaderColumn>
                <S.DaysBoxTableHeaderColumn>Quarta</S.DaysBoxTableHeaderColumn>
                <S.DaysBoxTableHeaderColumn>Quinta</S.DaysBoxTableHeaderColumn>
                <S.DaysBoxTableHeaderColumn>Sexta</S.DaysBoxTableHeaderColumn>
                <S.DaysBoxTableHeaderColumn>Sábado</S.DaysBoxTableHeaderColumn>
              </S.DaysBoxTableRow>
            </S.DaysBoxTableHead>
            <S.DaysBoxTableBody>
              <S.DaysBoxTableRow>
                <S.DaysBoxTableBodyCell>Manhã</S.DaysBoxTableBodyCell>
                {Object.keys(daySelection).map((day: any) => {
                  return (
                    <S.DaysBoxTableBodyCell>
                      <S.DaysBoxTableInput
                        type={'checkbox'}
                        defaultChecked={daySelection[day].manha == 'S'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setDaySelection((prev) => {
                            return {
                              ...prev,
                              [day]: {
                                tarde: prev[day].tarde,
                                manha: daySelection[day].manha == 'S' ? 'N' : 'S',
                              },
                            };
                          });
                        }}
                      />
                    </S.DaysBoxTableBodyCell>
                  );
                })}
              </S.DaysBoxTableRow>
              <S.DaysBoxTableRow>
                <S.DaysBoxTableBodyCell>Tarde</S.DaysBoxTableBodyCell>
                {Object.keys(daySelection).map((day: any) => {
                  return (
                    <S.DaysBoxTableBodyCell>
                      <S.DaysBoxTableInput
                        type={'checkbox'}
                        defaultChecked={daySelection[day].tarde == 'S'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setDaySelection((prev) => {
                            return {
                              ...prev,
                              [day]: {
                                manha: prev[day].manha,
                                tarde: daySelection[day].tarde == 'S' ? 'N' : 'S',
                              },
                            };
                          });
                        }}
                      />
                    </S.DaysBoxTableBodyCell>
                  );
                })}
              </S.DaysBoxTableRow>
            </S.DaysBoxTableBody>
          </S.DaysBoxTable>
        </S.DaysBox>
      </S.SelectDayMainBox>
    );
  };

  const handleDataStrucuteAdjust = (data: any, mode: 'fowards' | 'backwords') => {
    if (mode == 'fowards') {
      setDaySelection({
        domingo: {
          manha: data.DOMINGO_MANHA,
          tarde: data.DOMINGO_TARDE,
        },
        segunda: {
          manha: data.SEGUNDA_MANHA,
          tarde: data.SEGUNDA_TARDE,
        },
        terca: {
          manha: data.TERCA_MANHA,
          tarde: data.TERCA_TARDE,
        },
        quarta: {
          manha: data.QUARTA_MANHA,
          tarde: data.QUARTA_TARDE,
        },
        quinta: {
          manha: data.QUINTA_MANHA,
          tarde: data.QUINTA_TARDE,
        },
        sexta: {
          manha: data.SEXTA_MANHA,
          tarde: data.SEXTA_TARDE,
        },
        sabado: {
          manha: data.SABADO_MANHA,
          tarde: data.SABADO_TARDE,
        },
      });
    } else {
      data.domingoManha = daySelection.domingo.manha;
      data.domingoTarde = daySelection.domingo.tarde;
      data.segundaManha = daySelection.segunda.manha;
      data.segundaTarde = daySelection.segunda.tarde;
      data.tercaManha = daySelection.terca.manha;
      data.tercaTarde = daySelection.terca.tarde;
      data.quartaManha = daySelection.quarta.manha;
      data.quartaTarde = daySelection.quarta.tarde;
      data.quintaManha = daySelection.quinta.manha;
      data.quintaTarde = daySelection.quinta.tarde;
      data.sextaManha = daySelection.sexta.manha;
      data.sextaTarde = daySelection.sexta.tarde;
      data.sabadoManha = daySelection.sabado.manha;
      data.sabadoTarde = daySelection.sabado.tarde;
      return data;
    }
  };

  const selectMapDayHook = {
    daySelection: daySelection,
    setDaySelection: setDaySelection,
    Selectdays: Selectdays,
    handleDataStrucuteAdjust: handleDataStrucuteAdjust,
  };
  return selectMapDayHook;
};

export default UseMapRouterDaySelector;
