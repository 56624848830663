import * as S from './mapHeader.styles';
import { useMap } from '../../regioes.context';
import { MdClose, MdOutlineClose, MdOutlineMap, MdSearch, MdViewList } from 'react-icons/md';
import { ClientesProps, SetoresProps } from '../../regioes.types';
import VmButton from 'components/VmButton/VmButton';
import { BsFillTrashFill, BsPlusLg } from 'react-icons/bs';
import useMediaQuery from 'hooks/useMediaQuery';
import MapNewSetor from '../mapNewSetor/mapNewSetor.index';
import { useMemo, useState } from 'react';
import { AiFillFileExcel } from 'react-icons/ai';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import MapImportCli from '../mapImportCli/mapImportCli.index copy';
import { toast } from 'react-toastify';
import { deleteAllCliFromSetor } from 'client/api';
import { Button, dialog } from 'reactivus';
import MapCliUpdate from '../mapCliUpdate/mapCliUpdate.index';
import ClieSuggestionBox from './cliSuggestion.index';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';

export default function MapHeader() {
  const {
    clientes,
    selectedCli,
    setSelectedCli,
    cliSearchText,
    setCliSearchText,
    screenMode,
    setScreenMode,
    isDrawing,
    selectedSetores,
    setSelectedSetores,
    handleGetAllData,
    setores,
    cliSearchRef,
    handleCliSearchText,
  } = useMap();

  const navigate = useNavigate();

  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [showMapNewSetor, setShowMapNewSetor] = useState(false);

  const [showImportCliModal, setShowImportCliModal] = useState<boolean>(false);

  function exportCliToExcel() {
    const fileName: string = `CLIENTES`;

    let cliList: any = [];
    for (let i = 0; i < clientes?.list?.length; i++) {
      let cli: ClientesProps = clientes?.list[i];
      let newCli: any = {
        CODCLI: '',
        CLIENTE: '',
        SETOR: '',
        CIDADE: '',
        LOGRADOURO: '',

        PROXIMA_VISITA: '',
        SEQUENCIA: '',
        FREQ_VISITA: '',
        TEMPO_MINIMO_VISITA: '',
        POTENCIAL: '',
        VENDA_ULT_TRI: '',
        VENDA_MES: '',

        LATITUDE: '',
        LONGITUDE: '',
      };

      newCli['CODCLI'] = `${cli.CODCLI ?? ''}`;
      newCli['CLIENTE'] = `${cli.RAZAOSOCIAL ?? ''}`;
      newCli['SETOR'] = `[${cli.CODSETOR ?? ''}] ${cli.NOMESETOR ?? ''}`;
      newCli['CIDADE'] = `${cli.ENDCIDADE ?? ''} [${cli.ENDUF ?? ''}]`;
      newCli['LOGRADOURO'] = `${cli.ENDLOGRADOURO ?? ''}`;

      newCli['PROXIMA_VISITA'] = `${cli.DT_PROX_VISITA ?? ''}`;
      newCli['SEQUENCIA'] = `${cli.SEQUENCIA ?? ''}`;
      newCli['FREQ_VISITA'] = `${cli.FREQVISITA ?? ''}`;
      newCli['TEMPO_MINIMO_VISITA'] = `${cli.TEMPOMINVISITA ?? ''}`;
      newCli['POTENCIAL'] = `${cli.VL_POTENCIAL ?? ''}`;
      newCli['VENDA_ULT_TRI'] = `${cli.VL_MED_ULT_TRIM ?? ''}`;
      newCli['VENDA_MES'] = `${cli.VL_MES_ATUAL ?? ''}`;

      newCli['LATITUDE'] = `${cli.LATITUDE ?? ''}`;
      newCli['LONGITUDE'] = `${cli.LONGITUDE ?? ''}`;

      cliList.push(newCli);
    }

    const ws = XLSX.utils.json_to_sheet(cliList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, fileName);
  }

  const handleRemoveAllCliFromSetor = () => {
    const paramsToRemoveAllCliFromSetor = {
      codmepresa: selectedSetores[0].COD_EMPRESA,
      codsetor: selectedSetores[0].CODSETOR,
    };

    const clientesDoSetor = clientes?.list?.filter((cli: ClientesProps) => cli.CODSETOR == selectedSetores[0].CODSETOR);

    dialog
      .show({
        icon: 'question',
        title: 'Deseja remover todos os clientes do setor?',
        showCancelButton: true,
        cancelButtonText: 'Não, voltar',
        confirmButtonText: 'Sim, remover',
        showConfirmButton: true,
      })
      .then((res) => {
        if (res.isConfirmed) {
          dialog
            .show({
              html: `Serão removidos <b>${clientesDoSetor.length}</b> clientes do 
            setor <b>[${selectedSetores[0].CODSETOR}] ${selectedSetores[0].NOMESETOR}</b>. <br/> Confirma?`,
              showCancelButton: true,
              cancelButtonText: 'Não, voltar',
              confirmButtonText: 'Sim, remover',
              showConfirmButton: true,
            })
            .then((res) => {
              if (res.isConfirmed) {
                deleteAllCliFromSetor(paramsToRemoveAllCliFromSetor)
                  .then((res) => {
                    toast.success('Todos os clientes removidos do setor!');
                    handleGetAllData();
                  })
                  .catch((err: any) => {
                    toast.error('Falha ao remover clientes do setor!');
                    console.log('err :', err);
                  });
              }
            })
            .catch((err: Error) => {
              toast.error('Erro inesperado ao remover clientes do setor!');
              console.log('err :', err);
            });
        }
      })
      .catch((err: Error) => {
        toast.error('Erro inesperado ao remover clientes do setor!');
        console.log('err :', err);
      });
  };

  const placeholderText = useMemo(
    () => (selectedCli ? `${selectedCli.CODCLI} - ${selectedCli.RAZAOSOCIAL}` : 'Buscar...'),
    [selectedCli],
  );

  return (
    <S.MapHeaderMainBox>
      <S.MapHeaderBackBtn
        onClick={() => {
          navigate(`/mapa`);
        }}
      >
        <IoMdArrowRoundBack />
      </S.MapHeaderBackBtn>
      
      <S.SearchClientMainBoxInput>
        <S.SearchInput type="text" placeholder={placeholderText} onChange={handleCliSearchText} ref={cliSearchRef} />
        {selectedCli != null || (clientes.list && cliSearchText) ? (
          <MdClose
            onClick={(e) => {
              setSelectedCli(null);
              setCliSearchText('');
              cliSearchRef.current.value = '';
              window.localStorage.setItem('cor', '000000');
            }}
          />
        ) : (
          <MdSearch />
        )}
        {screenMode == 'M' && <ClieSuggestionBox />}
      </S.SearchClientMainBoxInput>

      <S.MapGridButton>
        <S.Icon mapGridShow={screenMode == 'M'} onClick={() => setScreenMode('M')}>
          <MdOutlineMap />
        </S.Icon>
        <S.Icon mapGridShow={screenMode == 'G'} onClick={() => setScreenMode('G')}>
          <MdViewList />
        </S.Icon>
      </S.MapGridButton>

      {screenMode == 'M' && (
        <VmButton
          options={{
            icon: <BsPlusLg />,
            label: selectedSetores ? 'Setor' : !selectedSetores && isWebScreen ? 'Novo Setor' : 'Setor',
            iconPosition: 'left',
            style: 'btn-success',
            tooltip: {
              show: true,
              text: 'Novo Setor',
              position: 'bottom',
            },
          }}
          onClick={() => setShowMapNewSetor(!showMapNewSetor)}
        />
      )}

      {/* {screenMode == 'M' && (
        <VmButton
          options={{
            icon: <BsPlusLg />,
            label: selectedSetores ? 'Área' : !selectedSetores && isWebScreen ? 'Nova Área' : 'Área',
            iconPosition: 'left',
            style: isDrawing ? 'btn-danger' : 'btn-success',
            tooltip: {
              show: true,
              text: 'Nova Área',
              position: 'bottom',
            },
          }}
        />
      )} */}

      {screenMode == 'G' && (
        <VmButton
          options={{
            icon: <AiFillFileExcel />,
            label: 'Exportar',
            iconPosition: 'left',
            style: 'btn-success',
            tooltip: {
              show: true,
              text: 'Exportar lista de clientes',
              position: 'bottom',
            },
          }}
          onClick={() => exportCliToExcel()}
        />
      )}

      {screenMode == 'G' && selectedSetores && selectedSetores.length == 1 && selectedSetores[0].CODSETOR != -1 && (
        <VmButton
          options={{
            icon: <AiFillFileExcel />,
            label: 'Importar',
            iconPosition: 'left',
            style: 'btn-success',
            tooltip: {
              show: true,
              text: 'Importar lista de clientes',
              position: 'bottom',
            },
          }}
          onClick={() => setShowImportCliModal(true)}
        />
      )}

      {screenMode == 'G' && selectedSetores && selectedSetores.length == 1 && (
        <VmButton
          options={{
            icon: <BsFillTrashFill />,
            label: 'Remover',
            iconPosition: 'left',
            style: 'btn-danger',
            tooltip: {
              show: true,
              text: 'Remover todos os clientes',
              position: 'bottom',
            },
          }}
          onClick={() => handleRemoveAllCliFromSetor()}
        />
      )}

      <MapCliUpdate />

      {selectedSetores && selectedSetores.length == 1 && (
        <S.SelectedSetorCard cor={selectedSetores[0].COR ?? '000000'}>
          <span>{`[${selectedSetores[0].CODSETOR}] ${selectedSetores[0].NOMESETOR}`}</span>
          <MdOutlineClose
            onClick={() => {
              const setoresToFirstSelect: SetoresProps[] = setores?.unfiltered?.filter(
                (sector: SetoresProps) => sector.CODSETOR != 0 && sector.CODSETOR != -1,
              );
              setSelectedSetores(setoresToFirstSelect);
            }}
          />
        </S.SelectedSetorCard>
      )}

      {showMapNewSetor && <MapNewSetor showMapNewSetor={showMapNewSetor} setShowMapNewSetor={setShowMapNewSetor} />}
      <MapImportCli
        showModal={showImportCliModal}
        setShowModal={setShowImportCliModal}
        setorToImportCli={selectedSetores && selectedSetores[0] ? selectedSetores[0] : null}
      />
    </S.MapHeaderMainBox>
  );
}
