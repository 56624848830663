import * as S from './sectorCard.styles';

export default function SectorCardLoading() {
  return (
    <S.SectorCardLoadingBox>
      <S.SectorCardLoadingHeader>
        <S.SectorCardLoadingHeaderName className="loading"></S.SectorCardLoadingHeaderName>
        <S.SectorCardLoadingHeaderRca className="loading"></S.SectorCardLoadingHeaderRca>
      </S.SectorCardLoadingHeader>

      <S.SectorCardLoadingFooter>
        <S.SectorCardLoadingInfoBox>
          <S.SectorCardLoadingInfoBoxTitle className="loading"></S.SectorCardLoadingInfoBoxTitle>
          <S.SectorCardLoadingInfoBoxValue className="loading"></S.SectorCardLoadingInfoBoxValue>
        </S.SectorCardLoadingInfoBox>

        <S.SectorCardLoadingInfoBox>
          <S.SectorCardLoadingInfoBoxTitle className="loading"></S.SectorCardLoadingInfoBoxTitle>
          <S.SectorCardLoadingInfoBoxValue className="loading"></S.SectorCardLoadingInfoBoxValue>
        </S.SectorCardLoadingInfoBox>

        <S.SectorCardLoadingInfoButtonsBox>
          <S.SectorCardLoadingInfoButton className="loading"></S.SectorCardLoadingInfoButton>
          <S.SectorCardLoadingInfoButton className="loading"></S.SectorCardLoadingInfoButton>
          <S.SectorCardLoadingInfoButton className="loading"></S.SectorCardLoadingInfoButton>
        </S.SectorCardLoadingInfoButtonsBox>

        <S.SectorCardLoadingInfoBox>
          <S.SectorCardLoadingInfoBoxTitle className="loading"></S.SectorCardLoadingInfoBoxTitle>
          <S.SectorCardLoadingInfoBoxValue className="loading"></S.SectorCardLoadingInfoBoxValue>
        </S.SectorCardLoadingInfoBox>

        <S.SectorCardLoadingInfoBox2>
          <S.SectorCardLoadingInfoBoxTitle className="loading"></S.SectorCardLoadingInfoBoxTitle>
          <S.SectorCardLoadingInfoBoxValue2 className="loading"></S.SectorCardLoadingInfoBoxValue2>
          <S.SectorCardLoadingInfoBoxPercBar className="loading"></S.SectorCardLoadingInfoBoxPercBar>
        </S.SectorCardLoadingInfoBox2>

        <S.SectorCardLoadingInfoBox2>
          <S.SectorCardLoadingInfoBoxTitle className="loading"></S.SectorCardLoadingInfoBoxTitle>
          <S.SectorCardLoadingInfoBoxValue2 className="loading"></S.SectorCardLoadingInfoBoxValue2>
          <S.SectorCardLoadingInfoBoxPercBar className="loading"></S.SectorCardLoadingInfoBoxPercBar>
        </S.SectorCardLoadingInfoBox2>
      </S.SectorCardLoadingFooter>
    </S.SectorCardLoadingBox>
  );
}
