import styled from 'styled-components';

export const ManagePolygonMainBox = styled.section`
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 10px;
  font-size: 1.2rem;
  position: relative;
`;

export const ManagePolygonButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 7.5px;
  position: absolute;
  top: 5px;
  right: 25px;
`;

export const ManagePolygonFooterButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 7.5px;
  padding-left: 5px;
`;

export const ManagePolygonButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 5px;
  border: none;
  border-radius: 4px;
`;

export const ManagePolygonButtonSuccess = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 5px;
  border: none;
  border-radius: 4px;
  background-color: #228f6b20;
  width: 90%;
  color: #fff;
  color: #228f6b;
  font-weight: 600;

  svg {
    color: #228f6b;
  }

  &:hover {
    background-color: #228f6b40;
  }
`;

export const ManagePolygonButtonDangerTrash = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 5px;
  border: none;
  border-radius: 4px;
  background-color: #cb2a2920;
  color: #cb2a29;

  &:hover {
    background-color: #cb2a2940;
  }
`;

export const ManagePolygonButtonDangerCancel = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 7px;
  border: none;
  border-radius: 4px;
  background-color: #cb2a2920;
  color: #cb2a29;

  &:hover {
    background-color: #cb2a2940;
  }
`;

export const ManagePolygonButtonInfo = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 5px;
  border: none;
  border-radius: 4px;
  background-color: #375e9720;
  color: #375e97;

  &:hover {
    background-color: #375e9740;
  }
`;

interface NameBoxProps {
  inputBorder: boolean;
}

export const ManagePolygonNameBox = styled.div<NameBoxProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 7.5px;
  width: calc(70% - 5px);
  font-weight: 500;

  .p-inputtext {
    border: ${(props) => (props.inputBorder ? 'auto' : 'none')};
    font-size: 1.1rem;
  }
`;

export const ManagePolygonAreaBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 7.5px;
  width: 100%;
  color: #606060;
  font-size: 0.9rem;
  padding-left: 5px;
`;

export const ManagePolygonColorBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5px;
  gap: 5px;
`;

export const ManagePolygonColorTitle = styled.span`
  font-size: 0.8rem;
`;
