import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { getAllKpi, getAllKpiCampanhas, getAllKpiOnCampanhas } from 'client/api/kpis';
import { KpiAllReturnProps, KpiCampanhaReturnProps } from 'client/interfaces';

export const useAllKpis = (codEmpresa: number | string, enabled = true) =>
  useQuery<KpiAllReturnProps, AxiosError>(['kpis', codEmpresa], async () => await getAllKpi(codEmpresa), {
    enabled,
  });

export const useAllKpisCampanhas = (codEmpresa: number | string, codregra: number | string, enabled = true) =>
  useQuery<KpiCampanhaReturnProps[], AxiosError>(
    ['kpis', codEmpresa],
    async () => await getAllKpiCampanhas(codEmpresa, codregra),
    {
      enabled,
    },
  );

export const useAllKpisOnCampanha = (codEmpresa: number | string, codCampanha: string | number, enabled = true) =>
  useQuery<KpiAllReturnProps, AxiosError>(
    ['kpisCampanha', codEmpresa],
    async () => await getAllKpiOnCampanhas(codEmpresa, codCampanha),
    {
      enabled,
    },
  );
