const dataList = [
  {
    nroResposta: '46345122024041004',
    dtresposta: '2024-12-04T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 4',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM223141151122024061045-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041005',
    dtresposta: '2024-12-05T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 5',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'INPUT'
  },
  {
    nroResposta: '46345122024041006',
    dtresposta: '2024-12-06T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 6',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041007',
    dtresposta: '2024-12-07T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 7',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM223141151122024061045-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041008',
    dtresposta: '2024-12-08T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 8',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041009',
    dtresposta: '2024-12-09T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 9',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM223141151122024061045-CAMPO1-1.JPG',
    tipo: 'INPUT'
  },
  {
    nroResposta: '46345122024041010',
    dtresposta: '2024-12-10T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 10',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041011',
    dtresposta: '2024-12-11T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 11',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041012',
    dtresposta: '2024-12-12T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 12',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'INPUT'
  },
  {
    nroResposta: '46345122024041013',
    dtresposta: '2024-12-13T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 13',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041014',
    dtresposta: '2024-12-14T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 14',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM223118368112024281942-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041015',
    dtresposta: '2024-12-15T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 15',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'INPUT'
  },
  {
    nroResposta: '46345122024041016',
    dtresposta: '2024-12-16T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 16',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM223118368112024281942-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041017',
    dtresposta: '2024-12-17T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 17',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM22346345122024041004-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },
  {
    nroResposta: '46345122024041018',
    dtresposta: '2024-12-18T10:05:01',
    codEmpresa: 122008,
    codfilial: 25,
    nroform: 223,
    form: 'Pesquisa PDV 18',
    codrca: 9159,
    vendedor: 'RAPHAEL PEREIRA CAMPOS GONÇALVES',
    codcli: 46345,
    cliente: 'Nome do cliente',
    nroCampo: 1,
    resposta: 'FORM223118368112024281942-CAMPO1-1.JPG',
    tipo: 'FOTO'
  },

  
];

export default dataList;
