import { RiRoadMapLine } from 'react-icons/ri';
import { SetoresProps } from '../../../regioes.types';
import * as S from './sectorCard.styles';
import { TbPencil } from 'react-icons/tb';
import { MdOutlinePersonSearch } from 'react-icons/md';
import { useMap } from '../../../regioes.context';
import { Checkbox } from 'primereact/checkbox';
import { useLoaderEffect } from 'providers/loaderEffect';
import { memo, SetStateAction, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { dialog, Tag } from 'reactivus';

const MemoizedCheckbox = memo(({ sector, selectedSetores, setSelectedSetores, setLoader, noSectorControl }: any) => {
  const handleCheckboxClick = useCallback(
    (e) => {
      setLoader({
        show: true,
        text: 'Por favor aguarde...',
      });

      setTimeout(() => {
        if (selectedSetores?.some((s: any) => s.CODSETOR === sector.CODSETOR)) {
          setSelectedSetores((prevState: any) => prevState.filter((s: any) => s.CODSETOR !== sector.CODSETOR));
        } else {
          setSelectedSetores((prevState: any) => [...(prevState || []), sector]);
        }
      }, 0);
    },
    [sector, noSectorControl, setLoader],
  );

  return (
    <Checkbox
      inputId={'selectSector' + sector?.CODSETOR}
      checked={selectedSetores?.some((s: any) => s.CODSETOR === sector.CODSETOR)}
      onChange={(e) => handleCheckboxClick(e)}
    />
  );
});

export default function SectorCard({
  sector,
  setShowMapEditSetor,
  setSetorToEdit,
  setShowMenu,
}: {
  sector: SetoresProps;
  setShowMapEditSetor: React.Dispatch<SetStateAction<boolean>>;
  setSetorToEdit: React.Dispatch<SetStateAction<SetoresProps>>;
  setShowMenu: React.Dispatch<SetStateAction<boolean>>;
}) {
  const {
    noSectorControl,
    setSelectedSetores,
    selectedSetores,
    setScreenMode,
    updateMapCenter,
    mapInstance,
    clientes,
  } = useMap();

  const { setLoader } = useLoaderEffect();

  const navigate = useNavigate();

  interface PotencialDataProps {
    vendaPotencial: number;
    vendaUltTri: number;
    vendaPercUltTri?: number;
    vendaUltMes: number;
    vendaPercUltMes?: number;
  }
  interface PercBarProps {
    value: number;
  }

  const numberFormat = (number: number) => {
    if (number != null && number.toString() == 'Infinity') {
      number = 100.0;
    }
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(number).replace('$', '');
  };

  const PercBar = ({ value }: PercBarProps) => {
    return (
      <S.PercBarBox>
        <S.PercBar
          width={value}
          color={value <= 25 ? '#f32929' : value <= 50 ? '#FFC300' : value <= 70 ? '#F64F3D' : '#228F6B'}
        ></S.PercBar>
      </S.PercBarBox>
    );
  };

  const vendaPotencialData = (props: PotencialDataProps, clientes: number) => {
    props.vendaPercUltTri = props.vendaUltTri < 1 ? 0 : +((props.vendaUltTri * 100) / props.vendaPotencial).toFixed(2);
    props.vendaPercUltMes = props.vendaUltMes < 1 ? 0 : +((props.vendaUltMes * 100) / props.vendaPotencial).toFixed(2);

    return (
      <S.SetorVendaPotencialRow>
        <S.SetorVendaPotencialRowContent>
          <span>Potencial:</span>
          <p>R${numberFormat(props.vendaPotencial)}</p>
        </S.SetorVendaPotencialRowContent>
        <S.SetorVendaPotencialRowContent>
          <span>
            Ult. Trim: <span>R${numberFormat(props.vendaUltTri)}</span>
          </span>
          <S.PercentageBox>
            <span>{props.vendaPercUltTri}%</span>
          </S.PercentageBox>
          <PercBar value={props.vendaPercUltTri} />
        </S.SetorVendaPotencialRowContent>
        <S.SetorVendaPotencialRowContent>
          <span>
            Ult. Mês: <span>R${numberFormat(props.vendaUltMes)}</span>
          </span>
          <S.PercentageBox>
            <span>{props.vendaPercUltMes}%</span>
          </S.PercentageBox>
          <PercBar value={props.vendaPercUltMes} />
        </S.SetorVendaPotencialRowContent>
      </S.SetorVendaPotencialRow>
    );
  };

  const handleNavigateToRouter = () => {
    if (sector.COD_RCA) {
      navigate(`/roteirizador/${sector.COD_MAPA}/${sector.CODSETOR}/${sector.COD_RCA}/${sector.COD_EMPRESA}`);
    } else {
      dialog.show({
        icon: 'warning',
        title: 'Setor sem vendedor vinculado!',
        text: 'Vincule um vendedor para prosseguir com a roteirização.',
        confirmButtonText: 'Ok',
        showConfirmButton: true,
      });
    }
  };

  const handleCheckboxClick = useCallback(() => {
    setLoader({
      show: true,
      text: 'Por favor aguarde...',
    });

    setTimeout(() => {
      if (selectedSetores?.some((s: any) => s.CODSETOR === sector.CODSETOR)) {
        setSelectedSetores((prevState: any) => prevState.filter((s: any) => s.CODSETOR !== sector.CODSETOR));
      } else {
        setSelectedSetores((prevState: any) => [...(prevState || []), sector]);
      }
    }, 0);
  }, [sector, noSectorControl, setLoader]);

  const handleMapUpdateZoomInSector = () => {
    for (let i = 0; i < clientes?.unfiltered?.length; i++) {
      if (clientes?.unfiltered[i]?.CODSETOR == sector.CODSETOR) {
        updateMapCenter(clientes?.unfiltered[i], mapInstance.current, 12, false);
        const ktBody = document.getElementById('kt_body');
        if (ktBody) {
          ktBody.setAttribute('data-kt-aside-minimize', 'on');
        }
        return;
      }
    }
  };

  return (
    <S.SectorCardBox color={sector.COR}>
      <S.SectorCardHeader>
        <S.SectorCardHeaderSelect>
          <MemoizedCheckbox
            sector={sector}
            selectedSetores={selectedSetores}
            setSelectedSetores={setSelectedSetores}
            setLoader={setLoader}
            noSectorControl={noSectorControl}
          />
        </S.SectorCardHeaderSelect>
        <S.SectorCardHeaderContent>
          <S.SectorCardName
            onClick={() => {
              // handleCheckboxClick();
              handleMapUpdateZoomInSector();
            }}
          >{`[${sector.CODSETOR}] ${sector.NOMESETOR}`}</S.SectorCardName>
          {sector && sector.CODSETOR != -1 && sector.COD_RCA ? (
            <S.SectorCardRcaName>{`[${sector.COD_RCA}] ${sector.NOMERCA}`}</S.SectorCardRcaName>
          ) : (
            <S.SectorCardRcaName
              style={{
                minHeight: '1.2rem',
              }}
            ></S.SectorCardRcaName>
          )}
          {sector && sector.CODSETOR == -1 && (
            <S.SectorCardRcaName>[{sector.QTD_CLIENTES}] Clientes </S.SectorCardRcaName>
          )}
        </S.SectorCardHeaderContent>
        <S.SectorCardHeaderTags>
          {sector.ATIVO == 'N' && sector.CODSETOR != -1 && <Tag color="danger" label="INATIVO" text rounded />}
          {sector.COD_RCA == null && sector.CODSETOR != -1 && <Tag color="warning" label="VAGO" text rounded />}
        </S.SectorCardHeaderTags>
      </S.SectorCardHeader>

      {sector && sector.CODSETOR != -1 && (
        <S.SectorCardInfo>
          <S.SectorCardCliInfo>
            <span>Clientes</span>
            <span>{sector.QTD_CLIENTES}</span>
          </S.SectorCardCliInfo>

          <S.SectorCardKmInfo>
            <span>Total KM</span>
            <span>{sector.TOTAL_KM}</span>
          </S.SectorCardKmInfo>

          <S.SectorCardButtonsBox mapColor={sector.ATUALIZA_AUTOMATICO === 'S' ? '#375e97' : '#cb2a29'}>
            <TbPencil
              onClick={() => {
                setShowMapEditSetor(true);
                setSetorToEdit(sector);
                handleMapUpdateZoomInSector();
              }}
            />
            <MdOutlinePersonSearch
              onClick={() => {
                setScreenMode('G');
                setSelectedSetores([sector]);
                setShowMenu(false);
              }}
            />
            <RiRoadMapLine
              onClick={() => {
                handleNavigateToRouter();
              }}
            />
          </S.SectorCardButtonsBox>
        </S.SectorCardInfo>
      )}

      {sector &&
        sector.CODSETOR != -1 &&
        vendaPotencialData(
          {
            vendaPotencial: sector.VL_POTENCIA ?? 0,
            vendaUltTri: sector.VL_MED_ULT_TRIM ?? 0,
            vendaUltMes: sector.VL_MES_ATUAL ?? 0,
          },
          sector.QTD_CLIENTES,
        )}
    </S.SectorCardBox>
  );
}
