import { useQueryClient } from '@tanstack/react-query';
import { postCompany } from 'client/api';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface IForm {
  codEmpresa: string;
  cnpj: string;
  razaosocial: string;
  fantasia: string;
  codSisERP: 1 | 2;
  urlServFV: string;
  urlServGerencial: string;
  urlServRelatorio: string;
  urlservApiClient: string;
  bdNome: string;
  bdUSU: string;
  bdPSW: string;
  ativo: string;
  dtInativo: string;
  obsInativo: string;
  caminhoLogo: string;
  bdSGBD: string;
  bdHost: string;
}

export const UseAddCompany = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IForm>({});
  
  const queryClient = useQueryClient();

  const values = watch();

  const navigate = useNavigate();

  const onSubmit = async (data: IForm) => {
    await postCompany({
      codEmpresa: Number(data.codEmpresa),
      cnpj: data.cnpj,
      razaosocial: data.razaosocial,
      fantasia: data.fantasia,
      codSisErp: data.codSisERP ?? 0,
      urlServFv: data.urlServFV ?? '',
      urlServGerencial: data.urlServGerencial ?? '',
      urlServRelatorio: data.urlServRelatorio ?? '',
      urlServApicliente: data.urlservApiClient ?? '',
      bdNome: data.bdNome ?? '',
      bdUsu: data.bdUSU ?? '',
      bdPsw: data.bdPSW ?? '',
      ativo: data.ativo ?? '',
      dtinativo: data.dtInativo ?? '',
      obsinativo: data.obsInativo ?? '',
      caminhoLogo: data.caminhoLogo ?? '',
      bdSgbd: data.bdSGBD ?? '',
      bdHost: data.bdHost ?? '',
    })
      .then(() => {
        toast.success('Sucesso ao Criar Empresa.');
        navigate('/empresas');
        reset();
        queryClient.invalidateQueries(['empresas-list']);
      })
      .catch(() => {
        toast.error('Erro ao Criar Empresa!');
      });
  };

  return {
    handleSubmit,
    control,
    onSubmit,
    setValue,
    values,
  };
};
