import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getVendedoresByUsuario } from 'client/api/vendedores';
import { getCidades, getCidadesBySearchText } from 'client/api';

type SelectedCidadeProps = {
  codCidade?: number;
  nomeCidade?: string;
  codUf?: number;
  uf?: string;
  label?: string;
};

const UseSelectCidade = (codUf: number) => {
  const { data: user } = useUser();
  const [cidades, setCidades] = useState<any[]>([]);
  const [selectedCidade, setSelectedCidade] = useState<SelectedCidadeProps>(() => {
    const city = JSON.parse(localStorage.getItem('vmCidData') || 'null');
    return (
      city || {
        codCidade: city ? city.codCidade : -1,
        nomeCidade: '',
      }
    );
  });

  useEffect(() => {
    if (user) {
      getCidades([codUf]).then((cities) => {
        const formattedCidades = cities?.map((c) => ({
          ...c,
          label: `[${c.codcidade}] - ${c.cidade}`,
          nomeCidade: c.cidade,
          codcidade: c.codcidade,
          codUf: c.coduf,
        }));
        setCidades(formattedCidades);
      });
    }
  }, [user, codUf]);

  const handleCityChange = (city: SelectedCidadeProps) => {
    setSelectedCidade(city);
    localStorage.setItem('vmCidData', JSON.stringify(city));
  };

  const SelectCidade = ({ onChange, width }: { onChange?: (codUsuario: number) => void; width?: string }) => {
    const widthCalculated = +(`[${selectedCidade.codCidade}] - ${selectedCidade?.nomeCidade}`.length * 8);

    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: clamp(100px, ${width ?? `${widthCalculated}px`}, ${width ?? '175px'});
    `;

    const handleChange = (city: SelectedCidadeProps) => {
      handleCityChange(city);
      if (onChange) {
        onChange(city.codCidade);
      }
    };

    return (
      <InputBox>
        <label>Cidade</label>
        <Dropdown
          value={selectedCidade}
          onChange={(e) => handleChange(e.value)}
          options={cidades}
          optionLabel="nomeCidade"
          filter
          placeholder="Selecione uma Cidade"
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
        />
      </InputBox>
    );
  };

  return {
    SelectCidade,
    selectedCidade,
    setSelectedCidade,
  };
};

export default UseSelectCidade;
