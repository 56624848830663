import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getEstados } from 'client/api';

type SelectedEstadoProps = {
  label?: string;
  codUf?: number;
  uf?: string;
  nomeUf?: string;
};

const UseSelectEstado = () => {
  const { data: user } = useUser();
  const [estados, setEstados] = useState<any[]>([]);
  const [selectedUf, setSelectedUf] = useState<SelectedEstadoProps>(() => {
    const uf = JSON.parse(localStorage.getItem('vmUfsData') || 'null');
    return (
      uf || {
        codUf: uf ? uf.codUf : -1,
        nomeUf: uf ? uf.nomeUf : '',
      }
    );
  });

  useEffect(() => {
    localStorage.removeItem('vmUfsData');
    if (user) {
      const codPais = 1058;
      getEstados(codPais).then((ufs: any[]) => {
        const formattedUfs = [];
        for (const uf of ufs) {
          formattedUfs.push({
            ...uf,
            label: `${uf.uf}`,
            codUf: uf.coduf,
          });
        }
        setEstados(formattedUfs);
      });
    }
  }, [user]);

  const handleUfChange = (uf: SelectedEstadoProps) => {
    setSelectedUf(uf);
    localStorage.setItem('vmUfsData', JSON.stringify(uf));
  };

  const SelectEstado = ({ onChange, width }: { onChange?: (codUf: number) => void; width?: string }) => {
    const widthCalculated = +(`[${selectedUf.codUf}] - ${selectedUf?.nomeUf}`.length * 8);

    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: clamp(50px, ${width ?? `${widthCalculated}px`}, ${width ?? '175px'});
    `;

    const handleChange = (uf: SelectedEstadoProps) => {
      handleUfChange(uf);
      if (onChange) {
        onChange(uf.codUf);
      }
    };

    return (
      <InputBox>
        <label>UF</label>
        <Dropdown
          value={selectedUf}
          onChange={(e) => handleChange(e.value)}
          options={estados}
          optionLabel="label"
          filter
          placeholder="UF"
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
        />
      </InputBox>
    );
  };

  return {
    SelectEstado,
    selectedUf,
    setSelectedUf,
  };
};

export default UseSelectEstado;
