import * as S from './regrasConfig.styles';

import { RegraProps, RegraItemProps, RegraRestricaoProps } from 'client/interfaces';
import ItensTable from '../itensTable/itensTable.index';
import RestricoesTable from '../restricoesTable/restricoesTable.index';

import { useParams } from 'react-router-dom';
import KpisTable from '../kpisTable/kpisTable.index';

type regrasConfigProps = {
  selectedRegra: RegraProps | null;
  itensList: RegraItemProps[];
  restricoesList: RegraRestricaoProps[];
  codEmpr: number;
  handleGetRegrasComerciais: () => void;
};

export default function RegrasConfig({
  selectedRegra,
  itensList,
  restricoesList,
  codEmpr,
  handleGetRegrasComerciais,
}: regrasConfigProps) {
  const regraTypeOptions = {
    combos: { title: 'Combos', type: 'C' },
    alerta: { title: 'Alerta', type: 'A' },
    'mix-sugerido': { title: 'Mix Sugerido', type: 'I' },
    lista: { title: 'Lista (Vb. Bnf)', type: 'L' },
    'bloqueio-venda': { title: 'Bloqueio Venda', type: 'B' },
    campanhas: { title: 'Campanhas', type: 'K' },
  };

  const { id: regraType } = useParams();

  return (
    <S.RegrasConfigMainBox>
      {regraTypeOptions[regraType] && regraTypeOptions[regraType].type != 'K' && (
        <S.RegrasConfigItem>
          <ItensTable
            selectedRegra={selectedRegra}
            codEmpr={codEmpr}
            handleGetRegrasComerciais={handleGetRegrasComerciais}
          />
        </S.RegrasConfigItem>
      )}
      <S.RegrasConfigItem>
        <RestricoesTable
          selectedRegra={selectedRegra}
          codEmpr={codEmpr}
          handleGetRegrasComerciais={handleGetRegrasComerciais}
        />
      </S.RegrasConfigItem>

      {regraTypeOptions[regraType] && regraTypeOptions[regraType].type == 'K' && (
        <S.RegrasConfigItem>
          <KpisTable
            selectedRegra={selectedRegra}
            codEmpr={codEmpr}
            handleGetRegrasComerciais={handleGetRegrasComerciais}
          />
        </S.RegrasConfigItem>
      )}
    </S.RegrasConfigMainBox>
  );
}
