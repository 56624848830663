import * as S from './kpisTable.styles';

import { RegraProps, RegraRestricaoProps } from 'client/interfaces';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Accordion, Button } from 'reactivus';
import { useEffect, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import NovoKpiModal from '../novoKpiModal/novoKpiModal.index';
import { getAllKpiOnCampanhas, postDeleteKpiOnCampanha } from 'client/api/kpis';

type itensTableProps = {
  selectedRegra: RegraProps;
  codEmpr: number;
  handleGetRegrasComerciais: () => void;
};

export default function KpisTable({ selectedRegra, codEmpr, handleGetRegrasComerciais }: itensTableProps) {
  const [kpisList, setKpisList] = useState([]);
  const [showNovaRestricaoModal, setShowNovaRestricaoModal] = useState(false);

  useEffect(() => {
    if (selectedRegra && selectedRegra.codregra) {
      handleGetKpisOnCampanhas();
    }
  }, [selectedRegra]);

  const handleGetKpisOnCampanhas = () => {
    getAllKpiOnCampanhas(codEmpr, selectedRegra.codregra).then((res) => {
      const kpis = adjustKpisListIndexes(res.data ?? [], 'nroKpi', 'tituloKpi');
      setKpisList(kpis ?? []);
    });
  };

  function adjustKpisListIndexes(arr: any[], txt1: string, txt2: string): any[] {
    const newTxt1 = 'codigorestricao';
    const newTxt2 = 'descricao';
    const keyMap: { [key: string]: string } = {
      [txt1]: newTxt1,
      [txt2]: newTxt2,
    };
    return arr.map((obj) => {
      const renamedObject: any = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key) && keyMap[key]) {
          const newKey = keyMap[key];
          renamedObject[newKey] = obj[key];
        }
      }
      return renamedObject;
    });
  }

  const removeRestricaoBodyTemplate = (rowData: RegraRestricaoProps) => {
    return (
      <Button
        icon={<FaTrash />}
        color={'danger'}
        text
        onClick={(e: any) => {
          handleRemoveKpiOnCampanha(rowData.codigorestricao);
        }}
      />
    );
  };

  const handleRemoveKpiOnCampanha = (nroKpi: number) => {
    const kpiToRemove = {
      codCampanha: selectedRegra.codregra,
      nroKpiList: [nroKpi],
    };
    postDeleteKpiOnCampanha(kpiToRemove)
      .then((res) => {
        if (res.succeeded) {
          handleGetRegrasComerciais();
        }
      })
      .catch((err) => {
        toast.error('Falha inesperada ao remover vinculo KPI');
        console.log('err :', err);
      });
  };

  const AccordionHeaderTemplate = () => {
    return (
      <S.AccordionHeaderTemplateBox>
        <span>KPIs</span>
        <Button
          icon={<FaPlus />}
          color={selectedRegra ? 'success' : 'light'}
          text
          label="Adicionar KPI"
          shadow
          size="sm"
          onClick={(e: any) => {
            e.stopPropagation();
            selectedRegra && setShowNovaRestricaoModal(true);
          }}
          disabled={!selectedRegra}
        />
      </S.AccordionHeaderTemplateBox>
    );
  };

  return (
    <Accordion title={'KPIs'} headerTemplate={<AccordionHeaderTemplate />}>
      <S.RestricoesTableMainBox>
        <S.RestricoesBodyBox>
          <DataTable
            value={kpisList ?? []}
            paginator={false}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            rows={100}
            rowHover
            stripedRows
            scrollable
            scrollHeight="57dvh"
            style={{ maxWidth: '100%', minHeight: '57dvh' }}
            emptyMessage={
              selectedRegra ? 'Esta regra não possui restrições' : 'Selecione uma regra pra visualizar as restrições'
            }
          >
            <Column
              field="codigorestricao"
              header="Cód."
              sortable
              style={{
                maxWidth: '100px',
              }}
            />
            <Column
              field="descricao"
              header="Descrição"
              sortable
              style={{
                minWidth: '40%',
              }}
            />
            <Column field="" header="Remover" body={removeRestricaoBodyTemplate} />
          </DataTable>
        </S.RestricoesBodyBox>
      </S.RestricoesTableMainBox>
      <NovoKpiModal
        showNovaRestricaoModal={showNovaRestricaoModal}
        setShowNovaRestricaoModal={setShowNovaRestricaoModal}
        codEmpresa={codEmpr}
        handleGetRegrasComerciais={handleGetRegrasComerciais}
        selectedRegra={selectedRegra}
        campanhaKpis={kpisList ?? []}
      />
    </Accordion>
  );
}
