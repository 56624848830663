import { UseAddCompany } from 'hooks/useCompanies/useAddCompany';
import * as S from './styles';
import { Controller } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { useEffect, useState } from 'react';
import { BsPlusLg } from 'react-icons/bs';
import VmButton from 'components/VmButton/VmButton';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { OPTIONS_ACTIVE_INATIVE } from 'enums/selectOptions';
import { TITLE_PAGE } from 'enums/titlePages';
import { useErps } from 'client/hooks';
import { FaCheck } from 'react-icons/fa';

export const AddCompany = () => {
  const { onSubmit, handleSubmit, control, setValue } = UseAddCompany();

  const navigate = useNavigate();
  const { data: erpsList, isLoading, error } = useErps();

  const setTitlePage = useTitlePageStore((state) => state.setTitlePage);

  useEffect(() => {
    setTitlePage(TITLE_PAGE.ADD_COMPANIES);
  }, []);

  return (
    <S.Body>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>Dados da Empresa</h3>
        <S.Container>
          <S.Row>
            <Controller
              name="codEmpresa"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Código Empresa*</label>
                  <InputText max={6} {...field} placeholder="000000" />
                </S.Box>
              )}
            />

            <Controller
              name="cnpj"
              control={control}
              rules={{
                required: true,
                validate: (value) => {
                  const cleanedValue = value.replace(/\D/g, '');
                  return cleanedValue.length === 14 || 'CNPJ deve ter 14 dígitos';
                },
              }}
              render={({ field, fieldState }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>CNPJ*</label>
                  <InputMask
                    {...field}
                    placeholder="XX.XXX.XXX/0001-XX"
                    style={{
                      padding: '6.5px',
                      border: '1px solid #ced4da ',
                      borderRadius: '3px',
                    }}
                    mask="99.999.999/9999-99"
                    name="cnpj"
                  />
                  {fieldState.error && <label style={{ color: 'red' }}>{fieldState.error.message}</label>}
                </S.Box>
              )}
            />

            <Controller
              name="ativo"
              rules={{
                required: true,
              }}
              control={control}
              defaultValue="S"
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Ativa/Inativa*</label>
                  <Dropdown
                    placeholder="Ativa/Inativa "
                    value={field.value}
                    options={OPTIONS_ACTIVE_INATIVE}
                    onChange={(e) => setValue('ativo', e.value)}
                    disabled
                  />
                </S.Box>
              )}
            />
          </S.Row>

          <S.Row>
            <Controller
              name="razaosocial"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Razão Social*</label>
                  <InputText {...field} placeholder="Digite Razão Social..." />
                </S.Box>
              )}
            />
            <Controller
              name="fantasia"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Nome Fantasia*</label>
                  <InputText {...field} placeholder="Digite Nome Fantasia..." />
                </S.Box>
              )}
            />
            <S.Box />
          </S.Row>
        </S.Container>
        <h3>Configurações</h3>
        <S.Container>
          <S.Row>
            <Controller
              name="codSisERP"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>Sistema ERP</label>
                  <Dropdown
                    placeholder="Selecione um Sistema ERP"
                    value={field.value}
                    options={erpsList}
                    onChange={(e) => setValue('codSisERP', e.value)}
                    itemTemplate={(erp) => (erp ? `[${erp.codSisErp}] ${erp.nomeErp}` : 'Selecione um Sistema ERP')}
                    valueTemplate={(erp) => {
                      const actualValue = erpsList?.filter((erp) => +erp.codSisErp == +field.value)[0];
                      return actualValue
                        ? `[${actualValue.codSisErp}] ${actualValue.nomeErp}`
                        : 'Selecione um Sistema ERP';
                    }}
                    optionValue="codSisErp"
                    optionLabel="nomeErp"
                  />
                </S.Box>
              )}
            />
          </S.Row>
          <S.Separator />

          <p>URLS Servidor</p>
          <S.Row>
            <Controller
              name="urlServFV"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>URL Servidor FV</label>
                  <InputText {...field} placeholder="www.servidorFV.com.br" />
                </S.Box>
              )}
            />

            <Controller
              name="urlServGerencial"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>URL Servidor Gerencial</label>
                  <InputText {...field} placeholder="www.servidorGerencial.com.br" />
                </S.Box>
              )}
            />

            <Controller
              name="urlServRelatorio"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>URL Servidor Relatório</label>
                  <InputText {...field} placeholder="www.servidorRelatório.com.br" />
                </S.Box>
              )}
            />
          </S.Row>
          <S.Row>
            <Controller
              name="urlservApiClient"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>URL Servidor API Cliente</label>
                  <InputText {...field} placeholder="www.servidorApiCliente.com.br" />
                </S.Box>
              )}
            />
          </S.Row>
          <S.Separator />

          <p>Banco de Dados</p>
          <S.Row>
            <Controller
              name="bdNome"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD Nome</label>
                  <InputText {...field} placeholder="BD Nome" />
                </S.Box>
              )}
            />

            <Controller
              name="bdUSU"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD USU</label>
                  <InputText {...field} placeholder="BD USU" />
                </S.Box>
              )}
            />

            <Controller
              name="bdPSW"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD PSW</label>
                  <InputText {...field} placeholder="BD PSW" />
                </S.Box>
              )}
            />
          </S.Row>
          <S.Row>
            <Controller
              name="bdSGBD"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD SGBD</label>
                  <InputText {...field} placeholder="BD SGBD" />
                </S.Box>
              )}
            />

            <Controller
              name="bdHost"
              rules={{
                required: false,
              }}
              control={control}
              render={({ field }) => (
                <S.Box style={{ width: '30%' }}>
                  <label>BD HOST</label>
                  <InputText {...field} placeholder="BD HOST" />
                </S.Box>
              )}
            />

            <S.Box />
          </S.Row>
        </S.Container>

        <S.Row style={{ marginTop: '10px', marginBottom: '10px' }}>
          <VmButton
            style={{
              marginRight: '10px',
            }}
            options={{
              style: 'btn-success',
              label: 'Salvar',
              icon: <FaCheck />,
              iconPosition: 'left',
            }}
            onClick={() => {}}
          />

          <VmButton
            style={{
              marginRight: '10px',
            }}
            options={{
              style: 'btn-danger',
              label: 'Cancelar',
              icon: <BsPlusLg />,
              iconPosition: 'right',
            }}
            onClick={() => {
              navigate('/empresas');
            }}
          />
        </S.Row>
      </form>
    </S.Body>
  );
};
