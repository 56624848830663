import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getVendedoresByUsuario } from 'client/api/vendedores';

type SelectedVendedorProps = {
  codVendedor: number;
  nomeVendedor?: string;
  bloqueio?: string;
  codEmpresa?: number;
  codFilial?: number;
  codGerente?: number;
  codSupervisor?: number;
  dtLatlong?: string | null;
  dtTermino?: string | null;
  latitude?: string;
  longitude?: string;
  nome?: string;
};

const UseSelectVendedor = (codEmpresa: number, codUsuario: number, codGerente?: number, codSupervisor?: number) => {
  const { data: user } = useUser();
  const [vendedores, setVendedores] = useState<any[]>([]);
  const [selectedVendedor, setSelectedVendedor] = useState<SelectedVendedorProps>(() => {
    const rca = JSON.parse(localStorage.getItem('vmRcaData') || 'null');
    return (
      rca || {
        codVendedor: rca ? rca.codVendedor : -1,
        nomeVendedor: '',
      }
    );
  });

  useEffect(() => {
    localStorage.removeItem('vmRcaData');
    if (user && codEmpresa && codUsuario) {
      getVendedoresByUsuario(codEmpresa, codUsuario).then((rcas: any[]) => {
        const formattedRcas = [];
        for (const v of rcas) {
          if (
            (codGerente != undefined && codGerente != -1 && +v.codGerente == +codGerente) ||
            (codSupervisor != undefined && codSupervisor != -1 && +v.codSupervisor == +codSupervisor) ||
            (codGerente == undefined && codSupervisor == undefined)
          ) {
            formattedRcas.push({
              ...v,
              label: `[${v.codVendedor}] - ${v.nome}`,
              nomeVendedor: v.nome,
            });
          }
        }
        setVendedores(formattedRcas);
      });
    }
  }, [user, codEmpresa, codEmpresa, codUsuario, codGerente, codSupervisor]);

  const handleUserChange = (emp: SelectedVendedorProps) => {
    setSelectedVendedor(emp);
    localStorage.setItem('vmRcaData', JSON.stringify(emp));
  };

  const SelectVendedor = ({
    onChange,
    width,
    label = 'Vendedor',
    placeholder = 'Selecione um Vendedor',
  }: {
    onChange?: (codUsuario: number) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {
    const widthCalculated = +(`[${selectedVendedor.codVendedor}] - ${selectedVendedor?.nomeVendedor}`.length * 8);

    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: clamp(100px, ${width ?? `${widthCalculated}px`}, ${width ?? '175px'});
    `;

    const handleChange = (emp: SelectedVendedorProps) => {
      handleUserChange(emp);
      if (onChange) {
        onChange(emp.codVendedor);
      }
    };

    return (
      <InputBox>
        <label>{label}</label>
        <Dropdown
          value={selectedVendedor}
          onChange={(e) => handleChange(e.value)}
          options={vendedores}
          optionLabel="label"
          filter
          placeholder={placeholder}
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
        />
      </InputBox>
    );
  };

  return {
    SelectVendedor,
    selectedVendedor,
    setSelectedVendedor,
  };
};

export default UseSelectVendedor;
