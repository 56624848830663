import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import { getUsers } from 'client/api';
import styled from 'styled-components';

type SelectedUserProps = {
  codUsuario: number;
  nomeUsuario?: string;
};

const UseSelectUsuario = (codEmpresa: number) => {
  const { data: user } = useUser();
  const [usuarios, setUsuarios] = useState<any[]>([]);
  const [selectedUsuario, setSelectedUsuario] = useState<SelectedUserProps>(() => {
    const usr = JSON.parse(localStorage.getItem('vmUsrData') || 'null');
    return (
      usr || {
        codUsuario: user ? user.codUsuario : -1,
        nomeUsuario: '',
      }
    );
  });

  useEffect(() => {
    if (user) {
      if (user.codEmpresa === 2072021) {
        getUsers(codEmpresa).then((users: any[]) => {
          const formattedUsers = users.map((u) => ({
            ...u,
            label: `[${u.codUsuario}] - ${u.nome}`,
            nomeUsuario: u.nome,
          }));
          setUsuarios(formattedUsers);
        });
      } else {
        const defaultUsuario = {
          codUsuario: user.codUsuario,
          nomeUsuario: '',
        };
        setSelectedUsuario(defaultUsuario);
        localStorage.setItem('vmUsrData', JSON.stringify(defaultUsuario));
      }
    }
  }, [user, usuarios.length, codEmpresa]);

  const handleUserChange = (emp: SelectedUserProps) => {
    setSelectedUsuario(emp);
    localStorage.setItem('vmUsrData', JSON.stringify(emp));
  };

  const SelectUsuario = ({
    onChange,
    width,
    label = 'Usuário',
    placeholder = 'Selecione um Usuário',
  }: {
    onChange?: (codUsuario: number) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {
    const widthCalculated = +(`[${selectedUsuario.codUsuario}] - ${selectedUsuario?.nomeUsuario}`.length * 8);

    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: clamp(100px, ${width ?? `${widthCalculated}px`}, ${width ?? '175px'});
    `;

    const handleChange = (emp: SelectedUserProps) => {
      handleUserChange(emp);
      if (onChange) {
        onChange(emp.codUsuario);
      }
    };

    if (user && user.codEmpresa === 2072021) {
      return (
        <InputBox>
          <label>{label}</label>
          <Dropdown
            value={selectedUsuario}
            onChange={(e) => handleChange(e.value)}
            options={usuarios}
            optionLabel="label"
            filter
            itemTemplate={(emp) => `[${emp.codUsuario}] - ${emp.nomeUsuario}`}
            placeholder={placeholder}
            emptyMessage="Nenhum dado encontrado"
            emptyFilterMessage="Nenhum dado encontrado"
          />
        </InputBox>
      );
    }
    return null;
  };

  return {
    SelectUsuario,
    getSelectededUsuario: () => selectedUsuario,
    selectedUsuario,
    setSelectedUsuario,
  };
};

export default UseSelectUsuario;
