/* eslint-disable @typescript-eslint/restrict-plus-operands */

import pointInPolygon from 'point-in-polygon';
import { Dialog } from 'primereact/dialog';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { toast } from 'react-toastify';
import VmButton from 'components/VmButton/VmButton';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { editPoly, postAdjustCliCoords } from 'client/api/areaMapa';
import useMediaQuery from 'hooks/useMediaQuery';
import { useMap } from '../../regioes.context';
import { useLoaderEffect } from 'providers/loaderEffect';

const MapCliUpdate = () => {
  const { handleGetAllData, clientes, setores, params } = useMap();
  const isWebScreen = useMediaQuery('(min-width: 1060px)');

  const [pendCliData, setPendCliData] = useState<any[]>([]);
  const [cliChangesList, setCliChanges] = useState<any[]>([]);
  const [pendCliCoordsData, setPendCliCoordsData] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);

  const { setLoader } = useLoaderEffect();

  const polygonos = useMemo(() => [], []);

  useEffect(() => {
    const validateData = async () => {
      try {
        await validaCliData();
      } catch (err) {
        console.error(err);
      }
    };

    validateData();
  }, [polygonos, clientes]);

  const validaCliData = async () => {
    const polys = polygonos;
    const clis = clientes.unfiltered;
    const polysToUpdate = [];
    const cliChanges = [];

    for (const poly of polys) {
      if (!poly.polygono?.[0]) continue;

      let coords = JSON.parse(poly.polygono[0].latlong);
      coords = coords[0]?.lat === undefined ? coords.map(([lat, lng]: [number, number]) => ({ lat, lng })) : coords;

      const clisInPoly = (await validarClientesNoPoligono(clis, coords))
        .filter((cli) => cli.inclusaoManual === 'N')
        .filter((cli) => cli.codSetor !== poly.codSetor);

      if (clisInPoly.length) {
        cliChanges.push({
          cliente: clisInPoly,
          setor: setores.unfiltered.filter((set) => set.CODSETOR === poly.codSetor),
        });

        polysToUpdate.push({
          codArea: 0,
          codSetor: poly.codSetor,
          codFilial: 1,
          codEmpresa: params.codEmpresa,
          latlngs: JSON.stringify(poly.latlngs),
          clientes: clisInPoly,
        });
      }
    }

    const pendCliCoordsDataList = clis.filter(
      (cli) => !cli.LATITUDE || !cli.LONGITUDE || +cli.LATITUDE == 0 || +cli.LONGITUDE == 0,
    );

    if (JSON.stringify(pendCliData) !== JSON.stringify(polysToUpdate)) {
      setPendCliData(polysToUpdate);
    }
    if (JSON.stringify(cliChangesList) !== JSON.stringify(cliChanges)) {
      setCliChanges(cliChanges);
    }
    if (JSON.stringify(pendCliCoordsData) !== JSON.stringify(pendCliCoordsDataList)) {
      setPendCliCoordsData(pendCliCoordsDataList);
    }
  };

  const validarClientesNoPoligono = useCallback((clientes: any[], poligono: any[]) => {
    const coordsPoligono = poligono.map((ponto) => [ponto.lng, ponto.lat]);

    return clientes
      .filter((cli) => cli.latitude && cli.longitude)
      .filter((cli) => pointInPolygon([cli.longitude, cli.latitude], coordsPoligono));
  }, []);

  const saveCliPendData = async () => {
    try {
      // if (pendCliData.length > 0) {
      //   await editPoly(pendCliData);
      //   if (pendCliCoordsData.length === 0) {
      //     handleGetAllData();
      //     setShowModal(false);
      //     toast.success('Clientes ajustados com sucesso!');
      //   }
      // }

      const arrayPieces = 20;

      if (pendCliCoordsData.length > 0) {
        setLoader({ show: false, text: 'Por favor aguarde (0%)' });
        setShowModal(false);

        for (let i = 0; i < pendCliCoordsData.length; i += arrayPieces) {
          const cliCoordsToAdjust = {
            codEmpresa: +params.codEmpresa,
            clientes: pendCliCoordsData.slice(i, i + arrayPieces).map((cli) => ({
              codCli: cli.CODCLI,
            })),
          };

          const reqsMadePerc = Math.min(Math.round((i / pendCliCoordsData.length) * 100), 100);
          setLoader({
            show: true,
            text: `Por favor aguarde (${reqsMadePerc}%)`,
          });

          await postAdjustCliCoords(cliCoordsToAdjust);
        }

        handleGetAllData();
        toast.success('Coordenadas dos clientes ajustadas com sucesso!');
      }
    } catch (error) {
      console.error(error);
      toast.error('Erro ao revalidar os dados!');
    } finally {
      setLoader({ show: false, text: '' });
    }
  };

  return pendCliData.length > 0 || pendCliCoordsData.length > 0 ? (
    <>
      <VmButton
        options={{
          icon: <BsInfoCircleFill />,
          label: `${pendCliData.length + pendCliCoordsData.length} ${
            pendCliData.length + pendCliCoordsData.length > 1 ? 'Pendências' : 'Pendência'
          }`,
          iconPosition: 'left',
          rounded: !isWebScreen,
          style: 'btn-danger',
          tooltip: {
            show: true,
            position: 'bottom',
            text: `${pendCliData.length + pendCliCoordsData.length} Pendência(s)`,
          },
        }}
        onClick={() => setShowModal(true)}
      />

      <Dialog
        visible={showModal}
        style={{ width: '32rem' }}
        breakpoints={{ '960px': '75vw', '641px': '90vw' }}
        header={`${pendCliData.length + pendCliCoordsData.length} ${
          pendCliData.length + pendCliCoordsData.length > 1 ? 'Clientes Irregulars' : 'Cliente Irregular'
        }`}
        modal
        className="p-fluid"
        onHide={() => setShowModal(false)}
      >
        <div style={{ margin: '20px 0' }}>
          <h4>Lista de alterações:</h4>
          {cliChangesList.map((change) => (
            <span key={change.cliente[0].codCli}>
              <br />
              Cliente: {change.cliente[0].codCli} - {change.cliente[0].razaosocial} será vinculado ao setor:{' '}
              {change.setor[0].codSetor} - {change.setor[0].setor}
              <br />
              <hr />
            </span>
          ))}
          <p>
            {`${pendCliCoordsData.length} ${
              pendCliCoordsData.length > 1
                ? 'clientes com coordenadas inválidas serão reprocessados.'
                : 'cliente com coordenadas inválidas será reprocessado.'
            }`}
          </p>
        </div>

        <div style={{ display: 'flex', gap: '10px' }}>
          <VmButton
            options={{
              label: 'Reprocessar Dados',
              iconPosition: 'left',
              rounded: false,
              style: 'btn-success',
            }}
            onClick={saveCliPendData}
          />
          <VmButton
            options={{
              label: 'Fechar',
              iconPosition: 'left',
              rounded: false,
              style: 'btn-danger',
            }}
            onClick={() => setShowModal(false)}
          />
        </div>
      </Dialog>
    </>
  ) : null;
};

export default MapCliUpdate;
