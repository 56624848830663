import styled from 'styled-components';

export const ControlHeaderMainBox = styled.section`
  position: absolute;
  top: 20.5px;
  left: 10px;
  z-index: 9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-left: 10px;

  select {
    background-color: #fafafa;
    color: #606060;
    outline: none;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    width: 100%;
    padding: 10px 5px;
    font-size: 1.15rem;
    border-radius: 5px;
  }
`;

export const TimerSelect = styled.select`
  background-color: #fafafa;
  color: #606060;
  outline: none;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  width: 100%;
  padding: 10px 5px;
  font-size: 1.15rem;
  border-radius: 5px;
`;
