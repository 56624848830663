import { useState } from 'react';
import { FomularioAnswerProps } from '../../AnaliseForms.types';
import * as S from './formDataList.styles';
import { DataTable } from 'primereact/datatable';
import { Panel } from 'primereact/panel';
import { Column } from 'primereact/column';
import { Image } from 'primereact/image';

import { BASE_URL } from 'services/api';

export default function FormDataList({ dataList }: { dataList: FomularioAnswerProps[] }) {
  const [expandedRows, setExpandedRows] = useState<any | null>(null);

  const formatDate = (date: string | Date) => {
    if (!date) return 'N/A';
    return new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date(date));
  };

  const fotoTemplate = (rowData: FomularioAnswerProps) => {
    console.log('FomularioRespostaProps :', rowData);
    return rowData.tipo === 'FOTO' ? (
      <S.FormRespImageBox>
        <Image src={`${BASE_URL}/fotos_formulario/${rowData.resposta}`} alt="Image" width="100" preview />
      </S.FormRespImageBox>
    ) : (
      rowData.resposta
    );
  };

  return (
    <S.FormDataListMainBox>
      <DataTable value={dataList} dataKey="nroResposta" responsiveLayout="scroll" rowHover>
        <Column field="dtresposta" header="Data Resposta" body={(rowData) => formatDate(rowData.dtresposta)} sortable />
        <Column
          header="Vendedor"
          field="nroform"
          body={(rowData) => `[${rowData.codrca}] ${rowData.vendedor ?? ''}`}
          sortable
        />
        <Column
          header="Cliente"
          field="nroform"
          body={(rowData) => `[${rowData.codcli}] ${rowData.cliente ?? 'Nome do cliente do formulário'}`}
          sortable
        />
        <Column header="Resposta" field="nroform" sortable body={fotoTemplate} />
      </DataTable>
    </S.FormDataListMainBox>
  );
}
