import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { ClientesProps } from 'modules/mapa/pages/Regioesv2/regioes.types';

interface FormEditFrequenceVisit {
  freqVisita: number;
  tempoMinVisita: number;
  dtProxVisita: string;
  sequenciaVisita: number;
  bloqRoteiro: string;
  agendamentoDoCliente: string;
}

interface IUseEditFrequenceVisit {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMarker: ClientesProps;
  codCompany: string;
  codMap: string;
}

export const UseEditFrequenceVisit = ({ setShowModal, selectedMarker, codCompany, codMap }: IUseEditFrequenceVisit) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid },
    setValue,
  } = useForm<FormEditFrequenceVisit>({});

  useEffect(() => {
    setValue('freqVisita', selectedMarker?.FREQVISITA ?? 0);
    setValue('tempoMinVisita', selectedMarker?.TEMPOMINVISITA ?? 0);
    if (selectedMarker && selectedMarker?.DT_PROX_VISITA) {
      const dtProxVisita = selectedMarker.DT_PROX_VISITA ? selectedMarker.DT_PROX_VISITA.split('T')[0].split('-') : [];
      setValue('dtProxVisita', dtProxVisita.join('-'));
    }
    setValue('sequenciaVisita', selectedMarker?.SEQUENCIA ?? 0);
    setValue('bloqRoteiro', selectedMarker?.BLOQ_ROTEIRO ?? 'N');
    setValue('agendamentoDoCliente', selectedMarker?.AGENDAMENTO_DO_CLIENTE ?? 'N');
  }, [selectedMarker]);

  return {
    handleSubmit,
    control,
    errors,
    isValid,
    setValue,
    reset,
  };
};
