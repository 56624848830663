import { getRouterStartPointsData } from 'client/api/ponto';
import { IRoteirizaData, IRouterStartPointsData } from 'client/interfaces';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface UseCreatePointProtocols {
  getPointList: () => void;
  pontosList: IRouterStartPointsData[];
  roteirizaSetor: IRoteirizaData;
  setRoteirizaSetor: React.Dispatch<React.SetStateAction<IRoteirizaData>>;
}

export const UseCreatePoint = (codEmpresa: number): UseCreatePointProtocols => {
  const [pontosList, setPontosList] = useState<IRouterStartPointsData[]>([]);
  const [roteirizaSetor, setRoteirizaSetor] = useState<IRoteirizaData>();

  const navigate = useNavigate();

  const getPointList = () => {
    if (roteirizaSetor === null) {
      navigate('/mapa');
    }

    getRouterStartPointsData(String(codEmpresa))
      .then((res) => {
        setPontosList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    getPointList,
    pontosList,
    roteirizaSetor,
    setRoteirizaSetor,
  };
};
