import styled from 'styled-components';

export const AnaliseFormMainBox = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
`;

export const AnaliseFormHeader = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
  flex-wrap: wrap;
  padding: 5px 10px;

  .p-dropdown {
    border-radius: 5px !important;
  }
`;

export const AnaliseFormContent = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 0px;
`;

interface AFInputBoxProps {
  width?: string;
}

export const AFInputBox = styled.div<AFInputBoxProps>`
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: ${({ width }) => width || "clamp(100px, 170px, 170px)"};
`;
