import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  background-color: #f7f8f9;

  height: 100vh;
  transition: all 0.4s ease;

  padding: 10px;
  width: 100%;
  height: 100%;

  /* background-color: #00000010; */

  table {
    white-space: nowrap;
  }

  .editableColumn {
    background-color: #60606010 !important;
    cursor: text;
  }
`;

export const ListTable = styled.div`
  position: absolute;
  width: 95%;
  height: 100%;
  bottom: 0;
  left: 0;
  padding-top: calc(4rem + 20px);
  /* background-color: #00000040; */

  .text-danger {
    color: red !important;
  }
`;

export const ActionsBox = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
`;

export const EditFieldInputBox = styled.div`
  display: flex;
  align-items: center;
  height: 2rem;
`;

export const EditFieldInput = styled.div`
  border: none;
  border-bottom: 1px solid #60606025;
  text-align: right;

  input {
    outline: none !important;
    border: none !important;
    text-align: left !important;
  }
`;
