import { UserValidateStatus } from 'client/interfaces';
import { CompanyForm, LoginForm, RegisterForm, UserForm } from 'modules/auth/components';
import {
  cpfCodErpSelector,
  empresaCNPJSelector,
  loginStatusSelector,
  missingPropertiesSelector,
  userSelector,
} from 'modules/auth/reducer/auth';
import { useAppSelector } from 'store';

const LoginPage = () => {
  const user = useAppSelector(userSelector);
  const empresaCNPJ = useAppSelector(empresaCNPJSelector);
  const cpfCodErp = useAppSelector(cpfCodErpSelector);
  const status = useAppSelector(loginStatusSelector);
  const missingProperties = useAppSelector(missingPropertiesSelector);

  if (!(empresaCNPJ != null)) return <CompanyForm />;
  if (!(user != null || cpfCodErp != null)) return <UserForm />;
  if (status === UserValidateStatus.Registered && missingProperties.length === 0) return <LoginForm />;
  if (status === UserValidateStatus.PendingRegistration || missingProperties.length > 0) return <RegisterForm />;
  return <LoginForm />;
};

export default LoginPage;
