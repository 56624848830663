import * as S from './controlMap.styles';
import { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default function ControlMap() {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const canvasLayerRef = useRef(null);

  const drawCanvas = (markers: any) => {
    if (!mapInstance?.current) {
      return;
    }

    const canvas = document.createElement('canvas');
    const size = mapInstance.current.getSize();
    canvas.width = size.x;
    canvas.height = size.y;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const bounds = mapInstance.current.getBounds();
    markers?.forEach((marker) => {
      if (bounds.contains([+marker.LATITUDE, +marker.LONGITUDE])) {
        drawMarker(marker);
      }
    });

    if (canvasLayerRef.current) {
      mapInstance.current.removeLayer(canvasLayerRef.current);
    }

    const boundsOverlay = mapInstance.current.getBounds();
    canvasLayerRef.current = L.imageOverlay(canvas.toDataURL(), boundsOverlay);
    canvasLayerRef.current.addTo(mapInstance.current);
  };

  const drawMarker = (marker: any) => {
    if (!mapInstance.current) return;

    const { LATITUDE, LONGITUDE, ICONE } = marker;

    const customDivIcon = L.divIcon({
      html: `<img src="${ICONE}" style="width: 30px; height: 40px;" />`,
      className: 'custom-marker-icon',
      iconSize: [40, 40],
      iconAnchor: [20, 40],
    });

    const leafletMarker = L.marker([+LATITUDE, +LONGITUDE], { icon: customDivIcon });
    leafletMarker.addTo(mapInstance.current);

    leafletMarker.on('click', () => {
      L.popup({
        offset: L.point(-5, -20),
        closeButton: true,
        autoClose: false,
        closeOnClick: true,
        className: 'custom-popup-visit-control',
      })
        .setLatLng([+LATITUDE, +LONGITUDE])
        .setContent(`<div>${marker.POPUP}</div>`)
        .openOn(mapInstance.current);
    });
  };

  const ControlMapContainer = ({ markers }: { markers: any }) => {
    useEffect(() => {
      if (!mapRef.current) {
        return;
      }

      mapInstance.current = L.map(mapRef.current, {
        zoomControl: false,
      }).setView(markers && markers.length > 0 ? [markers[0].LATITUDE, markers[0].LONGITUDE] : [-19.9286, -43.9409], 8);

      const Voyager = L.tileLayer('https://tiles.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}@2x.png', {
        attribution: 'Tiles &copy; Esri',
        minZoom: 3,
      });

      const OpenStreetMap = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors',
        minZoom: 3,
      });

      const CartoDbDark = L.tileLayer(
        'https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/dark_all/{z}/{x}/{y}@2x.png',
        {
          attribution: 'Tiles &copy; Esri',
          minZoom: 3,
        },
      );

      const CartoDbLight = L.tileLayer(
        'https://cartodb-basemaps-a.global.ssl.fastly.net/rastertiles/light_all/{z}/{x}/{y}@2x.png',
        {
          attribution: 'Tiles &copy; Esri',
          minZoom: 3,
        },
      );

      const layers = {
        Voyager,
        OpenStreetMap,
        CartoDbDark,
        CartoDbLight,
      };

      const savedLayer = localStorage.getItem('selectedLayer');
      const defaultLayer = 'Voyager';

      const initialLayer = layers[savedLayer] ? savedLayer : defaultLayer;
      layers[initialLayer].addTo(mapInstance.current);

      const layerControl = L.control.layers(layers).addTo(mapInstance.current);

      mapInstance.current.on('baselayerchange', (e) => {
        localStorage.setItem('selectedLayer', e.name);
      });

      L.control.zoom({ position: 'bottomleft' }).addTo(mapInstance.current);

      const redrawCanvas = () => drawCanvas(markers);

      const handleZoomLevel = () => {
        redrawCanvas();
      };

      mapInstance.current.on('moveend', redrawCanvas);
      mapInstance.current.on('resize', redrawCanvas);
      mapInstance.current.on('zoomend', handleZoomLevel);

      drawCanvas(markers);

      return () => {
        mapInstance.current.off('moveend', redrawCanvas);
        mapInstance.current.off('resize', redrawCanvas);
        mapInstance.current.off('click');
        mapInstance.current.remove();
        mapInstance.current.off('zoomend', handleZoomLevel);
      };
    }, [markers]);

    return (
      <S.MapContainerBox>
        <div ref={mapRef} style={{ height: '100vh', width: '100%' }} />
      </S.MapContainerBox>
    );
  };

  return {
    mapRef,
    drawCanvas,
    ControlMapContainer,
  };
}
