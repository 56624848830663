import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRespostas } from 'client/hooks';
import { RespostaCab } from 'client/interfaces';
import { BlockTableTemplate, filterByGlobalFilter } from 'components/datatable';
import InputData from 'components/InputData';
import { formatDateTime } from 'utils/datetime';

import * as S from './styles';
import useMediaQuery from 'hooks/useMediaQuery';
import { BlockUI } from 'primereact/blockui';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { Controller, useForm } from 'react-hook-form';
import { FilterByUsersCompany } from 'components/FilterByUsersCompany';
import { FilterByCompany } from 'components/FilterByCompany';
import { FilterByFilialCompany } from 'components/FilterByFillialCompany';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';

const FormularioRespostaTable = () => {
  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
  const isWebScreen = useMediaQuery('(min-width: 1024px)');
  const user = useAppSelector(userSelector);

  const endDate = new Date();
  const startDate = new Date();
  startDate.setMonth(endDate.getMonth() - 1);

  const { control, watch, setValue } = useForm({
    defaultValues: {
      codCompany: user?.codEmpresa,
      codUserByCompany: user?.codUsuario,
      codFilial: '',
      endDate: new Date(),
      startDate: startDate,
      filterGlobal: '',
    },
  });

  const values = watch();

  const navigate = useNavigate();

  // useEffect(() => {
  //   setValue(`codCompany`, selectedEmpresa.codEmpresa);
  // }, [selectedEmpresa]);

  const {
    data: respostas,
    isLoading,
    isFetching,
    refetch,
  } = useRespostas(values.startDate, values.endDate, values.codCompany);

  useEffect(() => {
    refetch();
  }, [values.codCompany]);

  useEffect(() => {
    setValue('codCompany', user?.codEmpresa);
    setValue('codUserByCompany', user?.codUsuario);
  }, []);

  const header = () => (
    <S.Container>
      {user?.codEmpresa === 2072021 && (
        <S.BoxInput>
          <label>Empresa</label>
          <FilterByCompany control={control} setValue={setValue} />
        </S.BoxInput>
      )}

      {/* <SelectEmpresa /> */}

      {user?.codEmpresa === 2072021 && (
        <S.BoxInput>
          <label>Usuário</label>
          <FilterByUsersCompany control={control} setValue={setValue} codCompany={values.codCompany} />
        </S.BoxInput>
      )}

      <S.BoxInput>
        <label>Filial</label>
        <FilterByFilialCompany
          control={control}
          setValue={setValue}
          codCompany={values.codCompany}
          codUser={values.codUserByCompany}
        />
      </S.BoxInput>

      <S.BoxInput>
        <label>Data Inicial</label>
        <Controller
          name="startDate"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => (
            <InputData dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" {...field} />
          )}
        />
      </S.BoxInput>

      <S.BoxInput>
        <label>Data Final</label>

        <Controller
          name="endDate"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => (
            <InputData dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy" mask="99/99/9999" {...field} />
          )}
        />
      </S.BoxInput>

      <S.BoxTextInput>
        <label>Buscar</label>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <Controller
            name="filterGlobal"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => (
              <InputText
                type="search"
                value={field.value}
                onChange={(e) => setValue('filterGlobal', e.target.value)}
                placeholder="Buscar..."
              />
            )}
          />
        </span>
      </S.BoxTextInput>
    </S.Container>
  );

  const viewresposta = (rowData: RespostaCab) => {
    navigate(`${rowData.nroResposta}`);
  };

  const respFilterCompany = respostas?.filter((resp) => resp.codEmpresa === Number(values.codCompany));

  const filterByFilial = respFilterCompany?.filter((formulario) => {
    return String(values.codFilial) === String(formulario.codfilial);
  });

  const filteredValue = useMemo(() => {
    let filteredData = filterByFilial ?? []; // Se houver filial selecionada, use o filtro de filial, senão use a lista completa
    filteredData = filterByGlobalFilter(
      filteredData.map((p, index) => ({ ...p, id: index })) ?? [],
      values.filterGlobal,
    );
    return filteredData;
  }, [respFilterCompany, values.filterGlobal, values.codCompany, values.codFilial]);

  useEffect(() => {
    setValue('codFilial', '');
  }, [values.codUserByCompany]);

  return (
    <div style={{ padding: isWebScreen && '10px' }}>
      {header()}
      <BlockUI
        containerStyle={{ zIndex: 0 }}
        blocked={!values.codCompany || !values.codFilial || values.codFilial === '' || values.codFilial === undefined}
        template={BlockTableTemplate('Selecione uma Filial')}
      >
        <DataTable
          value={filteredValue}
          dataKey="nroResposta"
          loading={isLoading || isFetching}
          paginator
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          rowsPerPageOptions={[10, 25, 50, 100, 200, 300, 500]}
          rows={50}
          emptyMessage="Nenhum dado encontrado"
          currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
        >
          <Column header="Formulário" field="form" />
          <Column header="Cliente" field="codcli" />
          <Column header="Filial" field="codfilial" />
          <Column header="CodSupervisor" field="codrca" />
          <Column
            header="Vendedor"
            field="codcli"
            body={(rowData: RespostaCab) =>
              `${rowData.vendedor ?? ''}  ${rowData.codrca ? ` [${rowData.codrca}]` : ''}`
            }
          />
          <Column
            header="Data Resposta"
            field="dtresposta"
            body={(rowData: RespostaCab) => formatDateTime('dd/MM/yyyy hh:mm:ss', new Date(rowData.dtresposta))}
          />
          <Column
            body={(rowData: RespostaCab) => (
              <Button
                onClick={() => viewresposta(rowData)}
                className="p-button-rounded p-button-text"
                icon="pi pi-eye"
                tooltip="Visualizar"
                tooltipOptions={{
                  position: 'top',
                }}
              />
            )}
          />
        </DataTable>
      </BlockUI>
    </div>
  );
};

export default FormularioRespostaTable;
