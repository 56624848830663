import styled from 'styled-components';

export const SelectColorMainBox = styled.section`
  width: 100%;
  background-color: #00000010;

  .p-dropdown {
    width: 100%;
  }
`;

export const ColorItemTemplate = styled.div``;

export const ColorItemValueTemplate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface TemplateTitleProps {
  color: string;
}
export const ColorItemTemplateTitle = styled.div<TemplateTitleProps>`
  font-weight: 650;
  color: ${(props) => `#${props.color}`};
`;

export const ColorItemTemplatePalette = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 2px;
`;

interface PaletteItemProps {
  color: string;
  active?: boolean;
}

export const ColorItemTemplatePaletteItem = styled.div<PaletteItemProps>`
  border: 1px solid #60606020;
  width: 10%;
  height: 15px;
  padding: 2px;
  background-color: ${(props) => `#${props.color}`};
  transition: width 0.4s ease, transform 0.1s ease, border 0.1s ease, margin-left 0.4s ease;

  border: 2px solid ${(props) => `#${props.color}`};

  ${(props) =>
    props.active &&
    `
    border: 2px solid #fff;
    width: calc(12%);
    margin-left: -2.5%;
    margin-top: -1px;
    transform: scaleY(1.2);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    `}

  &:hover {
    border: 2px solid #fff;
    width: calc(15%);
    margin-left: -2.5%;
    margin-top: -1px;
    transform: scaleY(1.5);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
`;
