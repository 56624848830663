import styled, { css } from 'styled-components';

export const ContainerPage = styled.div`
  padding: 10px 0px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;

  table {
    max-height: 50dvh;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: red;
`;

export const ItemRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  padding: 10px 0px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Form = styled.form``;

interface InputProps {
  error?: boolean;
}

export const DescriptionInput = styled.small<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const TitleInput = styled.label<InputProps>`
  ${(props) =>
    props.error &&
    css`
      color: red;
    `}
`;

export const ClientsListHeader = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 15px;
  flex-wrap: wrap;
  padding: 10px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
