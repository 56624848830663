import { Dialog } from 'primereact/dialog';
import * as S from './mapNewSetor.styles';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { SetStateAction, useEffect, useState } from 'react';
import { Button } from 'reactivus';
import { BsCheck, BsX } from 'react-icons/bs';
import { toast } from 'react-toastify';
import { registerSetor } from 'client/api';
import { useMap } from '../../regioes.context';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useVendedoresByUser } from 'client/hooks/vendedores';
import { useSupervisorByRole } from 'client/hooks/supervisor';
import UseSelectColor from 'hooks/UseSelectColor/selectColor.index';
import { InputSwitch } from 'primereact/inputswitch';

export default function MapNewSetor({
  showMapNewSetor,
  setShowMapNewSetor,
}: {
  showMapNewSetor: boolean;
  setShowMapNewSetor: React.Dispatch<SetStateAction<boolean>>;
}) {
  const { handleGetAllData, colorsList, params } = useMap();

  const { SelectColor } = UseSelectColor();

  const { data: vendedores, refetch: refetchVendedores } = useVendedoresByUser(
    params.codEmpresa,
    String(params.codUser),
  );

  const {
    data: supervisores,
    isLoading,
    refetch: refetchSupervisor,
  } = useSupervisorByRole(params.codEmpresa, String(params.codUser));

  const defaultNewSetorData = {
    codSetor: 0,
    setor: '',
    codRca: 0,
    nomeRca: '',
    ativo: true,
    codFilial: 1,
    codGerente: 0,
    nomeGerente: '',
    codMapa: 0,
    cor: '000000',
    CodEmpresa: 0,
    atualizaAutomatico: 'S',
  };

  const [newSetorData, setNewSetorData] = useState(defaultNewSetorData);

  useEffect(() => {
    setNewSetorData((prev: any) => {
      return {
        ...prev,
        codMapa: params.codMapa,
        CodEmpresa: params.codEmpresa,
      };
    });
  }, [params]);

  const DialogFooterCadastrarSetor = (
    <S.BoxButtons>
      <Button icon={<BsCheck />} label="Salvar" iconPosition="left" color="success" onClick={() => handleSaveSetor()} />
      <Button
        icon={<BsX />}
        label="Fechar"
        iconPosition="left"
        color="danger"
        onClick={() => setShowMapNewSetor(false)}
      />
    </S.BoxButtons>
  );

  const corTemplate = (rowData: any) => {
    return (
      <S.CorOption cor={rowData.cor}>
        <S.Cor cor={rowData.cor} />
        {rowData.nome}
      </S.CorOption>
    );
  };

  const supervisorOptionTemplate = (supervisor: any) => {
    return (
      <S.SelectOption>
        [{supervisor.codSupervisor}] {supervisor.nomesup}
        <S.SelectOptionCloseButton
          show={newSetorData && newSetorData.codGerente == supervisor.codSupervisor}
          onClick={() => {
            setNewSetorData((prev: any) => {
              return {
                ...prev,
                codGerente: 0,
                nomeGerente: '',
              };
            });
          }}
        >
          <AiOutlineCloseCircle />
        </S.SelectOptionCloseButton>
      </S.SelectOption>
    );
  };

  const vendedorOptionTemplate = (vendedor: any) => {
    return (
      <S.SelectOption>
        [{vendedor.codVendedor}] {vendedor.nome}
        <S.SelectOptionCloseButton
          show={newSetorData && newSetorData.codRca == vendedor.codVendedor}
          onClick={() => {
            setNewSetorData((prev: any) => {
              return {
                ...prev,
                codRca: 0,
                nomeRca: '',
              };
            });
          }}
        >
          <AiOutlineCloseCircle />
        </S.SelectOptionCloseButton>
      </S.SelectOption>
    );
  };

  const handleSaveSetor = () => {
    if (newSetorData.setor === '') {
      toast.error('Nome do setor não informado!');
      return;
    } else if (newSetorData.cor == '') {
      toast.error('Cor não informada!');
      return;
    }

    console.log('newSetorData :', newSetorData);
    registerSetor(newSetorData)
      .then((res) => {
        toast.success('Setor criado com sucesso!');
        handleGetAllData();
      })
      .catch((err) => {
        toast.error('Não foi possível criar setor!');
      });
    setShowMapNewSetor(false);
    setNewSetorData(defaultNewSetorData);
  };

  return (
    <Dialog
      visible={showMapNewSetor}
      style={{ width: '32rem' }}
      breakpoints={{ '960px': '75vw', '641px': '90vw' }}
      header={'Cadastro de Setor'}
      modal
      className="p-fluid"
      onHide={() => setShowMapNewSetor(false)}
      footer={DialogFooterCadastrarSetor}
      draggable={false}
    >
      <S.NovoSetorForm>
        <S.Box className="field">
          Nome do Setor*
          <InputText
            id="name"
            placeholder="Setor"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  setor: e.target.value,
                };
              });
            }}
          />
        </S.Box>

        <S.Box className="field">
          Supervisor
          <Dropdown
            options={supervisores}
            optionLabel="nomesup"
            placeholder={newSetorData.nomeGerente || 'Selecione um supervisor'}
            value={newSetorData.codGerente}
            filter
            itemTemplate={supervisorOptionTemplate}
            className="w-full md:w-14rem"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  codGerente: e.target.value.codSupervisor,
                  nomeGerente: e.target.value.nomesup,
                };
              });
            }}
          />
        </S.Box>

        <S.Box className="field">
          Vendedor
          <Dropdown
            options={
              newSetorData.codGerente
                ? vendedores.filter((vend: any) => vend.codSupervisor == newSetorData.codGerente)
                : vendedores
            }
            optionLabel="nome"
            placeholder={newSetorData.nomeRca || 'Selecione um vendedor'}
            value={newSetorData.codRca}
            filter
            itemTemplate={vendedorOptionTemplate}
            className="w-full md:w-14rem"
            onChange={(e) => {
              console.log('e :', e.value);
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  codRca: e.value.codVendedor,
                  nomeRca: e.value.nome,
                };
              });
            }}
          />
        </S.Box>

        <S.Box className="field">
          Roteiriza Automático
          <Dropdown
            options={[
              { option: 'S', checked: true, label: 'SIM' },
              { option: 'N', label: 'NÃO' },
            ]}
            optionLabel="label"
            defaultChecked={true}
            placeholder={newSetorData.atualizaAutomatico == 'S' ? 'SIM' : 'NÃO'}
            filter
            className="w-full md:w-14rem"
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  atualizaAutomatico: e.target.value.option,
                };
              });
            }}
          />
        </S.Box>

        <S.Box className="field">
          Cor
          <SelectColor
            onChange={(color) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  cor: color.COR,
                };
              });
            }}
          />
        </S.Box>
        
        <S.Box className="field">
          Ativo/Inativo:
          <InputSwitch
            checked={newSetorData.ativo}
            onChange={(e) => {
              setNewSetorData((prev: any) => {
                return {
                  ...prev,
                  ativo: e.value,
                };
              });
            }}
          />
        </S.Box>
      </S.NovoSetorForm>
    </Dialog>
  );
}
