import { useEffect, useState } from 'react';
import * as S from './styles';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import { BsFillPinFill, BsHandThumbsUp } from 'react-icons/bs';
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from 'react-icons/md';
import { useAppSelector } from 'store';
import { userSelector } from 'modules/auth/reducer/auth';
import { IStatusVisitControl } from 'client/interfaces/roteirizador.interfaces';
import { Control, UseFormSetValue } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectSupervisor from 'hooks/UseSelectSupervisor/selectSupervisor';
import UseSelectVendedor from 'hooks/UseSelectVendedor/selectVendedor';

interface IControlSideBarParams {
  dataStatus: IStatusVisitControl[];
  values: {
    codCompany: number;
    codUserByCompany: number;
    codSupervisor: string;
    codSeller: string;
    filterGlobal: string;
  };
  setValue: UseFormSetValue<{
    codCompany: number;
    codUserByCompany: number;
    codSupervisor: string;
    codSeller: string;
    filterGlobal: string;
  }>;
  control: Control<
    {
      codCompany: number;
      codUserByCompany: number;
      codSupervisor: string;
      codSeller: string;
      filterGlobal: string;
    },
    any
  >;
}

export default function ControlSideBar({ dataStatus, values, setValue, control }: IControlSideBarParams) {
  const user = useAppSelector(userSelector);

  const location = useLocation();
  const currentPath = location.pathname;

  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
  const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);
  const { SelectSupervisor, selectedSupervisor } = UseSelectSupervisor(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
  );
  const { SelectVendedor } = UseSelectVendedor(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
    selectedSupervisor.codSupervisor,
  );

  const [isMobileView, setIsMobileView] = useState(currentPath == '/controle-visitas-mobile');

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showFilterOptionsBox, setShowFilterOptionsBox] = useState(currentPath == '/controle-visitas-mobile');

  const icones = [<AiOutlineCheck />, <BsHandThumbsUp />, <BsFillPinFill />, <AiOutlineClose />];

  useEffect(() => {
    if (user?.codEmpresa == 2072021) {
      setShowFilterOptionsBox(true);
    }
  }, [user]);

  useEffect(() => {
    setValue('codCompany', selectedEmpresa.codEmpresa);
    setValue('codUserByCompany', selectedUsuario.codUsuario);
    setValue('codSupervisor', '0');
    setValue('codSeller', '0');
    setMobileFilter({
      codSupervisor: '0',
      codSeller: '0',
    });
  }, [selectedEmpresa, selectedUsuario]);

  const [mobileFilter, setMobileFilter] = useState({
    codSupervisor: '0',
    codSeller: '0',
  });

  const handleMobileFilter = () => {
    setValue('codSupervisor', mobileFilter.codSupervisor);
    setValue('codSeller', mobileFilter.codSeller);
  };

  return (
    <S.MapSideBarMainBox showMenu={showMenu} isMobile={isMobileView}>
      {!showMenu && <S.SideBarHeaderClosed>CONTROLE DE VISITAS</S.SideBarHeaderClosed>}

      <S.MapSideBarOpenBtn showMenu={showMenu} isMobile={isMobileView} onClick={(e) => setShowMenu(!showMenu)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http:// www.w3.org/2000/svg"
          className="mh-50px"
          color="#7bcda0"
        >
          <path
            opacity="0.5"
            d="M9.63433 11.4343L5.45001 7.25C5.0358 6.83579 5.0358 6.16421 5.45001 5.75C5.86423 5.33579 6.5358 5.33579 
            6.95001 5.75L12.4929 11.2929C12.8834 11.6834 12.8834 12.3166 12.4929 12.7071L6.95001 18.25C6.5358 18.6642 5.86423 18.6642 
            5.45001 18.25C5.0358 17.8358 5.0358 17.1642 5.45001 16.75L9.63433 12.5657C9.94675 12.2533 9.94675 11.7467 9.63433 11.4343Z"
            fill="currentColor"
          />
          <path
            d="M15.6343 11.4343L11.45 7.25C11.0358 6.83579 11.0358 6.16421 11.45 5.75C11.8642 5.33579 
             12.5358 5.33579 12.95 5.75L18.4929 11.2929C18.8834 11.6834 18.8834 12.3166 18.4929 12.7071L12.95 18.25C12.5358 18.6642 
             11.8642 18.6642 11.45 18.25C11.0358 17.8358 11.0358 17.1642 11.45 16.75L15.6343 12.5657C15.9467 12.2533 15.9467 11.7467 
             15.6343 11.4343Z"
            fill="currentColor"
          />
        </svg>
      </S.MapSideBarOpenBtn>

      <S.Content showMenu={showMenu} isMobile={isMobileView}>
        <S.SideBarHeaderBox>
          <S.SideBarHeaderFilterBox isMobile={isMobileView}>
            <div className={'filterTitle'}>
              <span>FILTROS</span>
            </div>
            {!isMobileView && (
              <div className={'filterStatus'} onClick={() => setShowFilterOptionsBox(!showFilterOptionsBox)}>
                {showFilterOptionsBox ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
              </div>
            )}

            {showFilterOptionsBox && (
              <div className={showFilterOptionsBox ? 'filterOptions filterOptionsOpen' : 'filterOptions'}>
                <SelectEmpresa
                  label="Empresa *"
                  width="100%"
                  onChange={(e) => {
                    setValue('codCompany', e);
                  }}
                />
                <SelectUsuario
                  label="Usuário *"
                  width="100%"
                  onChange={(e) => {
                    setValue('codUserByCompany', e);
                  }}
                />
                <SelectSupervisor
                  label="Supervisor"
                  width="100%"
                  onChange={(e) => {
                    if (isMobileView) {
                      setMobileFilter((prev: any) => {
                        return {
                          ...prev,
                          codSupervisor: e,
                        };
                      });
                    } else {
                      setValue('codSupervisor', e.toString());
                    }
                  }}
                />
                <SelectVendedor
                  label="Vendedor"
                  width="100%"
                  onChange={(e) => {
                    if (isMobileView) {
                      setMobileFilter((prev: any) => {
                        return {
                          ...prev,
                          codSeller: e,
                        };
                      });
                    } else {
                      setValue('codSeller', e.toString());
                    }
                  }}
                />

                {isMobileView && (
                  <div className={'filterOptionButton'}>
                    <button
                      onClick={() => {
                        setShowMenu(false);
                        handleMobileFilter();
                      }}
                    >
                      Filtrar
                    </button>
                  </div>
                )}
              </div>
            )}
          </S.SideBarHeaderFilterBox>
        </S.SideBarHeaderBox>

        {!isMobileView &&
          dataStatus?.map((status: IStatusVisitControl) => {
            return (
              <S.SideBarStatusCard>
                {icones[status.CARD - 1]}
                <S.SideBarStatusCardName>{status.TITULO}</S.SideBarStatusCardName>
                <S.SideBarStatusCardCount>{status.TXT_TOTAL}</S.SideBarStatusCardCount>
                <S.SideBarStatusTagsBox>
                  <S.SideBarStatusTag type={'success'}>{status.TXT_Q1}</S.SideBarStatusTag>
                  <S.SideBarStatusTag type={'warning'}>{status.TXT_Q2}</S.SideBarStatusTag>
                  <S.SideBarStatusTag type={'danger'}>{status.TXT_Q3}</S.SideBarStatusTag>
                </S.SideBarStatusTagsBox>
              </S.SideBarStatusCard>
            );
          })}
      </S.Content>
    </S.MapSideBarMainBox>
  );
}
