import styled from 'styled-components';

type containerProps = {
  fullSize?: boolean;
};

export const ContentMainBox = styled.section<containerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 92vh;
  height: ${(props) => (props.fullSize ? '100vh' : '92vh')};
  width: 100%;
  position: relative;
  padding-bottom: 10px;
  overflow-y: scroll;
  padding: 0 10px 10px 10px;
  padding: ${(props) => (props.fullSize ? '0 0px 0px 0px' : '0 10px 10px 10px')};

  @media only screen and (max-width: 1024px) {
    height: ${(props) => (props.fullSize ? '100vh' : '78vh')};
    overflow: scroll;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding: 0;
    position: absolute;
    top: ${(props) => (props.fullSize ? '0' : '55px')};
  }
`;
