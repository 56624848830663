import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import storage from 'services/storage';
import { useAppDispatch, useAppSelector } from 'store';
import { validateCpf, validatePhone } from 'utils/input';

import {
  cpfCodErpSelector,
  missingPropertiesSelector,
  nomeUsuarioSelector,
  registerUser,
  setEmpresaCNPJ,
  setUserCpf,
} from '../reducer/auth';
import * as S from './styles';
import styles from './styles.module.scss';
import useMediaQuery from 'hooks/useMediaQuery';

interface FormState {
  password: string;
  confirmPassword: string;
  cpf: string | undefined;
  celular: string | undefined;
  email: string | undefined;
  dtNascimento: Date | undefined;
  nome: string | undefined;
}

function RegisterForm() {
  const [isLoading, setIsLoading] = useState(false);
  const password = useRef({});
  const cpfCodErp = useAppSelector(cpfCodErpSelector);
  const missingProperties = useAppSelector(missingPropertiesSelector);
  const nomeUser = useAppSelector(nomeUsuarioSelector);

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [fixedButton, setFixedButton] = useState(false);
  const isAboveMediumScreens = useMediaQuery('(min-width: 1060px)');

  const {
    handleSubmit,
    register,
    watch,
    control,
    formState: { errors, dirtyFields },
  } = useForm<FormState>({
    defaultValues: {
      nome: nomeUser,
    },
  });
  const dispatch = useAppDispatch();

  password.current = watch('password', '');

  const onSubmit: SubmitHandler<FormState> = async (form) => {
    try {
      setIsLoading(true);
      await dispatch(
        registerUser({
          ...form,
          cpfCodErp: cpfCodErp ?? '',
        }),
      ).unwrap();
      toast.success('Usuário registrado com sucesso! Faça login para continuar.');
    } catch (e) {
      toast.error('Erro ao registrar usuário. Por favor entre em contato com o administrador!');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    dispatch(setEmpresaCNPJ(null));
    dispatch(setUserCpf(null));
    storage.clearStorage();
  };

  const renderHeader = () => {
    if (missingProperties.length === 1 && missingProperties.includes('SENHA'))
      return (
        <div className="text-muted fw-semibold fs-6 mb-5">Por favor, crie uma senha para concluir seu cadastro.</div>
      );

    return (
      <>
        <div className="text-muted fw-semibold fs-6 mb-5">
          Para continuar, precisamos de algumas informações para concluir o seu cadastro.
        </div>
        <div className="text-muted fw-semibold fs-6 mb-5">
          Por favor, preencha os dados abaixo{missingProperties.includes('SENHA') && ' e crie uma senha'}!
        </div>
      </>
    );
  };

  useEffect(() => {
    handleParamsValidation();
  }, []);

  const handleParamsValidation = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const origin = urlParams.get('origin');
    origin && origin == 'fv' && !isAboveMediumScreens && setFixedButton(true);
  };

  const RegisterButton = () => {
    return (
      <button type="submit" className={`${styles.button} btn btn-lg w-100 mb-5`} disabled={isLoading}>
        {!isLoading && <span className="indicator-label">Cadastrar</span>}
        {isLoading && (
          <span className="indicator-progress" style={{ display: 'block' }}>
            Validando...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        )}
      </button>
    );
  };

  return (
    <S.Container style={{ overflowY: 'scroll', height: 'clamp(50dvh, 70dvh, 120dvh)' }}>
      {renderHeader()}

      <form onSubmit={handleSubmit(onSubmit)} className="form w-100" noValidate>
        <div className="fv-row mb-10">
          <label className="form-label fs-6 fw-bolder text-dark">NOME</label>
          <input
            {...register('nome', {
              required: 'Obrigatório',
            })}
            placeholder="digite nome completo"
            className={clsx('form-control form-control-lg form-control-solid', {
              'is-invalid': (dirtyFields.nome ?? false) && errors.nome,
            })}
            // disabled={nomeUser !== ''}
            name="nome"
          />

          {errors.nome != null && (
            <div className="fv-plugins-message-container invalid-feedback">
              <span role="alert">{errors.nome.message}</span>
            </div>
          )}
        </div>

        {missingProperties.includes('CPF') && (
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">CPF</label>
            <Controller
              name="cpf"
              control={control}
              rules={{
                required: 'Obrigatório',
                validate: (value) => validateCpf(value ?? '') || 'CPF inválido!',
              }}
              render={({ field }) => (
                <InputMask
                  {...field}
                  placeholder="XXX.XXX.XXX-XX"
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': (dirtyFields.cpf ?? false) && errors.cpf,
                  })}
                  mask="999.999.999-99"
                  type="cpf"
                  name="cpf"
                />
              )}
            />

            {errors.cpf != null && (
              <div className="fv-plugins-message-container invalid-feedback">
                <span role="alert">{errors.cpf.message}</span>
              </div>
            )}
          </div>
        )}

        {missingProperties.includes('CELULAR') && (
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">Celular</label>
            <Controller
              name="celular"
              control={control}
              rules={{
                required: 'Obrigatório',
                validate: (value) => validatePhone(value ?? '') || 'Telefone inválido!',
              }}
              render={({ field }) => (
                <InputMask
                  {...field}
                  placeholder="(99) 9 9999-9999"
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': (dirtyFields.celular ?? false) && errors.celular,
                  })}
                  mask="(99) 9 9999-9999"
                  type="phone"
                  name="phone"
                />
              )}
            />

            {errors.celular != null && (
              <div className="fv-plugins-message-container invalid-feedback">
                <span role="alert">{errors.celular.message}</span>
              </div>
            )}
          </div>
        )}

        {missingProperties.includes('EMAIL') && (
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">Email</label>
            <input
              {...register('email', {
                required: 'Obrigatório',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Email inválido!',
                },
              })}
              placeholder="digite seu email"
              className={clsx('form-control form-control-lg form-control-solid', {
                'is-invalid': (dirtyFields.email ?? false) && errors.email,
              })}
              type="email"
              name="email"
            />
            {errors.email != null && (
              <div className="fv-plugins-message-container invalid-feedback">
                <span role="alert">{errors.email.message}</span>
              </div>
            )}
          </div>
        )}

        {missingProperties.includes('BIRTH_DATE') && (
          <div className="fv-row mb-10">
            <label className="form-label fs-6 fw-bolder text-dark">Data de Nascimento</label>

            <input
              {...register('dtNascimento', {
                required: 'Obrigatório',
              })}
              type="date"
              placeholder="crie uma senha"
              className={clsx('form-control form-control-lg form-control-solid', {
                'is-invalid': (dirtyFields.dtNascimento ?? false) && errors.dtNascimento,
              })}
              name="dtNascimento"
            />

            {errors.dtNascimento != null && (
              <div className="fv-plugins-message-container invalid-feedback">
                <span role="alert">{errors.dtNascimento.message}</span>
              </div>
            )}
          </div>
        )}

        {missingProperties.includes('SENHA') && (
          <>
            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">Senha</label>
              <div className="input-group mb-5">
                <input
                  {...register('password', {
                    required: 'Obrigatório',
                    minLength: {
                      value: 8,
                      message: 'Senha deve ter ao menos 8 caracteres',
                    },
                  })}
                  type={showPassword ? 'text' : 'password'}
                  placeholder="crie uma senha"
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': (dirtyFields.password ?? false) && errors.password,
                  })}
                  name="password"
                />
                <a onClick={() => setShowPassword(!showPassword)} className="btn btn-light">
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </a>
              </div>

              {errors.password != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.password.message}</span>
                </div>
              )}
            </div>

            <div className="fv-row mb-10">
              <label className="form-label fs-6 fw-bolder text-dark">Confirme a senha</label>
              <div className="input-group mb-5">
                <input
                  {...register('confirmPassword', {
                    validate: (value: string) => value === password.current || 'As senhas não combinam',
                  })}
                  placeholder="confirme a senha"
                  className={clsx('form-control form-control-lg form-control-solid', {
                    'is-invalid': (dirtyFields.confirmPassword ?? false) && errors.confirmPassword,
                  })}
                  type={showNewPassword ? 'text' : 'password'}
                  name="confirmPassword"
                />
                <a onClick={() => setShowNewPassword(!showNewPassword)} className="btn btn-light">
                  {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                </a>
              </div>

              {errors.confirmPassword != null && (
                <div className="fv-plugins-message-container invalid-feedback">
                  <span role="alert">{errors.confirmPassword.message}</span>
                </div>
              )}
            </div>
          </>
        )}

        {!fixedButton || isAboveMediumScreens ? (
          <div className="text-center">
            <RegisterButton />
            <button
              type="button"
              onClick={handleCancel}
              className="btn btn-lg btn-light-danger w-100 mb-5"
              disabled={isLoading}
            >
              Cancelar
            </button>
          </div>
        ) : (
          !isAboveMediumScreens && (
            <S.RegisterButtonFixed>
              <RegisterButton />
            </S.RegisterButtonFixed>
          )
        )}
      </form>
    </S.Container>
  );
}

export default RegisterForm;
