import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getByRoleManagersCompany } from 'client/api';

type SelectedGerenteProps = {
  codGerente?: number;
  codEmpresa?: number;
  nomeGerente?: string;
  label?: string;
};

const UseSelectGerente = (codEmpresa: number, codUsuario: number) => {
  const { data: user } = useUser();
  const [gerentes, setGerentes] = useState<any[]>([]);
  const [selectedGerente, setSelectedGerente] = useState<SelectedGerenteProps>(() => {
    const ger = JSON.parse(localStorage.getItem('vmGerData') || 'null');
    return (
      ger || {
        codGerente: ger ? ger.codGerente : -1,
        nomeGerente: '',
      }
    );
  });

  useEffect(() => {
    localStorage.removeItem('vmGerData');
    if (user && codEmpresa && codUsuario) {
      getByRoleManagersCompany(codEmpresa, codUsuario).then((rcas: any[]) => {
        const formattedRcas = rcas.map((v) => ({
          ...v,
          label: `[${v.codGerente}] - ${v.nomeGerente}`,
        }));
        setGerentes(formattedRcas);
      });
    }
  }, [user, codEmpresa, codEmpresa, codUsuario]);

  const handleGerenteChange = (emp: SelectedGerenteProps) => {
    setSelectedGerente(emp);
    localStorage.setItem('vmGerData', JSON.stringify(emp));
  };

  const SelectGerente = ({
    onChange,
    width,
    label = 'Gerente',
    placeholder = 'Selecione um Gerente',
  }: {
    onChange?: (codUsuario: number) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {
    const widthCalculated = +(`[${selectedGerente.codGerente}] - ${selectedGerente?.nomeGerente}`.length * 8);

    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: clamp(100px, ${width ?? `${widthCalculated}px`}, ${width ?? '175px'});
    `;

    const handleChange = (emp: SelectedGerenteProps) => {
      handleGerenteChange(emp);
      if (onChange) {
        onChange(emp.codGerente);
      }
    };

    return (
      <InputBox>
        <label>{label}</label>
        <Dropdown
          value={selectedGerente}
          onChange={(e) => handleChange(e.value)}
          options={gerentes}
          optionLabel="label"
          filter
          placeholder={placeholder}
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
        />
      </InputBox>
    );
  };

  return {
    SelectGerente,
    selectedGerente,
    setSelectedGerente,
  };
};

export default UseSelectGerente;
