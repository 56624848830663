import { useQueryClient } from '@tanstack/react-query';
import { BlockUI } from 'primereact/blockui';
import { Column } from 'primereact/column';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { useEffect, useState } from 'react';
import { BsDashLg, BsPlusLg } from 'react-icons/bs';
import { alterAccessMenus, removeAcessoMenus, saveAcessoMenus } from 'client/api';
import { useMenuAcesso, useMenuWithSub, useUser } from 'client/hooks';
import { MenuWithSub } from 'client/interfaces';
import ButtonComponent from 'components/Button';
import ContainerPage from 'components/ContainerPage';
import { BlockTableTemplate, booleanEditor, styleHeaderCenter } from 'components/datatable';
import { useAppSelector } from 'store';
import { MenuAcesso } from '../../interfaces';
import { selectedEmpresaSelector, selectedUsuarioSelector, setSelectedUsuario } from '../../reducer/menuAcesso';
import SubItemMenu from '../SubItemMenu';
import * as S from './styles';
import AccessData from '../AccessData';
import { FiltersCompanyUser } from './FiltersCompanyUser';
import { TABS, useAccessDataStore } from 'storesZustand/accessData';
import { MenuAccessData } from './MenuAccessData';
import { ResetUserData } from '../ResetUserData';
import { UseByUserFiliais } from 'hooks/useFiliais/useByRoleFiliais';
import { toast } from 'react-toastify';
import { InputSwitch } from 'primereact/inputswitch';

const acessoTemplate = (data: MenuAcesso) =>
  data.acesso ? <Tag value="Sim" severity="success" /> : <Tag value="Não" severity="danger" />;

const UsuarioItensMenu = () => {
  const [expandedRows, setExpandedRows] = useState<any[] | DataTableExpandedRows | undefined>(undefined);
  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const tabActive = useAccessDataStore((state) => state.tabActive);

  const { data } = useUser();
  const {} = UseByUserFiliais({
    codEmpresa: data?.codEmpresa,
    codUser: data?.codUsuario,
  });

  const selectedEmpresa = useAppSelector(selectedEmpresaSelector);
  const selectedUsuario = useAppSelector(selectedUsuarioSelector);

  const queryClient = useQueryClient();

  const { data: menus, isFetching } = useMenuWithSub(selectedEmpresa?.codEmpresa ?? 0);

  const {
    data: acessos,
    isFetching: isLoadingAcessos,
    refetch: refetchAcesso,
  } = useMenuAcesso(selectedUsuario?.codUsuario ?? 0, selectedUsuario != null);

  const rowExpansionTemplate = (data: MenuWithSub) => <SubItemMenu menu={data} />;

  const menuUserAccessTemplate = (rowData: any) => {
    const [checked, setChecked] = useState(rowData && rowData.acesso != undefined && rowData.acesso == true ? true : false);
    useEffect(() => {
      if (rowData && rowData.acesso != undefined) {
        setChecked(rowData && rowData.acesso && rowData.acesso == true ? true : false);
      }
    }, [rowData]);

    const handleSwitchChange = (e) => {
      const newValue = e.value ? true : false;
      rowData.acesso = newValue;
      setIsLoadingSave(true);
      alterAccessMenus({
        codEmpresa: selectedUsuario.codEmpresa,
        codUsuario: selectedUsuario.codUsuario,
        codMenu: rowData.codMenu,
      })
        .then((res) => {
          setChecked(newValue);
          refetchAcesso();
          queryClient.invalidateQueries({
            queryKey: ['sub-menu-acesso'],
          });
        })
        .catch((err) => {
          rowData.acesso = newValue == true ? false : true;
          toast.error('Falha inesperada ao editar acesso');
          console.log('err :', err);
        })
        .finally(() => {
          setIsLoadingSave(false);
        });
    };
    return <InputSwitch checked={checked} onChange={handleSwitchChange} />;
  };

  const onCellEditComplete = async (e: any) => {
    const { rowData }: { rowData: MenuAcesso; newValue: boolean } = e;

    setIsLoadingSave(true);
    await alterAccessMenus({
      codEmpresa: selectedUsuario.codEmpresa,
      codUsuario: selectedUsuario.codUsuario,
      codMenu: rowData.codMenu,
    }).finally(() => {
      refetchAcesso();
      queryClient.invalidateQueries({
        queryKey: ['sub-menu-acesso'],
      });
      setIsLoadingSave(false);
    });
  };

  const expandAll = () => {
    const _expandedRows: DataTableExpandedRows = {};
    menus?.forEach((p) => (_expandedRows[`${p.codMenu}`] = true));

    setExpandedRows(_expandedRows);
  };

  const collapseAll = () => {
    setExpandedRows(undefined);
  };

  const resultadoComAcesso = menus?.map((item) => {
    const acesso = acessos?.includes(item.codMenu);
    return {
      ...item,
      acesso,
    };
  });
  const renderMenus = resultadoComAcesso;

  const header = (
    <div className="col-6 d-grid gap-2 d-md-flex">
      <ButtonComponent icon={<BsPlusLg />} text="Expandir Tudo" onClick={expandAll} variant="secondary" />
      <ButtonComponent icon={<BsDashLg />} text="Agrupar Tudo" onClick={collapseAll} variant="secondary" />
    </div>
  );

  useEffect(() => {
    setSelectedUsuario(null);
  }, [selectedEmpresa]);

  return (
    <ContainerPage>
      <FiltersCompanyUser />
      <MenuAccessData />
      {tabActive === TABS.ACCESS_MENU ? (
        <S.ContainerTable>
          <BlockUI
            containerStyle={{ zIndex: 0 }}
            blocked={selectedUsuario == null}
            template={BlockTableTemplate('Selecione uma empresa e usuário')}
          >
            <DataTable
              value={selectedUsuario == null ? [] : renderMenus}
              loading={isFetching || isLoadingAcessos || isLoadingSave}
              sortMode="single"
              sortField="grupo"
              sortOrder={1}
              editMode="cell"
              dataKey="codMenu"
              expandedRows={expandedRows}
              emptyMessage={selectedUsuario == null ? 'Selecione um usuário' : 'Nenhum registro'}
              onRowToggle={(e) => setExpandedRows(e.data)}
              rowExpansionTemplate={rowExpansionTemplate}
              header={header}
              style={{ fontSize: '16px' }}
            >
              <Column expander style={{ width: '3em' }} />
              <Column field="grupo" header="Grupo Menu" sortable />
              <Column field="nomeMenu" header="Nome Menu" />
              <Column
                header="Acesso"
                field="acesso"
                headerStyle={styleHeaderCenter}
                // editor={(e) => booleanEditor(e)}
                // onCellEditComplete={onCellEditComplete}
                // body={acessoTemplate}
                body={menuUserAccessTemplate}
              />
            </DataTable>
          </BlockUI>
        </S.ContainerTable>
      ) : tabActive === TABS.ACCESS_DATA ? (
        <AccessData selectedUsuario={selectedUsuario} />
      ) : tabActive === TABS.ALTER_PASSWORD ? (
        <ResetUserData />
      ) : (
        <></>
      )}
    </ContainerPage>
  );
};

export default UsuarioItensMenu;
