import { Calendar, CalendarProps } from 'primereact/calendar';
import { useState } from 'react';
import styled from 'styled-components';

const UseSelectDate = (defaultDate?: string) => {
  const [selectedDate, setSelectedDate] = useState<string>(defaultDate ?? null);

  const SelectDate = ({
    onChange,
    width,
    label = 'Data',
    placeholder = 'dd/mm/aaaa',
    ...rest
  }: {
    onChange?: (data: string) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  } & CalendarProps) => {
    const widthCalculated = 100;

    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: clamp(100px, ${width ?? `${widthCalculated}px`}, ${width ?? '175px'});
    `;

    const handleChange = (data: string) => {
      setSelectedDate(data);
      if (onChange) {
        onChange(data);
      }
    };

    return (
      <InputBox>
        <label>{label}</label>
        <Calendar
          value={selectedDate}
          onChange={(e) => handleChange(e.value as string)}
          placeholder={placeholder}
          showButtonBar
          dateFormat="dd/mm/yy"
          {...rest}
        />
      </InputBox>
    );
  };

  return {
    SelectDate,
    selectedDate,
    setSelectedDate,
  };
};

export default UseSelectDate;
