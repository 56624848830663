import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useEffect, useState } from 'react';
import { AiFillEdit, AiOutlineDelete } from 'react-icons/ai';
import { BsArrow90DegLeft, BsPlusLg } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import VmButton from 'components/VmButton/VmButton';

import AddressModal from '../../components/addressModal/AddressModal';
import * as S from './styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { TITLE_PAGE } from 'enums/titlePages';
import { deleteRotaPonto } from 'client/api/ponto';
import { UseCreatePoint } from 'hooks/usePoint/useCreatePoint/useCreatePoint';
import { IRouterStartPointsData } from 'client/interfaces';
import { Dropdown } from 'primereact/dropdown';
import { useVendedores } from 'client/hooks/vendedores';

const CadastroPonto = () => {
  const {
    codEmpresa: codEmpresaParam,
    codMapa: codMapaParam,
    codVendedor: codVendedorParam,
  } = useParams<{ codEmpresa: string; codMapa: string; codVendedor: string }>();
  const { getPointList, pontosList } = UseCreatePoint(+codEmpresaParam);
  const { data: vendedores } = useVendedores(+codEmpresaParam);

  const { setTitlePage, titlePage } = useTitlePageStore();

  const [showModal, setShowModal] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  const [toEditPoint, setToEditPoint] = useState<IRouterStartPointsData>();

  const defaultVendedor: any = {
    codEmpresa: -1,
    codSupervisor: -1,
    codVendedor: -1,
    dtLatlong: null,
    latitude: '',
    longitude: '',
    nome: 'TODOS',
  };

  const [selectedVendedor, setSelectedVendedor] = useState(defaultVendedor);

  const editTemplate = (rowData: IRouterStartPointsData) => {
    return (
      <VmButton
        options={{
          icon: <AiFillEdit />,
          iconPosition: 'left',
          rounded: true,
          tooltip: {
            show: true,
            text: 'Editar',
            position: 'right',
          },
          style: 'btn-light',
        }}
        style={{ marginRight: '10px' }}
        onClick={() => {
          setIsEdit(true);
          setShowModal(true);
          setToEditPoint(rowData);
        }}
      />
    );
  };

  const removeTemplate = (rowData: IRouterStartPointsData) => {
    return (
      <VmButton
        options={{
          icon: <AiOutlineDelete />,
          iconPosition: 'left',
          rounded: true,
          tooltip: {
            show: true,
            text: 'Remover',
            position: 'right',
          },
          style: 'btn-light',
        }}
        style={{ marginRight: '10px' }}
        onClick={() => {
          Swal.fire({
            title: 'Tem certeza?',
            html: `Deseja remover este ponto de referência? </br> ` + `"${rowData.codPonto} - ${rowData.descricao} "`,
            icon: 'question',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não, voltar',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
          })
            .then((result) => {
              if (result.isConfirmed) {
                deleteRotaPonto({
                  codPonto: rowData.codPonto,
                  descricao: rowData.casa,
                  codVendedor: rowData.codVendedor,
                  latitude: rowData.latitude,
                  longitude: rowData.longitude,
                  casa: rowData.casa,
                  codEmpresa: rowData.codEmpresa,
                })
                  .then(() => {
                    getPointList();
                    toast.success('Ponto removido com sucesso!');
                  })
                  .catch((err) => {
                    toast.success('Erro ao remover ponto!');
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }}
      />
    );
  };

  const vendedorTemplate = (rowData: IRouterStartPointsData) => {
    return (
      <div>
        {rowData.nomeVendedor}[{rowData.codVendedor}]
      </div>
    );
  };

  useEffect(() => {
    if (!showModal && isEdit) {
      setIsEdit(false);
      return;
    }
  }, [showModal]);

  useEffect(() => {
    getPointList();
    setTitlePage(TITLE_PAGE.SCREENWRITER_MY_PLACES);
  }, [titlePage]);

  useEffect(() => {
    if (vendedores) {
      setSelectedVendedor(
        vendedores ? vendedores.filter((rca: any) => rca.codVendedor == +codVendedorParam)[0] : defaultVendedor,
      );
    }
  }, [vendedores]);

  return (
    <div>
      <S.HeaderButtons>
        <S.Box htmlFor="name" className="font-bold">
          Vendedor
          <Dropdown
            options={[...[defaultVendedor], ...(vendedores ?? [])]}
            optionLabel="nome"
            placeholder={selectedVendedor?.nome || 'Selecione um vendedor'}
            value={selectedVendedor}
            itemTemplate={(option) => `${option.nome} [${option.codVendedor}]`}
            filter
            filterBy="nome,codVendedor"
            onChange={(e) => {
              setSelectedVendedor(e.target.value);
            }}
          />
        </S.Box>
        <VmButton
          options={{
            icon: <BsPlusLg />,
            iconPosition: 'left',
            label: 'Novo Local',
            style: 'btn-success',
          }}
          style={{ marginRight: '10px' }}
          onClick={() => {
            setShowModal(true);
          }}
        />

        <VmButton
          options={{
            icon: <BsArrow90DegLeft />,
            iconPosition: 'left',
            label: 'Voltar',
            style: 'btn-danger',
          }}
          style={{ marginRight: '10px' }}
          onClick={() => {
            window.history.back();
          }}
        />
      </S.HeaderButtons>

      <S.ContainerTable>
        <DataTable
          value={
            selectedVendedor && selectedVendedor.codVendedor == -1
              ? pontosList
              : pontosList.filter(
                  (rca: any) => rca.codVendedor == (selectedVendedor ? selectedVendedor.codVendedor : 0),
                )
          }
          tableStyle={{ minWidth: '100%' }}
          paginator
          rows={8}
          style={{ fontSize: '.8rem' }}
        >
          <Column field="codPonto" header="COD PONTO" sortable />
          <Column field="descricao" header="DESCRICAO" sortable />
          <Column field="codVendedor" header="VENDEDOR" sortable body={vendedorTemplate} />
          <Column field="latitude" header="LATITUDE" sortable />
          <Column field="longitude" header="LONGITUDE" sortable />
          <Column field="casa" header="CASA" sortable />
          <Column field="codEmpresa" header="COD EMPRESA" sortable />
          <Column field="edit" header="Editar" body={editTemplate} />
          <Column field="remove" header="Remover" body={removeTemplate} />
        </DataTable>
      </S.ContainerTable>

      <AddressModal
        isAdress={false}
        isEdit={isEdit}
        showModal={showModal}
        setShowModal={setShowModal}
        toEditPoint={toEditPoint}
        getPointList={getPointList}
        codMapaParam={codMapaParam}
        codEmpresaParam={codEmpresaParam}
        codVendedorParam={+codVendedorParam}
        codVendedor={codVendedorParam}
      />
    </div>
  );
};

export default CadastroPonto;
