import styled from 'styled-components';

export const MapContainerBox = styled.section`
  position: relative;

  .leaflet-popup-content-wrapper {
    width: 480px !important;
    height: 425px !important;
    overflow: hidden !important;
    padding: 0 !important;
  }

  .leaflet-popup-content {
    width: 100%;
    height: 100%;
    padding: 0 !important;
  }

  .leaflet-control-layers {
    z-index: 9 !important;
    max-width: 120px !important;
  }

  .leaflet-control-container .leaflet-top {
    position: absolute !important;
    top: calc(100% - 70px);
    left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 9 !important;
  }

  .leaflet-draw {
    position: absolute !important;
    top: calc(100% - 130px);
    left: -10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 9 !important;
    max-height: 50px;
  }

  .leaflet-draw-edit-edit,
  .leaflet-draw-edit-remove {
    display: none;
  }

  .leaflet-control {
    float: none !important;
  }

  .leaflet-control-layers-list {
    min-height: 80px;
    min-width: 100px;
    z-index: 9 !important;
    padding: 2px;
    z-index: 9 !important;
    background-color: #fff !important;
  }

  .leaflet-control-layers:hover {
    margin-top: -30px;
  }

  .leaflet-control-zoom {
    position: absolute !important;
    top: calc(100% - 140px);
    left: 0px;
    z-index: 8 !important;
  }

  .custom-popup-visit-control .leaflet-popup-content-wrapper {
    width: 320px !important;
    min-height: 100px !important;
    max-height: 170px !important;
    padding: 10px 0px !important;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .custom-popup-visit-control .leaflet-popup-content-wrapper .leaflet-popup-content {
    padding: 0;
    width: 100% !important;
    margin: 0px !important;
  }

  .custom-popup-visit-control .leaflet-popup-close-button {
    background-color: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
  }

  .custom-popup-visit-control .leaflet-popup-tip {
    /* display: none; */
  }

  @media only screen and (max-width: 1024px) {
    .leaflet-control-container .leaflet-top {
      position: absolute !important;
      top: calc(100% - 235px);
      left: 10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      z-index: 9 !important;
    }

    .leaflet-draw {
      position: absolute !important;
      top: calc(100% - 150px);
      left: -10px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      z-index: 9 !important;
      max-height: 50px;
    }

    .leaflet-control-zoom {
      position: absolute !important;
      top: calc(100% - 315px);
      left: 0px;
      z-index: 8 !important;
    }
  }
`;

export const CustomInfoWindow = styled.div`
  position: relative;
  top: 0px;
  white-space: nowrap;
  padding: 5px;
`;
