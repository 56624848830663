import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  getMapas,
  getMapa,
  getEstados,
  getCidades,
  getMapasEmpresa,
  getVendedorAcompanhamento,
  getVendedorCheckinCheckout,
  getMapsByUser,
} from 'client/api';
import { IbgeCidade, IbgeEstado, Mapa } from 'client/interfaces';

export const useMapas = (enabled = true) =>
  useQuery<Mapa[], AxiosError>(['mapas'], getMapas, {
    staleTime: 1000 * 60 * 15,
    enabled,
  });

export const useMapa = (id: number, enabled = true) =>
  useQuery<Mapa, AxiosError>(['mapas', id], async () => await getMapa(id), {
    staleTime: 1000 * 60 * 15,
    enabled,
  });

export const useEstados = (codPais?: number, enabled = true) =>
  useQuery<IbgeEstado[], AxiosError>(['estados', codPais], async () => await getEstados(codPais), {
    staleTime: 1000 * 60 * 15,
    enabled,
  });

export const useCidades = (codUf: number[], enabled = true) =>
  useQuery<IbgeCidade[], AxiosError>(['cidades', codUf], async () => await getCidades(codUf), {
    staleTime: 1000 * 60 * 15,
    enabled,
  });

export const useMapasEmpresa = (codEmpresa: number, enabled = true) =>
  useQuery<Mapa[], AxiosError>(['mapas', codEmpresa], async () => await getMapasEmpresa(codEmpresa), {
    staleTime: 1000 * 60 * 15,
    enabled,
  });

export const useMapsByUser = (codCompany: number, codUser: number, enabled = true) =>
  useQuery<Mapa[], AxiosError>(
    ['mapas-by-user', codCompany, codUser],
    async () => await getMapsByUser(codCompany, codUser ?? 0),
    {
      staleTime: 0,
      enabled,
    },
  );

export const useVendedorAcompanhamento = (
  codEmpresa: number,
  codGerente: string,
  codSupervisor: string,
  codVendedor: string,
  dtIni: string,
  dtFim: string,
  codUsuario: string,
  enabled = true,
) =>
  useQuery<Mapa[], AxiosError>(
    ['visitasVendedores', codEmpresa],
    async () => await getVendedorAcompanhamento(codEmpresa, codGerente, codSupervisor, codVendedor, dtIni, dtFim, codUsuario),
    {
      staleTime: 1000 * 60 * 15,
      enabled,
    },
  );

export const useVendedorCheckinCheckout = (
  codEmpresa: number,
  codVendedor: string,
  dtIni: string,
  dtFim: string,
  codUsuario: string,
  enabled = true,
) =>
  useQuery<Mapa[], AxiosError>(
    ['checkinOutVendedores', codEmpresa],
    async () => await getVendedorCheckinCheckout(codEmpresa, codVendedor, dtIni, dtFim, codUsuario),
    {
      staleTime: 1000 * 60 * 15,
      enabled,
    },
  );
