import styled from 'styled-components';

export const EmptyMainBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  overflow: hidden;
`;

export const EmptyImage = styled.img`
  max-width: 50%;
  max-height: 50%;
`;

export const EmptyText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  /* font-weight: 600; */
  /* color: #000; */
`;
