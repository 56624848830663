import { FomularioAnswerProps } from '../../AnaliseForms.types';
import * as S from './formImageCards.styles';
import { Image } from 'primereact/image';

import { BASE_URL } from 'services/api';

export default function FormImageCards({ dataList }: { dataList: FomularioAnswerProps[] }) {

  const formatDate = (date: string | Date) => {
    if (!date) return 'N/A';
    return new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  const ImageCard = ({ imageData }: { imageData: FomularioAnswerProps }) => {
    return (
      <S.FormImagesCards>
        <Image src={`${BASE_URL}/fotos_formulario/${imageData.resposta}`} alt="Image" preview />
        <S.FormImagesCardsTitle>
          <span>{formatDate(imageData.dtresposta)}</span>
          <span>{`[${imageData.codrca}] ${imageData.vendedor ? imageData.vendedor.toUpperCase() : ''}`}</span>
          <span>{`[${imageData.codcli}] ${imageData.cliente ? imageData.cliente.toUpperCase() : ''}`}</span>
        </S.FormImagesCardsTitle>
      </S.FormImagesCards>
    );
  };

  return (
    <S.FormImagesCardsMainBox>
      {dataList?.map((img: FomularioAnswerProps) => (
        <ImageCard imageData={img} />
      ))}
    </S.FormImagesCardsMainBox>
  );
}
