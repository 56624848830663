interface ColorProps {
  COR: string;
  NOME: string;
  CORES: {
    COR: string;
    NOME: string;
  }[];
}

const colors: ColorProps[] = [
  {
    NOME: 'AZUL',
    COR: '375E97',
    CORES: [
      { NOME: 'AZUL BEBÊ', COR: 'e1e7ef' },
      { NOME: 'AZUL CLARO', COR: 'c1cddf' },
      { NOME: 'AZUL PASTEL', COR: 'd2e8ff' },
      { NOME: 'AZUL GELA', COR: 'a1c3e7' },
      { NOME: 'AZUL FÓSFORO', COR: '92b8d4' },
      { NOME: 'AZUL MEDIANO', COR: '68a0c5' },
      { NOME: 'AZUL MÉDIO', COR: '375e97' },
      { NOME: 'AZUL INTENSO', COR: '325588' },
      { NOME: 'AZUL ESCURO', COR: '2c4b79' },
      { NOME: 'AZUL', COR: '4235cc' },
    ],
  },
  {
    NOME: 'VERDE',
    COR: '228F6B',
    CORES: [
      { NOME: 'VERDE CLARÍSSIMO', COR: 'edfaf3' },
      { NOME: 'VERDE MENTA', COR: 'c6f0da' },
      { NOME: 'VERDE ÁGUA', COR: 'abe8c9' },
      { NOME: 'VERDE PISTACHE', COR: '84deb0' },
      { NOME: 'VERDE CLARO', COR: '6dd8a1' },
      { NOME: 'VERDE FOLHA', COR: '48ce89' },
      { NOME: 'VERDE LIMO', COR: '42bb7d' },
      { NOME: 'VERDE', COR: '39b85b' },
      { NOME: 'VERDE ESCURO', COR: '28714b' },
      { NOME: 'VERDE PETRÓLEO', COR: '1e573a' },
    ],
  },
  {
    NOME: 'VERMELHO',
    COR: 'B20000',
    CORES: [
      { NOME: 'VERMELHO CLARÍSSIMO', COR: 'f7e1dd' },
      { NOME: 'VERMELHO PASTEL', COR: 'f5c1b7' },
      { NOME: 'VERMELHO ROSADO', COR: 'f1a28f' },
      { NOME: 'VERMELHO SALMÃO', COR: 'ec7e63' },
      { NOME: 'VERMELHO CORAL', COR: 'e35847' },
      { NOME: 'VERMELHO INTENSO', COR: 'dc2c2c' },
      { NOME: 'VERMELHO QUEIMADO', COR: 'B20000' },
      { NOME: 'VERMELHO TERRACOTA', COR: '8a1213' },
      { NOME: 'VERMELHO ESCURO', COR: '601111' },
      { NOME: 'VERMELHO PROFUNDO', COR: '4c0e10' },
    ],
  },
  {
    NOME: 'LARANJA',
    COR: 'F64F3D',
    CORES: [
      { NOME: 'LARANJA CLARÍSSIMO', COR: 'ffebcc' },
      { NOME: 'LARANJA PASTEL', COR: 'ffdb99' },
      { NOME: 'LARANJA MEL', COR: 'ffcc66' },
      { NOME: 'LARANJA BEGE', COR: 'ffb233' },
      { NOME: 'LARANJA DOURADO', COR: 'ff9d00' },
      { NOME: 'LARANJA PÊSSEGO', COR: 'FF8000' },
      { NOME: 'LARANJA CORAL', COR: 'ff6600' },
      { NOME: 'LARANJA QUEIMADO', COR: 'f35a00' },
      { NOME: 'LARANJA ESCURO', COR: 'e85d00' },
      { NOME: 'LARANJA PROFUNDO', COR: 'e15200' },
    ],
  },
  {
    NOME: 'AMARELO',
    COR: 'FFC40F',
    CORES: [
      { NOME: 'AMARELO CLARÍSSIMO', COR: 'fff8ea' },
      { NOME: 'AMARELO PASTEL', COR: 'ffe9bd' },
      { NOME: 'AMARELO SUAVE', COR: 'ffdf9d' },
      { NOME: 'AMARELO VIBRANTE', COR: 'ffd070' },
      { NOME: 'AMARELO OURO', COR: 'FFC40F' },
      { NOME: 'AMARELO INTENSO', COR: 'ffb92a' },
      { NOME: 'AMARELO MOSTARDA', COR: 'e8a826' },
      { NOME: 'AMARELO QUEIMADO', COR: 'b5831e' },
      { NOME: 'AMARELO ESCURO', COR: '8c6617' },
      { NOME: 'AMARELO PROFUNDO', COR: '6b4e12' },
    ],
  },
  {
    NOME: 'ROXO',
    COR: '8500B2',
    CORES: [
      { NOME: 'ROXO CLARÍSSIMO', COR: 'f2e6f9' },
      { NOME: 'ROXO PASTEL', COR: 'e1c6e9' },
      { NOME: 'ROXO LAVANDA', COR: 'd3a0d6' },
      { NOME: 'ROXO LILÁS', COR: 'c47ac6' },
      { NOME: 'ROXO VIOLETA', COR: 'b364b3' },
      { NOME: 'ROXO FÚCSIA', COR: '9f459e' },
      { NOME: 'ROXO INTENSO', COR: '8f3b89' },
      { NOME: 'ROXO ESCURO', COR: '7c2d75' },
      { NOME: 'ROXO AMETISTA', COR: '692263' },
      { NOME: 'ROXO PROFUNDO', COR: '8500B2' },
    ],
  },
  {
    NOME: 'ROSA',
    COR: 'FF7373',
    CORES: [
      { NOME: 'PINK CLARÍSSIMO', COR: 'fbe9f2' },
      { NOME: 'PINK PASTEL', COR: 'f5c1d7' },
      { NOME: 'PINK BEGE', COR: 'e8a2bb' },
      { NOME: 'PINK LAVANDA', COR: 'e17ca2' },
      { NOME: 'PINK ROSADO', COR: 'FF7373' },
      { NOME: 'PINK FÚCSIA', COR: 'cf3a7b' },
      { NOME: 'PINK INTENSO', COR: 'b92e69' },
      { NOME: 'PINK ESCURO', COR: 'a02559' },
      { NOME: 'PINK MAGENTA', COR: '8c1f47' },
      { NOME: 'PINK PROFUNDO', COR: '692534' },
    ],
  },
  {
    NOME: 'MARRON',
    COR: '663300',
    CORES: [
      { NOME: 'MARROM CLARÍSSIMO', COR: 'f1e1d4' },
      { NOME: 'MARROM BEGE', COR: 'e2c1a6' },
      { NOME: 'MARROM PASTEL', COR: 'd1a67a' },
      { NOME: 'MARROM AMBAR', COR: 'c18e5b' },
      { NOME: 'MARROM CANELA', COR: 'a76b3d' },
      { NOME: 'MARROM CARAMEL', COR: '8f4f32' },
      { NOME: 'MARROM RICO', COR: '7a3d2b' },
      { NOME: 'MARROM ESCURO', COR: '663300' }, //602a19
      { NOME: 'MARROM CHOCOLATE', COR: '4c1e12' },
      { NOME: 'MARROM PROFUNDO', COR: '3a150d' },
    ],
  },
  {
    NOME: 'BEGE',
    COR: 'E5CE98',
    CORES: [
      { NOME: 'BEGE CLARO', COR: 'fdf0d5' },
      { NOME: 'BEGE PASTEL', COR: 'f6e3c4' },
      { NOME: 'BEGE PELE', COR: 'f1dbab' },
      { NOME: 'BEGE CREME', COR: 'e8d59f' },
      { NOME: 'BEGE NATURAL', COR: 'E5CE98' },
      { NOME: 'BEGE', COR: 'd4b879' },
      { NOME: 'BEGE MÉDIO', COR: 'c8a066' },
      { NOME: 'BEGE ESCURO', COR: 'b5934f' },
      { NOME: 'BEGE TERRA', COR: 'a67c3d' },
      { NOME: 'BEGE FORTE', COR: '9c5f2e' },
    ],
  },
];

export default colors;
