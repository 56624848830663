import { BsFillPinMapFill, BsFillTrash3Fill, BsIntersect, BsPersonFillCheck, BsStopwatchFill } from 'react-icons/bs';
import { ClientesProps, SetoresFilterObjectProps, SetoresObjectProps } from '../../regioes.types';
import * as S from './mapCliPopUp.styles';
import VmButton from 'components/VmButton/VmButton';
import { HiCurrencyDollar } from 'react-icons/hi';
import history from 'assets/svgs/history.svg';
import copyFile from 'assets/svgs/copyFile.svg';
import mapMarker from 'assets/svgs/mapMarker.svg';
import marker from 'assets/svgs/marker.svg';
import globle from 'assets/svgs/globe.svg';
import user from 'assets/svgs/user.svg';
import { toast } from 'react-toastify';
import { SetStateAction } from 'react';

export default function MapCliPopUp({
  cliente,
  setores,
  setoresFilter,
  handleRemoveCliSector,
  showEditAddressModal,
  setShowEditAddressModal,
  showVinculoSetor,
  setShowVinculoSetor,
  showEditVisit,
  setShowEditVisit,
}: {
  cliente: ClientesProps;
  setores: SetoresObjectProps;
  setoresFilter: SetoresFilterObjectProps;
  handleRemoveCliSector: () => void;
  showEditAddressModal: boolean;
  setShowEditAddressModal: React.Dispatch<SetStateAction<boolean>>;
  showVinculoSetor: boolean;
  setShowVinculoSetor: React.Dispatch<SetStateAction<boolean>>;
  showEditVisit: boolean;
  setShowEditVisit: React.Dispatch<SetStateAction<boolean>>;
}) {
  const copyText = () => {
    const textoNoParagrafo = `${cliente.LATITUDE}, ${cliente.LONGITUDE}`;

    const textarea = document.createElement('textarea');
    textarea.value = textoNoParagrafo;

    document.body.appendChild(textarea);
    textarea.select();

    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast.success('Coordenadas copiadas para a área de transferência com sucesso!');
  };

  const clientSector = setores?.unfiltered?.filter((set) => set.CODSETOR === cliente.CODSETOR)[0];

  const numberFormat = (number) => {
    if (number != null && number.toString() == 'Infinity') {
      number = 100.0;
    }
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'USD' }).format(number).replace('$', '');
  };

  type PercBarProps = {
    value: number;
  };

  const PercBar = ({ value }: PercBarProps) => {
    return (
      <S.PercBarBox>
        <S.PercBar
          width={value}
          color={value <= 25 ? '#f32929' : value <= 50 ? '#FFC300' : value <= 70 ? '#F64F3D' : '#228F6B'}
        ></S.PercBar>
      </S.PercBarBox>
    );
  };

  type PotencialDataProps = {
    vendaPotencial: number;
    vendaUltTri: number;
    vendaPercUltTri?: number;
    vendaUltMes: number;
    vendaPercUltMes?: number;
  };

  const vendaPotencialData = (props: PotencialDataProps) => {
    props.vendaPercUltTri = props.vendaUltTri < 1 ? 0 : +((props.vendaUltTri * 100) / props.vendaPotencial).toFixed(2);
    props.vendaPercUltMes = props.vendaUltMes < 1 ? 0 : +((props.vendaUltMes * 100) / props.vendaPotencial).toFixed(2);

    return (
      <S.SetorVendaPotencialRow>
        <S.SetorVendaPotencialRowContent>
          <span>
            Ult. Trim: <span>R${numberFormat(props.vendaUltTri)}</span>
          </span>
          <S.PercentageBox>
            <span>{props.vendaPercUltTri}%</span>
          </S.PercentageBox>
          <PercBar value={props.vendaPercUltTri} />
        </S.SetorVendaPotencialRowContent>
        <S.SetorVendaPotencialRowContent>
          <span>
            Ult. Mês: <span>R${numberFormat(props.vendaUltMes)}</span>
          </span>
          <S.PercentageBox>
            <span>{props.vendaPercUltMes}%</span>
          </S.PercentageBox>
          <PercBar value={props.vendaPercUltMes} />
        </S.SetorVendaPotencialRowContent>
      </S.SetorVendaPotencialRow>
    );
  };

  return (
    <S.MarkerContainer key={cliente.CODCLI}>
      <S.MarkerHeader>
        <S.Badge
          style={{
            background: `#${cliente.SETOR_ATIVO == 'N' && setoresFilter.code == 'A' ? '000000' : cliente?.COR}`,
            border: 'none',
          }}
          onClick={() => {
            console.log('setoresFilter :', setoresFilter);
          }}
        >
          <img src={user} />
        </S.Badge>
        {cliente ? <p>{`${cliente.RAZAOSOCIAL} [${cliente.CODCLI}]`}</p> : '--'}
      </S.MarkerHeader>
      <S.MarkerBody>
        <S.MarkerRow>
          <span>
            <S.Badge>
              <img src={globle} />
            </S.Badge>
            <p>Setor</p>
          </span>

          <S.Description>
            <S.BadgeSquare
              style={{
                color: `#${cliente.SETOR_ATIVO == 'N' && setoresFilter.code == 'A' ? '000000' : cliente?.COR}`,
                borderColor: `#${cliente.SETOR_ATIVO == 'N' && setoresFilter.code == 'A' ? '000000' : cliente?.COR}`,
              }}
            >
              <span>
                {cliente.SETOR_ATIVO == 'N' && setoresFilter.code == 'A'
                  ? 'Cliente sem setor'
                  : clientSector
                  ? `${clientSector.NOMESETOR} [${clientSector.CODSETOR}]`
                  : 'Cliente sem setor'}
              </span>
            </S.BadgeSquare>
          </S.Description>
        </S.MarkerRow>
        <S.Separator />
        <S.MarkerRow>
          <span>
            <S.Badge>
              <img src={mapMarker} />
            </S.Badge>

            <p>Endereço</p>
          </span>

          <S.Description>
            {cliente.ENDLOGRADOURO} {cliente.ENDNUMERO ? `Nº${cliente.ENDNUMERO}` : '--'}, {cliente.ENDCIDADE}{' '}
            {cliente.ENDUF}
          </S.Description>
        </S.MarkerRow>
        <S.Separator />

        <S.MarkerRow>
          <span>
            <S.Badge>
              <img src={marker} />
            </S.Badge>

            <p>Coordenadas</p>
          </span>

          <S.Description onClick={copyText} style={{ cursor: 'pointer' }}>
            {cliente.LATITUDE}, {cliente.LONGITUDE} <img src={copyFile} />
          </S.Description>
        </S.MarkerRow>
        <S.Separator />

        <S.MarkerRow>
          <span>
            <S.Badge>
              <img src={history} />
            </S.Badge>
            <p>Frequência Visita:</p>
          </span>
          <S.Description onClick={copyText} style={{ cursor: 'pointer' }}>
            {cliente.FREQVISITA}
          </S.Description>
        </S.MarkerRow>
        <S.Separator />

        <S.MarkerRow>
          <span>
            <S.Badge>
              <BsStopwatchFill color={'#a8aabc'} />
            </S.Badge>
            <p>Tempo Mínimo Visita:</p>
          </span>
          <S.Description onClick={copyText} style={{ cursor: 'pointer' }}>
            {cliente.TEMPOMINVISITA}
          </S.Description>
        </S.MarkerRow>
        <S.Separator />

        <S.MarkerPotencialRow>
          <span>
            <S.Badge>
              <HiCurrencyDollar color={'#a8aabc'} />
            </S.Badge>
            <p>
              Potencial: <br /> R${numberFormat(cliente.VL_POTENCIAL)}{' '}
            </p>
          </span>
          <S.Description style={{ cursor: 'pointer' }}>
            {vendaPotencialData({
              vendaPotencial: cliente.VL_POTENCIAL,
              vendaUltMes: cliente.VL_MES_ATUAL,
              vendaUltTri: cliente.VL_MED_ULT_TRIM,
            })}
          </S.Description>
        </S.MarkerPotencialRow>

        <S.MarkerRowButtons>
          <VmButton
            style={{ marginRight: '10px' }}
            options={{
              style: 'btn-dark',
              rounded: true,
              icon: <BsPersonFillCheck />,
              iconPosition: 'right',
              size: `btn-sm`,
              tooltip: {
                show: true,
                position: 'top',
                text: 'Editar Visita',
              },
            }}
            onClick={() => {
              setShowEditVisit(!showEditVisit);
            }}
          />

          <VmButton
            options={{
              style: 'btn-dark',
              rounded: true,
              icon: <BsIntersect />,
              iconPosition: 'right',
              size: `btn-sm`,
              tooltip: {
                show: true,
                position: 'top',
                text: 'Vincular Clinte a Setor',
              },
            }}
            onClick={() => {
              setShowVinculoSetor(!showVinculoSetor);
            }}
          />

          <VmButton
            style={{ marginLeft: '10px' }}
            options={{
              style: 'btn-info',
              rounded: true,
              icon: <BsFillPinMapFill />,
              iconPosition: 'right',
              tooltip: {
                show: true,
                position: 'top',
                text: 'Editar Coordenadas Cliente',
              },
            }}
            onClick={() => {
              setShowEditAddressModal(!showEditAddressModal);
            }}
          />

          <VmButton
            style={{ marginLeft: '10px' }}
            options={{
              style: 'btn-danger',
              rounded: true,
              icon: <BsFillTrash3Fill />,
              iconPosition: 'right',
              tooltip: {
                show: true,
                position: 'top',
                text: 'Remover Cliente',
              },
            }}
            onClick={() => handleRemoveCliSector()}
          />
        </S.MarkerRowButtons>
      </S.MarkerBody>
    </S.MarkerContainer>
  );
}
