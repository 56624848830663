import styled, { keyframes } from 'styled-components';
type SetorCardProps = {
  color: string;
};

type ButtonsBoxProps = {
  mapColor: string;
};

type PercBarProps = {
  width: number;
  color: string;
};

export const SectorCardBox = styled.section<SetorCardProps>`
  background-color: #ffff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-left: 3px solid ${(props) => (props.color ? `#${props.color}` : 'transparent')};
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  color: #383e7d;
`;

export const SectorCardHeader = styled.div`
  background-color: #ffff;
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 7.5px;
  padding-bottom: 5px;
  position: relative;
`;

export const SectorCardHeaderSelect = styled.div``;

export const SectorCardHeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 7.5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e1e2f0;
`;

export const SectorCardHeaderTags = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const SectorCardName = styled.span`
  background-color: #ffff;
  border-radius: 5px;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;

  &:hover {
    font-weight: 500;
  }
`;

export const SectorCardRcaName = styled.span`
  background-color: #ffff;
  border-radius: 5px;
  width: 100%;
  font-size: 0.8rem;
  color: #9497b8;
`;

export const SectorCardInfo = styled.div`
  background-color: #ffff;
  border-radius: 5px;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
`;

export const SectorCardCliInfo = styled.div`
  background-color: #ffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;

  & > span:nth-of-type(2) {
    font-weight: 500;
  }
`;

export const SectorCardKmInfo = styled.div`
  background-color: #ffff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;

  & > span:nth-of-type(2) {
    font-weight: 500;
  }
`;

export const SectorCardButtonsBox = styled.div<ButtonsBoxProps>`
  background-color: #ffff;
  border-radius: 5px;
  font-size: 0.8rem;
  display: flex;
  flex-direction: row;
  gap: 10px;
  svg {
    font-size: 1.5rem;
    cursor: pointer;
  }

  & > svg:nth-of-type(1),
  & > svg:nth-of-type(2) {
    color: #9497b8;
    transition: all 0.4s ease;
  }

  & > svg:nth-of-type(1):hover,
  & > svg:nth-of-type(2):hover {
    color: #000;
  }

  & > svg:nth-of-type(3) {
    color: ${(props) => (props.mapColor ? `${props.mapColor}` : 'transparent')};
  }
`;

export const SetorVendaPotencialRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-size: 0.8rem;
  white-space: nowrap;
  width: 90%;
  margin: 0 auto;
`;

export const SetorVendaPotencialRowContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-height: 32px;
  gap: 3px;
  & > p:nth-of-type(1) {
    font-weight: 500;
  }
  & > span > span {
    font-weight: 500;
  }
`;

export const PercBarBox = styled.div`
  display: flex;
  background-color: #60606025;
  width: clamp(40px, 50px, 100%);
  height: 0.35rem;
  border-radius: 7.5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const PercBar = styled.div<PercBarProps>`
  height: 100%;
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.color}B9;
  position: absolute;
`;

type PercBarTextProps = {
  color: string;
};

export const PercBarText = styled.div<PercBarTextProps>`
  position: absolute;
  right: 5px;
  top: 0px;
  color: ${(props) => props.color};
  font-size: 0.7rem !important;
  height: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: first baseline;
  text-align: center;
`;

export const PercentageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  span {
    background-color: #60606010;
    text-align: center;
    padding: 2px;
    border-radius: 2px;
  }
`;

// LOADING
const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

export const SectorCardLoadingBox = styled.section`
  background-color: #ffff;
  border-radius: 5px;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
  color: #383e7d;

  .loading {
    position: relative;
    overflow: hidden;
    background: linear-gradient(
      90deg,
      rgba(240, 240, 240, 1) 25%,
      rgba(220, 220, 220, 1) 50%,
      rgba(240, 240, 240, 1) 75%
    );
    background-size: 200% 100%;
    animation: ${shimmer} 1.9s infinite;
    border-radius: 5px;
  }
`;

export const SectorCardLoadingHeader = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-bottom: 1px solid #e1e2f0;
  padding: 0 0 5px 0;
  width: 95%;
  margin: 0 auto;
`;

export const SectorCardLoadingHeaderName = styled.div`
  width: 100%;
  height: 1.3rem;
`;

export const SectorCardLoadingHeaderRca = styled.div`
  width: 100%;
  height: 1.1rem;
`;

export const SectorCardLoadingFooter = styled.section`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  padding: 0 0 5px 0;
  width: 95%;
  margin: 0 auto;
`;

export const SectorCardLoadingInfoBox = styled.section`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
  padding: 0 0 5px 0;
`;

export const SectorCardLoadingInfoBoxTitle = styled.div`
  margin-right: 0;
  width: 50%;
  height: 1.2rem;
`;

export const SectorCardLoadingInfoBoxValue = styled.div`
  margin-right: 0;
  width: 50%;
  height: 1rem;
`;



export const SectorCardLoadingInfoBox2 = styled.section`
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5px;
  padding: 0 0 5px 0;
`;

export const SectorCardLoadingInfoBoxValue2 = styled.div`
  margin-right: 0;
  width: 40%;
  height: 1.2rem;
`;
export const SectorCardLoadingInfoBoxPercBar= styled.div`
  margin-right: 0;
  width: 100%;
  height: 10px;
`;

export const SectorCardLoadingInfoButtonsBox = styled.section`
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
  padding: 0 0 5px 0;
`;

export const SectorCardLoadingInfoButton = styled.div`
  margin-right: 0;
  width: 30%;
  height: 2.5rem;
`;
