import styled from 'styled-components';

export const RowItens = styled.div`
  display: flex;
  flex-direction: row;
`;

export const IconTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: last baseline;
  gap: 15px;
  padding: 5px 0;
`;

export const ContainerTable = styled.div`
  width: 100%;

  @media (max-width: 992px) {
    padding: 10px;
    padding-bottom: 30px;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: first baseline;
  text-align: left;
  gap: 20px;
`;

export const NewFormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: first baseline;
  text-align: left;
  gap: 10px;
`;

export const NewFormInputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
  gap: 6px;

  label {
    font-weight: bolder;
    font-size: 1.1rem;
    color: #000;
  }

  .p-calendar .p-inputtext,
  .p-dropdown {
    height: 3.5rem;
    border-radius: 7.5px;
  }

  .p-placeholder {
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
  }

  .p-inputtext,
  .p-inputwrapper {
    background-color: var(--gray-300);
    border: none;
  }

  .p-multiselect {
    max-width: 200px !important;
  }
`;

export const ImageFvSizeSuggestion = styled.div`
  color: #cb2a29;
`;

export const FormFvImage = styled.div`
  font-size: 1.5rem;
  padding-right: 10px;
  text-align: center !important;
  svg {
    color: #606060;
    font-size: 1.5rem;
  }

  img {
    max-width: 150px;
    max-height: 150px;
    margin: 0 auto;
    border: 1px solid #60606050;
    cursor: pointer;
    border-radius: 5px;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: first baseline;
  text-align: left;
  gap: 15px;
`;

export const ItemImageTemplateBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
  cursor: pointer;
`;

export const FomImageTableIcon = styled.section`
  font-size: 1.5rem;
  padding-right: 10px;
  text-align: center !important;
  svg {
    color: #606060;
    font-size: 1.5rem;
  }

  img {
    max-width: 50px;
    max-height: 50px;
    margin: 0 auto;
  }
`;

export const RestricaoTable = styled.div`
  padding: 10px;
  table thead {
    position: sticky;
    top: 5rem;
    z-index: 99;
  }
  .p-datatable-header {
    position: sticky !important;
    top: 0rem !important;
    z-index: 101 !important;
  }
`;

export const ItensTable = styled.div`
  padding: 10px;
  table thead {
    position: sticky;
    top: 5rem;
    z-index: 99;
  }
  .p-datatable-header {
    position: sticky !important;
    top: 0rem !important;
    z-index: 101 !important;
  }
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  text-align: left;
`;

export const FloatIcons = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  opacity: 0;

  svg {
    font-size: 1.5rem;
  }
`;

interface ImageExistsProps {
  image?: true;
}

export const ImagemCard = styled.div<ImageExistsProps>`
  border-radius: 5px;
  background-color: #fff;
  min-width: calc(150px + 10px);
  padding: 5px;
  max-height: 150px;
  min-height: 150px;
  width: 100%;
  max-width: 235px;
  transition: all 0.4s ease, border 0.1s ease;
  border: none;
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;

  &:hover {
    border: 2px dashed #228f6b;
    cursor: pointer;
  }

  &::after {
    content: '+ Imagem';
    border: 2px dashed #228f6b;
    background-color: #ffffff99;
    color: #228f6b;
    padding: 10px 10px;
    border-radius: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99 !important;
    opacity: ${(props) => (props.image ? '0' : '0')};
    transition: all 0.4s ease;
    max-width: 100%;
    min-width: 60%;
    text-align: center;
  }

  &:hover::after {
    opacity: 1;
    border: 2px dashed #228f6b;
  }

  input {
    display: none;
  }

  img {
    max-width: 230px;
    max-height: 150px;
  }

  &:hover ${FloatIcons} {
    opacity: 1;
  }
`;
