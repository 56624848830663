import styled from 'styled-components';

interface IMapHeaderProps {
  mapGridShow?: boolean;
}

interface MapHeaderMainBoxProps {
  showMap?: boolean;
}
export const MapHeaderMainBox = styled.div<MapHeaderMainBoxProps>`
  position: absolute;
  left: ${(props) => (props.showMap ? '50px' : '0px')};
  top: 0px;
  z-index: 99;

  width: 120%;
  height: calc(4rem + 20px);

  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 10px 0px 10px 15px;

  @media (max-width: 768px) {
    right: none;
    max-width: none;
    margin-right: 15px;
  }
`;
export const SearchClientMainBoxInput = styled.div`
  padding: 6px !important;
  font-size: 1rem;
  color: var(--dark-900);
  outline: none;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 2px;
  transition: all 0.4s ease;
  border: none;
  width: 10%;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  svg {
    transition: all 0.4s ease;
    font-size: 1.25rem;
    color: var(--dark-900);
    cursor: pointer;

    :hover {
      font-size: 1.2rem;
    }
  }

  @media (max-width: 768px) {
    width: 30%;
  }
`;

export const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  outline: none;
  font-size: 1.25rem;
  width: 100%;
`;

export const ClieSuggestionBox = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  height: 40vh;
  max-height: 80vh;
  width: 150%;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 7px;

  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: all 0.4s ease;

  padding-right: 5px;
`;

export const ClieSuggestionCard = styled.span`
  transition: all 0.4s ease;
  background-color: #fafafa;
  cursor: pointer;
  padding: 5px;
  font-weight: 500;
  white-space: nowrap;

  :hover {
    background-color: #eeeeee;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 9px;
  }
`;

export const NovoSetorForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px !important;
`;

export const MapGridButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  border: none;

  font-size: 1rem;
  color: var(--dark-900);
  outline: none;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  border-radius: 2px;
  transition: all 0.4s ease;
  border: none;
  width: 100px;
  border-radius: 7px;

  @media (max-width: 768px) {
    position: absolute;
    top: 50px;
    left: 10px;
    width: 3rem;
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const Icon = styled.div<IMapHeaderProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  text-align: center;
  font-size: 2rem;
  padding: 5px !important;
  transition: all 0.4s ease;

  background-color: ${(props) => (props.mapGridShow ? 'var(--gray-500)' : '')};
  color: ${(props) => (props.mapGridShow ? 'var(--dark-900)' : 'rgb(77, 77, 77)')};
  border-radius: ${(props) => (props.mapGridShow ? '7px' : '')};

  :hover {
    background-color: var(--gray-500);
    color: var(--dark-900);
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CurrentSetorCard = styled.div`
  padding: 5px;
  border-radius: 5px;
  color: var(--dark-900);
  font-size: 1.2rem;
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
  font-weight: bold;
  border-bottom: 5px solid;
  white-space: nowrap;
  span {
    opacity: 0;
    transition: all 0.4s ease;
    cursor: pointer;
    width: 20px;
    opacity: 1;
    font-weight: bolder;
  }
`;

export const Box = styled.label`
  width: 100%;
  padding-top: 10px;
`;

export const BoxButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ICorOption = {
  cor: string;
};

export const CorOption = styled.div<ICorOption>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
  &:hover {
    color: ${(props) => '#' + props.cor};
  }
`;

export const Cor = styled.div<ICorOption>`
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: ${(props) => '#' + props.cor};
  transition: all 0.2s ease;
`;

export const OptionSetorCode = styled.span<ICorOption>`
  font-size: 0.8rem !important;
  border-left: 2px solid ${(props) => '#' + props.cor};
  color: ${(props) => '#' + props.cor};
  padding: 2px;
  display: flex;
  align-items: center;
`;

export const SelectOption = styled.span``;

type ICloseOption = {
  show: boolean;
};

export const SelectOptionCloseButton = styled.span<ICloseOption>`
  position: absolute;
  display: ${(props) => (props.show ? 'block' : 'none')};
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
  font-size: 1.25rem;
  transition: all 0.4s ease;

  &:hover {
    font-size: 1.45rem;
  }
`;
