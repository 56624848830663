import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getVendedoresByUsuario } from 'client/api/vendedores';
import { getFiliaisByUser } from 'client/api/filiais';

type SelectedVendedorProps = {
  codFilial?: number;
  nomeFilial?: string;
  codEmpresa?: number;
  label?: string;
};

const UseSelectFilial = (codEmpresa: number, codUsuario: number) => {
  const { data: user } = useUser();
  const [filiais, setFiliais] = useState<any[]>([]);
  const [selectedFilial, setSelectedFilial] = useState<SelectedVendedorProps>(() => {
    const usr = JSON.parse(localStorage.getItem('vmFilData') || 'null');
    return (
      usr || {
        codFilial: user ? user.codUsuario : -1,
        nomeFilial: '',
      }
    );
  });

  useEffect(() => {
    if (user && codEmpresa && codUsuario) {
      getFiliaisByUser(codEmpresa, codUsuario).then((fils: any[]) => {
        const formattedFiliais = fils.map((v) => ({
          ...v,
          label: `[${v.codFilial}] - ${v.nomeFilial}`,
        }));
        setFiliais(formattedFiliais);
      });
    }
  }, [user, codEmpresa, codEmpresa, codUsuario]);

  const handleUserChange = (emp: SelectedVendedorProps) => {
    setSelectedFilial(emp);
    localStorage.setItem('vmFilData', JSON.stringify(emp));
  };

  const SelectFilial = ({
    onChange,
    width,
    label = 'Filial',
    placeholder = 'Selecione uma Filial',
  }: {
    onChange?: (codUsuario: number) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {
    const widthCalculated = +(`[${selectedFilial.codFilial}] - ${selectedFilial?.nomeFilial}`.length * 8);

    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: clamp(100px, ${width ?? `${widthCalculated}px`}, ${width ?? '175px'});
    `;

    const handleChange = (emp: SelectedVendedorProps) => {
      handleUserChange(emp);
      if (onChange) {
        onChange(emp.codFilial);
      }
    };

    return (
      <InputBox>
        <label>{label}</label>
        <Dropdown
          value={selectedFilial}
          onChange={(e) => handleChange(e.value)}
          options={filiais}
          optionLabel="label"
          filter
          placeholder={placeholder}
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
        />
      </InputBox>
    );
  };

  return {
    SelectFilial,
    selectedFilial,
    setSelectedFilial,
  };
};

export default UseSelectFilial;
