'use client';

import { createContext, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  CoresOptionsProps,
  MapSettingsProps,
  MarkerProps,
  PagesStateProps,
  PositionsStateProps,
  ToRenderObjectsProps,
  ViewModeProps,
} from './analiseRoteiro.types';
import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import { useLoaderEffect } from 'providers/loaderEffect';
import { getRouterData } from 'client/api';
import { Marker, Polyline, Popup } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import L from 'leaflet';
import clientSvg1 from 'assets/svgs/clientSvg1';
import CliPopUp from './components/cliPopUp';

export interface AnaliseRoteiroProps {
  SelectEmpresa: any;
  selectedEmpresa: SelectedEmpresaProps;
  handleGetRouterData: (codVendedor: string) => void;
  cores: CoresOptionsProps;
  positions: PositionsStateProps;
  setPositions: React.Dispatch<SetStateAction<PositionsStateProps>>;
  pages: PagesStateProps;
  setPages: React.Dispatch<SetStateAction<PagesStateProps>>;
  mapSettings: MapSettingsProps;
  setMapSettings: React.Dispatch<SetStateAction<MapSettingsProps>>;
  toRenderObjects: ToRenderObjectsProps;
  setToRenderObjects: React.Dispatch<SetStateAction<ToRenderObjectsProps>>;
  viewMode: ViewModeProps;
  setViewMode: React.Dispatch<SetStateAction<ViewModeProps>>;
  handlePagination: (prev?: boolean) => void;
  selectedMarker: any;
  setSelectedMarker: React.Dispatch<SetStateAction<any>>;
  selectedCodVendedor: number;
  setSelectedCodVendedor: React.Dispatch<SetStateAction<number>>;
}

type SelectedEmpresaProps = {
  codEmpresa: number;
  fantasia?: string;
};

export const AnaliseRoteiroContext = createContext({} as AnaliseRoteiroProps);

export const AnaliseRoteiroProvider = ({ children }: any) => {
  const { setLoader } = useLoaderEffect();

  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();

  const cores: CoresOptionsProps = {
    SEGUNDA: '#32ba70',
    TERÇA: '#375cff',
    QUARTA: '#ff6143',
    QUINTA: '#7328b3',
    SEXTA: '#e52755',
    SABADO: '#eed63b',
    DOMINGO: '#FF8C00',
  };

  const [positions, setPositions] = useState<PositionsStateProps>({
    all: [],
    current: [],
  });
  const [pages, setPages] = useState<PagesStateProps>({
    all: [],
    current: '',
    showPrev: true,
    showNext: true,
    weekName: '',
  });
  const [mapSettings, setMapSettings] = useState<MapSettingsProps>({
    zoom: 8,
    maxZoom: 17,
    center: [-15.79347680799033, -47.882663471032025],
  });
  const [toRenderObjects, setToRenderObjects] = useState<ToRenderObjectsProps>({
    markers: <></>,
    lines: <></>,
  });

  const [viewMode, setViewMode] = useState<ViewModeProps>('map');

  const [selectedMarker, setSelectedMarker] = useState(null);

  const [selectedCodVendedor, setSelectedCodVendedor] = useState<number>(0);

  useEffect(() => {
    if (selectedCodVendedor && selectedCodVendedor != 0) {
      handleGetRouterData(selectedCodVendedor.toString());
    }
  }, [selectedCodVendedor]);

  useEffect(() => {
    if (pages.current && positions.current) {
      handleRenderPositions(positions.current);
      setMapSettings({
        zoom: selectedMarker ? 18 : 14,
        maxZoom: 22,
        center: selectedMarker
          ? [+selectedMarker.lat, +selectedMarker.lng]
          : [+positions.current[0].latitudePonto, +positions.current[0].longitudePonto],
      });
    }
    handleWeekDates();
  }, [pages.current, positions.current]);

  const handleGetRouterData = (codVendedor: string) => {
    setLoader({
      show: true,
      text: `Buscando roteiro, por favor aguarde...`,
    });
    getRouterData(('122008').toString(), '261', codVendedor)
      .then((res: any) => {
        if (res.length > 0) {
          for (let i = 0; i < res.length; i++) {
            res[i].distancia = 0;
            for (let j = 0; j < res[i].clientesDaRota.length; j++) {
              res[i].distancia = +res[i].distancia + +res[i].clientesDaRota[j].distancia;
            }
          }
          setPositions({
            all: res,
            current: res,
          });
          const pagesList: any = handlePagesList(res);
          const pageToStart = handleRouterStartPage(res, pagesList);
          setPages({
            all: pagesList,
            current: pageToStart ?? pagesList[0],
            showPrev: true,
            showNext: true,
            weekName: '',
          });
          setMapSettings({
            zoom: 14,
            maxZoom: 22,
            center: [+res[0].latitudePonto, +res[0].longitudePonto],
          });
        }
        handleRenderPositions(res ?? []);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoader({
          show: false,
          text: ``,
        });
      });
  };

  const handlePagesList = (positions: any) => {
    if (positions.length == 0) {
      return [];
    }
    let week = positions[0].semana + '/' + positions[0].mes;
    let pages = [week];
    positions.map((pos: any) => {
      if (week !== pos.semana + '/' + pos.mes) {
        pages.push(pos.semana + '/' + pos.mes);
      }
      week = pos.semana + '/' + pos.mes;
    });

    return pages;
  };

  const handlePagination = (prev?: boolean) => {
    const movePrev = prev != undefined ? prev : false;
    const actualIndex = pages.all.findIndex((el: any) => {
      if (el === pages.current) {
        return true;
      } else {
        return false;
      }
    });
    const lastIndex = pages.all.length - 1;

    if (!movePrev) {
      if (actualIndex < lastIndex) {
        setPages((prev: PagesStateProps) => {
          return { ...prev, current: pages.all[actualIndex + 1], showPrev: true };
        });
      } else {
        setPages((prev: PagesStateProps) => {
          return { ...prev, showPrev: false };
        });
      }
      if (actualIndex + 1 === lastIndex) {
        setPages((prev: PagesStateProps) => {
          return { ...prev, showNext: false };
        });
      }
    } else {
      if (actualIndex > 0) {
        setPages((prev: PagesStateProps) => {
          return { ...prev, current: pages.all[actualIndex - 1], showNext: true };
        });
      } else {
        setPages((prev: PagesStateProps) => {
          return { ...prev, showNext: false };
        });
      }
      if (actualIndex - 1 === 0) {
        setPages((prev: PagesStateProps) => {
          return { ...prev, showPrev: false };
        });
      } else {
        setPages((prev: PagesStateProps) => {
          return { ...prev, showPrev: true };
        });
      }
    }

    return;
  };

  const handleRouterStartPage = (positions: any[], pagesList: string[]): string => {
    let newPageToStart = pagesList[0];
    const actualDate = new Date().toLocaleDateString('pt-BR');
    for (let i = 0; i < positions.length; i++) {
      const rota: any = positions[i];
      const visitaDate = new Date(rota.dtProxVisita).toLocaleDateString('pt-BR');
      if (actualDate == visitaDate) {
        newPageToStart = `${rota.semana}/${rota.mes}`;
        break;
      }
    }
    return newPageToStart;
  };

  const handleWeekDates = () => {
    const data = positions.current.filter((rout: any) => pages.current === rout.semana + '/' + rout.mes);

    let month = new Date(data[0] ? data[0].dtProxVisita : '').toLocaleString('default', { month: 'short' });
    month = month.replace('.', '')[0].toUpperCase() + month[1] + month[2];

    let mindate = new Date(data[0] ? data[0].dtProxVisita : '').getDate();
    let maxdate = new Date(data[data.length - 1] ? data[data.length - 1].dtProxVisita : '').getDate();
    setPages((prev: PagesStateProps) => {
      return { ...prev, weekName: `${pages.current.split('/')[0]} | ${mindate}-${maxdate}/${month}` };
    });
  };

  const customMarkerIcon = (color: string, value: number) => {
    let svgTemplate = '';
    if (value === 0) {
      svgTemplate = `<svg xmlns="http://www.w3.org/2000/svg" height="50" width="50" viewBox="0 0 50 50" className="map-label-ini">
      <circle cx="18" cy="18" r="15" stroke="#bfcde0" stroke-width="3" fill="#000000" opacity="0.7" />
      <svg
        x="9"
        y="9"
        stroke="#FFF"
        fill="#FFF"
        stroke-width="0"
        viewBox="0 0 16 16"
        height="17px"
        width="17px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5Z"></path>
      </svg>
    </svg>`;
    } else {
      svgTemplate = clientSvg1(value, color);
    }

    return L.icon({
      iconUrl: 'data:image/svg+xml;charset=UTF-8,' + encodeURIComponent(svgTemplate),
      iconSize: [36, 36],
      iconAnchor: [12, 24],
      popupAnchor: [0, -24],
    });
  };

  const handleRenderPositions = (points: any[]) => {
    let posList = points.filter((rout: any) => pages.current == rout.semana + '/' + rout.mes);

    let markers: any = [<></>];
    posList.map((pos: any) => {
      points.push(
        <Marker
          icon={customMarkerIcon(pos.visitaBloqueio === 'S' ? '#CCCCCC' : '#FF0000', 0)}
          key={Math.random()} // Ideally, use a stable unique identifier
          position={[+pos.latitudePonto, +pos.longitudePonto]}
          eventHandlers={{
            click: () => {
              // Set selected marker data when the marker is clicked
              // setSelectedMarker({
              //   lat: pos.latitudePonto,
              //   lng: pos.longitudePonto,
              //   nomeCli: pos.descricaoPonto,
              // });
            },
          }}
        >
          <Popup>{+pos.latitudePonto}</Popup>
        </Marker>,
      );

      markers.push(
        pos.clientesDaRota.map((position: MarkerProps, index: any) => {
          return (
            <Marker
              icon={customMarkerIcon(
                position.visitaBloqueio == 'S' ? '#818080' : cores[position?.diaSemana ?? 'SEGUNDA'],
                position.sequencia,
              )}
              key={position.codCli + Math.random()}
              position={{ lat: +position.lat, lng: +position.lng }}
              eventHandlers={{
                click: () => {
                  // setSelectedMarker(position);
                },
              }}
            >
              <Popup maxWidth={600}>
                <CliPopUp cli={position} handleGetCliData={undefined} compPositions={[]} />
              </Popup>
            </Marker>
          );
        }),
      );
    });

    let polyLines = [<></>];
    posList.map((pos: any, index: any) => {
      let linePoints: LatLngExpression[] = [];

      linePoints?.push([+pos.latitudePonto, +pos.longitudePonto]);

      pos.clientesDaRota?.map((position: MarkerProps, index: any) => linePoints.push([+position.lat, +position.lng]));

      polyLines.push(
        <Polyline
          key={pos.diaSemana + pos.mes + pos.dtProxVisita + pos.latitudePonto}
          color={cores[pos.diaSemana]}
          positions={linePoints}
        />,
      );
    });
    setToRenderObjects({
      markers: markers,
      lines: polyLines,
    });
  };

  return (
    <AnaliseRoteiroContext.Provider
      value={{
        SelectEmpresa,
        selectedEmpresa,
        handleGetRouterData,
        cores,
        positions,
        setPositions,
        pages,
        setPages,
        mapSettings,
        setMapSettings,
        toRenderObjects,
        setToRenderObjects,
        viewMode,
        setViewMode,
        handlePagination,
        selectedMarker,
        setSelectedMarker,
        selectedCodVendedor,
        setSelectedCodVendedor,
      }}
    >
      {children}
    </AnaliseRoteiroContext.Provider>
  );
};

export const useAnaliseRoteiro = () => useContext(AnaliseRoteiroContext);
