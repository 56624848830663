import { useEmpresa, useEmpresas, useUser } from 'client/hooks';
import { empresaCNPJSelector } from 'modules/auth/reducer/auth';
import * as S from '../styles';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { BlockUI } from 'primereact/blockui';
import {
  selectedEmpresaSelector,
  selectedUsuarioSelector,
  setSelectedEmpresa,
  setSelectedUsuario,
} from '../../../reducer/menuAcesso';
import { useAppDispatch, useAppSelector } from 'store';

import { useEffect, useState } from 'react';
import { getUsers } from 'client/api';
import { UserData } from 'client/interfaces';

export const FiltersCompanyUser = () => {
  const [users, setUsers] = useState<UserData[]>([]);

  const { data: user } = useUser();

  const { data: companyUser } = useEmpresa(user?.codEmpresa);

  const { data: empresas } = useEmpresas();

  const dispatch = useAppDispatch();

  const codEmpresaVMais = '2072021';
  const selectedEmpresa = useAppSelector(selectedEmpresaSelector);
  const selectedUsuario = useAppSelector(selectedUsuarioSelector);
  const codEmpresa = useAppSelector(empresaCNPJSelector);

  const isClientUser = codEmpresa !== codEmpresaVMais;

  useEffect(() => {
    if (isClientUser) {
      dispatch(setSelectedEmpresa(companyUser));
      dispatch(setSelectedUsuario(null));
    }
  }, [isClientUser, companyUser]);

  useEffect(() => {
    if (selectedEmpresa?.codEmpresa) {
      getUsers(isClientUser ? user?.codEmpresa : selectedEmpresa?.codEmpresa).then((data) => {
        setUsers(data);
      });
    }
  }, [selectedEmpresa?.codEmpresa, user]);

  return (
    <S.Header>
      <S.BoxSelect>
        <label className="mb-1" htmlFor="empresa-select">
          Empresa
        </label>
        <div className="row">
          {codEmpresa === codEmpresaVMais ? (
            <Dropdown
              value={selectedEmpresa}
              options={empresas}
              onChange={(e) => {
                dispatch(setSelectedEmpresa(e.value));
                dispatch(setSelectedUsuario(null));
              }}
              optionLabel="razaosocial"
              placeholder="Selecione uma empresa"
              filter
              filterBy="fantasia,codEmpresa"
              itemTemplate={(usr) => (`${usr.fantasia} - [${usr.codEmpresa}]`)}
            />
          ) : (
            <InputText value={companyUser?.razaosocial} disabled />
          )}
        </div>
      </S.BoxSelect>

      <S.BoxSelect>
        <label className="mb-1" htmlFor="usuario-select">
          Usuário
        </label>
        <div className="row">
          <BlockUI containerStyle={{ zIndex: 0}} blocked={!selectedEmpresa?.codEmpresa} template={<i className="pi pi-spin pi-spinner" />}>
            <Dropdown
              style={{ width: '100%' }}
              value={selectedUsuario}
              options={users}
              disabled={!selectedEmpresa?.codEmpresa}
              onChange={(e) => dispatch(setSelectedUsuario(e.value))}
              optionLabel="nome"
              placeholder="Selecione um usuário"
              filter
              emptyMessage="Nenhum usuário encontrado"
              dataKey="codUsuario"
              filterBy="nome"
              itemTemplate={(usr) => (usr.codUsuario ? `${usr.nome} - [${usr.codUsuario}]` : usr.nome)}
            />
          </BlockUI>
        </div>
      </S.BoxSelect>
    </S.Header>
  );
};
