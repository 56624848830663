import { Dropdown } from 'primereact/dropdown';
import { useUser } from '../../client/hooks/usuarios';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getSupervisorByRole } from 'client/api/vendedores';

type SelectedSupervisorProps = {
  codEmpresa?: number;
  codSupervisor?: number;
  nomeSupervisor?: string;
  nomesup?: string;
  label?: string;
  codGerente?: number;
};

const UseSelectSupervisor = (codEmpresa: number, codUsuario: number, codGerente?: number) => {
  const { data: user } = useUser();
  const [supervisores, setSupervisores] = useState<any[]>([]);
  const [selectedSupervisor, setSelectedSupervisor] = useState<SelectedSupervisorProps>(() => {
    const sup = JSON.parse(localStorage.getItem('vmSupData') || 'null');
    return (
      sup || {
        codSupervisor: sup ? sup.codSupervisor : -1,
        nomeSupervisor: '',
      }
    );
  });

  useEffect(() => {
    localStorage.removeItem('vmSupData');
    if (user && codEmpresa && codUsuario) {
      getSupervisorByRole(codEmpresa, codUsuario).then((sups: any[]) => {
        const formattedSups = [];
        for (const s of sups) {
          if (codGerente == undefined || codGerente == -1 || +s.codGerente == +codGerente) {
            formattedSups.push({
              ...s,
              label: `[${s.codSupervisor}] - ${s.nomesup}`,
              nomeSupervisor: s.nomesup,
            });
          }
        }
        setSupervisores(formattedSups);
      });
    }
  }, [user, codEmpresa, codEmpresa, codUsuario, codGerente]);

  const handleUserChange = (sup: SelectedSupervisorProps) => {
    setSelectedSupervisor(sup);
    localStorage.setItem('vmSupData', JSON.stringify(sup));
  };

  const SelectSupervisor = ({
    onChange,
    width,
    label = 'Supervisor',
    placeholder = 'Selecione um Supervisor',
  }: {
    onChange?: (codUsuario: number) => void;
    width?: string;
    label?: string;
    placeholder?: string;
  }) => {
    const widthCalculated = +(
      `[${selectedSupervisor.codSupervisor}] - ${selectedSupervisor?.nomeSupervisor}`.length * 8
    );

    const InputBox = styled.div`
      display: flex;
      flex-direction: column;
      gap: 0px;
      width: clamp(100px, ${width ?? `${widthCalculated}px`}, ${width ?? '175px'});
    `;

    const handleChange = (sup: SelectedSupervisorProps) => {
      handleUserChange(sup);
      if (onChange) {
        onChange(sup.codSupervisor);
      }
    };

    return (
      <InputBox>
        <label>{label}</label>
        <Dropdown
          value={selectedSupervisor}
          onChange={(e) => handleChange(e.value)}
          options={supervisores}
          optionLabel="label"
          filter
          placeholder={placeholder}
          emptyMessage="Nenhum dado encontrado"
          emptyFilterMessage="Nenhum dado encontrado"
        />
      </InputBox>
    );
  };

  return {
    SelectSupervisor,
    selectedSupervisor,
    setSelectedSupervisor,
  };
};

export default UseSelectSupervisor;
