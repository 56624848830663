import * as S from './AnaliseForms.styles';
import { useTitlePageStore } from 'storesZustand/titlePageStore';
import { useEffect, useState } from 'react';

import UseSelectEmpresa from 'hooks/UseSelectEmpresa/selectEmpresas';
import UseSelectUsuario from 'hooks/UseSelectUsuario/selectUsuario';
import UseSelectVendedor from 'hooks/UseSelectVendedor/selectVendedor';
import UseSelectFilial from 'hooks/UseSelectFilial/selectFilial';
import UseSelectCidade from 'hooks/UseSelectCidade/selectCidade';
import UseSelectGerente from 'hooks/UseSelectGerente/selectGerente';
import UseSelectSupervisor from 'hooks/UseSelectSupervisor/selectSupervisor';
import UseSelectEstado from 'hooks/UseSelectEstado/selectEstado';
import UseSelectCliente from 'hooks/UseSelectCliente/selectCliente';
import UseSelectDate from 'hooks/UseSelectDate/selectDate';

import VmButton from 'components/VmButton/VmButton';
import { HiFastForward } from 'react-icons/hi';

import dataList from './mock';
import FormDataList from './components/FormDataList/formDataList.index';
import { useCabecalhoItems } from 'client/hooks';
import { Dropdown } from 'primereact/dropdown';
import { FomularioAnswerProps } from './AnaliseForms.types';
import FormImageCards from './components/FormImageCards/formImageCards.index';
import FormDoubleImageCards from './components/FormDoubleImageCards/formDoubleImageCards.index';

export const AnaliseForms = () => {
  const { setTitlePage } = useTitlePageStore();

  const { SelectEmpresa, selectedEmpresa } = UseSelectEmpresa();
  const { SelectUsuario, selectedUsuario } = UseSelectUsuario(selectedEmpresa.codEmpresa);

  const { SelectGerente, selectedGerente } = UseSelectGerente(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);

  const { SelectSupervisor, selectedSupervisor } = UseSelectSupervisor(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
    selectedGerente.codGerente,
  );

  const { SelectVendedor, selectedVendedor } = UseSelectVendedor(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
    selectedGerente.codGerente,
    selectedSupervisor.codSupervisor,
  );

  const { SelectCliente, selectedCliente } = UseSelectCliente(
    selectedEmpresa.codEmpresa,
    selectedUsuario.codUsuario,
    selectedVendedor.codVendedor,
  );

  const { SelectFilial, selectedFilial } = UseSelectFilial(selectedEmpresa.codEmpresa, selectedUsuario.codUsuario);
  const { SelectEstado, selectedUf } = UseSelectEstado();
  const { SelectCidade, selectedCidade } = UseSelectCidade(selectedUf.codUf);

  const { SelectDate: SelectDtIni, selectedDate: selectedDtIni } = UseSelectDate();
  const { SelectDate: SelectDtFim, selectedDate: selectedDtFim } = UseSelectDate();

  const { data: perguntasList, isLoading, refetch } = useCabecalhoItems(343, selectedEmpresa.codEmpresa);

  const [analiseOptions, setAnaliseOptions] = useState<{
    pergunta1: FomularioAnswerProps;
    pergunta2: FomularioAnswerProps;
    tipo: string;
  }>({
    pergunta1: null,
    pergunta2: null,
    tipo: 'G',
  });

  useEffect(() => {
    setTitlePage('Análise Formulários');
    document.title = 'Análise Formulários | VMAIS';
    console.log('dataList :', dataList);
    console.log('perguntasList :', perguntasList);
  }, [selectedEmpresa.codEmpresa]);

  return (
    <S.AnaliseFormMainBox>
      <S.AnaliseFormHeader>
        <SelectEmpresa width="160px" label="Empresa *" />
        <SelectUsuario width="160px" label="Usuário *" />

        <SelectFilial width="160px" label="Filial *" />

        <SelectDtIni label="Data Inicial *" />
        <SelectDtFim label="Data Final *" />

        <S.AFInputBox width="160px">
          <label>Pergunta *</label>
          <Dropdown
            value={analiseOptions.pergunta1}
            onChange={(e) => {
              setAnaliseOptions((prev: any) => {
                return {
                  ...prev,
                  pergunta1: e.value,
                  pergunta2: null,
                  tipo: e.value.tipo == 'FOTO' ? 'F' : 'G',
                };
              });
            }}
            options={perguntasList ?? []}
            optionLabel="nomeCampo"
            filter
            placeholder="Pergunta"
            emptyMessage="Nenhum dado encontrado"
            emptyFilterMessage="Nenhum dado encontrado"
            itemTemplate={(item: any) => `[${item.nroform}] ${item.nomeCampo}`}
          />
        </S.AFInputBox>

        {analiseOptions.pergunta1 && analiseOptions.pergunta1.tipo == 'FOTO' && (
          <S.AFInputBox width="160px">
            <label>Pergunta 2</label>
            <Dropdown
              value={analiseOptions.pergunta2}
              onChange={(e) => {
                setAnaliseOptions((prev: any) => {
                  return {
                    ...prev,
                    pergunta2: e.value ?? null,
                    tipo: e.value
                      ? e.value.tipo == 'FOTO'
                        ? 'FF'
                        : analiseOptions.pergunta1.tipo == 'FOTO'
                        ? 'F'
                        : 'G'
                      : 'F',
                  };
                });
              }}
              options={perguntasList?.filter((pergunta: any) => pergunta.tipo == 'FOTO') ?? []}
              optionLabel="nomeCampo"
              filter
              placeholder="Pergunta"
              emptyMessage="Nenhum dado encontrado"
              emptyFilterMessage="Nenhum dado encontrado"
              itemTemplate={(item: any) => `[${item.nroform}] ${item.nomeCampo}`}
              showClear
            />
          </S.AFInputBox>
        )}

        <SelectGerente width="160px" />
        <SelectSupervisor width="160px" />
        <SelectVendedor width="160px" />

        <SelectEstado width="70px" />
        <SelectCidade width="120px" />

        <SelectCliente width="160px" />

        <VmButton
          options={{
            label: 'Apurar',
            style: 'btn-success',
            width: '',
            heigth: '',
            icon: <HiFastForward />,
            iconPosition: 'left',
            tooltip: {
              show: false,
              text: 'Apurar Análise',
              position: 'bottom',
            },
            loading: false,
          }}
        />
      </S.AnaliseFormHeader>

      <S.AnaliseFormContent>
        {analiseOptions.tipo == 'G' ? (
          <FormDataList dataList={dataList} />
        ) : analiseOptions.tipo == 'F' ? (
          <FormImageCards dataList={dataList?.filter((pergunta: any) => pergunta.tipo == 'FOTO')} />
        ) : analiseOptions.tipo == 'FF' ? (
          <FormDoubleImageCards
            dataList={dataList?.filter((pergunta: any) => pergunta.tipo == 'FOTO')}
            dataList2={dataList?.reverse()?.filter((pergunta: any) => pergunta.tipo == 'FOTO')}
          />
        ) : (
          <></>
        )}
      </S.AnaliseFormContent>
    </S.AnaliseFormMainBox>
  );
};
