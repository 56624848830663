import { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { getClientesWithoutSector } from 'client/api';

const MapComponent = () => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const [pontos, setPontos] = useState([]);
  const canvasLayerRef = useRef(null);

  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const polygonLayerRef = useRef(null);

  useEffect(() => {
    handleGetPontos();
  }, []);

  const handleGetPontos = () => {
    getClientesWithoutSector(122008, 136, 72)
      .then((res) => {
        const newPontos = (res ?? []).map((cli) => ({
          lat: +(cli.LATITUDE ?? 0),
          lng: +(cli.LONGITUDE ?? 0),
          color: '#' + cli.COR,
          radius: 8,
          info: cli.RAZAOSOCIAL,
          ...cli,
        }));
        setPontos(newPontos);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const drawMarker = (ctx, lat, lng, radius, color) => {
    const point = mapInstance.current.latLngToContainerPoint([lat, lng]);

    ctx.beginPath();
    ctx.arc(point.x, point.y, radius, 0, 2 * Math.PI);
    ctx.strokeStyle = 'white';
    ctx.lineWidth = 2;
    ctx.stroke();

    ctx.beginPath();
    ctx.arc(point.x, point.y, radius - 1, 0, 2 * Math.PI);
    ctx.fillStyle = color;
    ctx.fill();
  };

  const simplifyPoints = (points, zoom) => {
    const tolerance = Math.max(1, Math.pow(2, 15 - zoom)); 
    return points.filter((_, index) => index % tolerance === 0);
  };

  const drawCanvas = () => {
    if (!mapInstance.current) return;

    const canvas = document.createElement('canvas');
    const size = mapInstance.current.getSize();
    canvas.width = size.x;
    canvas.height = size.y;
    const ctx = canvas.getContext('2d');

    // Simplify points based on the zoom level
    const zoom = mapInstance.current.getZoom();
    const simplifiedPontos = simplifyPoints(pontos, zoom);

    simplifiedPontos.forEach(({ lat, lng, radius, color }) => {
      drawMarker(ctx, lat, lng, radius, color);
    });

    if (canvasLayerRef.current) {
      mapInstance.current.removeLayer(canvasLayerRef.current);
    }

    const bounds = mapInstance.current.getBounds();
    canvasLayerRef.current = L.imageOverlay(canvas.toDataURL(), bounds);
    canvasLayerRef.current.addTo(mapInstance.current);
  };

  useEffect(() => {
    mapInstance.current = L.map(mapRef.current).setView([-19.9286, -43.9409], 5);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
    }).addTo(mapInstance.current);

    const redrawCanvas = () => drawCanvas();

    mapInstance.current.on('moveend', redrawCanvas);
    mapInstance.current.on('resize', redrawCanvas);
    mapInstance.current.on('zoomend', redrawCanvas); // Redraw when zoom changes

    drawCanvas();

    mapInstance.current.on('click', (event) => {
      const clickPos = mapInstance.current.mouseEventToContainerPoint(event.originalEvent);

      if (isDrawing) return;
      const { lat, lng } = event.latlng;
      isDrawing && setPolygonCoordinates((prevCoords) => [...prevCoords, [lat, lng]]);

      pontos.forEach(({ lat, lng, radius, info }) => {
        const point = mapInstance.current.latLngToContainerPoint([lat, lng]);
        const distance = Math.sqrt((clickPos.x - point.x) ** 2 + (clickPos.y - point.y) ** 2);

        if (distance <= radius) {
          L.popup().setLatLng([lat, lng]).setContent(`Cliente: ${info}`).openOn(mapInstance.current);
        }
      });
    });

    return () => {
      mapInstance.current.off('moveend', redrawCanvas);
      mapInstance.current.off('resize', redrawCanvas);
      mapInstance.current.off('zoomend', redrawCanvas);
      mapInstance.current.off('click');
      mapInstance.current.remove();
    };
  }, [pontos]);

  useEffect(() => {
    if (pontos.length > 0 && mapInstance.current) {
      drawCanvas();
    }
  }, [pontos]);

  useEffect(() => {
    if (polygonLayerRef.current) {
      mapInstance.current.removeLayer(polygonLayerRef.current);
    }

    if (polygonCoordinates.length > 1) {
      polygonLayerRef.current = L.polygon(polygonCoordinates, { color: 'blue' }).addTo(mapInstance.current);
    }
  }, [polygonCoordinates]);

  return (
    <div>
      <div ref={mapRef} style={{ height: '100vh' }} />
    </div>
  );
};

export default MapComponent;
