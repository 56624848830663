import React, { useMemo, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import * as S from './mapHeader.styles';
import { useMap } from '../../regioes.context';
import { ClientesProps } from '../../regioes.types';

const CliSuggestionBox = () => {
  const { clientes, setSelectedCli, cliSearchText, cliSearchRef, setCliSearchText, setoresFilter } = useMap();

  const sortedClientes = useMemo(() => {
    if (!clientes?.list) return [];
    return [...clientes.list].sort((a, b) => a.RAZAOSOCIAL.localeCompare(b.RAZAOSOCIAL));
  }, [clientes?.list]);

  const handleClick = useCallback(
    (cli: ClientesProps) => {
      setSelectedCli(cli);
      setCliSearchText('');
      if (cliSearchRef.current) cliSearchRef.current.value = '';
    },
    [setSelectedCli],
  );

  if (clientes?.list?.length === 0 || !cliSearchText) {
    return null;
  }

  return (
    <S.ClieSuggestionBox
      style={{
        height: clientes?.list?.length > 0 && cliSearchText && cliSearchText != '' ? '40vh' : '0vh',
        opacity: clientes?.list?.length > 0 && cliSearchText && cliSearchText != '' ? '1' : '0',
      }}
    >
      <List height={400} itemCount={sortedClientes.length} itemSize={50}>
        {({ index, style }) => {
          const cli = sortedClientes[index];
          return (
            <S.ClieSuggestionCard style={style} key={cli.CODCLI} onClick={() => handleClick(cli)}>
              {cli.CODCLI} - {cli.RAZAOSOCIAL} <br />
              {cli.SETOR_ATIVO == 'N' && setoresFilter.code == 'A' ? (
                <S.OptionSetorCode cor={'000000'}>{'Sem Setor / Setor Inativo'}</S.OptionSetorCode>
              ) : (
                <S.OptionSetorCode cor={cli.COR ?? '000000'}>
                  {cli.CODSETOR != 0 && cli.CODSETOR != null && cli.CODCLI
                    ? `[${cli.CODSETOR}] ${cli.NOMESETOR}`
                    : 'Sem Setor'}
                </S.OptionSetorCode>
              )}
            </S.ClieSuggestionCard>
          );
        }}
      </List>
    </S.ClieSuggestionBox>
  );
};

export default CliSuggestionBox;
