import styled from 'styled-components';

export const FormImagesCardsMainBox = styled.section`
  position: relative;
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0px 0px 0px;
  flex-wrap: wrap;
  gap: 30px;
`;

export const FormImagesCards = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  max-width: calc((100% - (4 * 20px)) / 5);
  margin-top: 10px;
  overflow: hidden;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  .p-image,
  .p-image img {
    max-width: 100% !important;
    max-height: 100% !important;
    max-height: 200px !important;
  }
`;

export const FormImagesCardsTitle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  min-height: 2rem;
  /* position: absolute; */
  /* bottom: 0px; */
  padding: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 0.8rem;
  text-align: left;
  box-shadow: rgba(0, 0, 0, 0.05) 0px -2.4px 3.2px;

  gap: 5px;

  span {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
